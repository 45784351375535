import {
    Grid,
    IconButton,
    Divider,
    Tooltip,
    Box,
    useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { convertToDate } from '../../utils';
import InfoIcon from "@mui/icons-material/Info";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const DetailsBox = ({ detailsData, attributesToShow }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        // Maybe show a confirmation/toast here
    };

    function formatValue(type, value) {
			switch (type) {
				case 'datetime':
					return value ? convertToDate(value) : null;
				case 'text':
					return value;
				default:
					// Return it as is for text or any other types
					return value;
			}
		}

    return (
        <Box
            mt={0.5}
            mb={0.5}
            ml={0.5}
            mr={0.5}
            sx={{
                maxWidth: "100%",
                wordWrap: 'break-word',
            }}
        >
            {attributesToShow.map((attr, index) => (
                <div key={attr.key}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={3}
                        key={attr.key}
                    >
                        <Grid item xs={5}>
                            {attr.displayName}:
                        </Grid>
                        <Grid item xs={7}>
                            {formatValue(attr.valueType, detailsData[attr.key])}
                        </Grid>
                        {/* <Grid item xs={2}>
                            {attr.enableCopy && (
                                <IconButton
                                    onClick={() =>
                                        copyToClipboard(detailsData[attr.key])
                                    }
                                >
                                    <FileCopyIcon />
                                </IconButton>
                            )}

                            {attr.enableInfo && (
                                <Tooltip title={attr.infoText}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid> */}
                    </Grid>
                    {index !== attributesToShow.length - 1 && (
                        <Divider style={{ margin: "8px 0" }} />
                    )}
                </div>
            ))}
        </Box>
    );
};

export default DetailsBox;
