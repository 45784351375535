import {
	useTheme,
	Box,
	IconButton,
	Tooltip,
	darken,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Typography,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
} from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import Header from "../../components/Header/Header.jsx";
import useHttpService from "../../customHooks/useHttpService.js";
import { Refresh } from "@mui/icons-material";
import DetailsIcon from "@mui/icons-material/Details";
import RightDrawer from "../../components/RightDrawer/RightDrawer";
import DetailsBox from "../../components/DetailsBox/DetailsBox";
import { MaterialReactTable } from "material-react-table";
import { HistoryOutlined, WorkOutline } from "@mui/icons-material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { tokens } from "../../theme.js";
import { convertToDate } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

const WorkFlows = () => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const location = useLocation();
	const [locationFilterApplied, setLocationFilterApplied] = useState(false);

	const tableInstanceRef = useRef(null);

	// main table -> SalesOrders
	const [workFlowData, setWorkFlowData] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [columnFilters, setColumnFilters] = useState(() => {
		let initialFilters = [];
		if (location.state) {
			if (location.state?.runId) {
				initialFilters.push({ id: "run_id", value: location.state.runId });
			}
		}
		return initialFilters;
	});
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const fetchWorkFlowData = () => {
		const apiPath = "/sid/ServiceOrder_Dag";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		apiUrl.searchParams.set(
			"start",
			`${pagination.pageIndex * pagination.pageSize}`
		);
		apiUrl.searchParams.set("size", `${pagination.pageSize}`);
		//apiUrl.searchParams.set("tasks", 1);

		columnFilters.forEach((columnFilter) => {
			if (columnFilter.id === "id") {
				apiUrl.searchParams.set("id", columnFilter.value);
			} else if (columnFilter.id === "dag_id") {
				apiUrl.searchParams.set("dag_id", columnFilter.value);
			} else if (columnFilter.id === "mainkey") {
				apiUrl.searchParams.set("serviceorderid", columnFilter.value);
			} else if (columnFilter.id === "status") {
				apiUrl.searchParams.set("status", columnFilter.value);
			} else if (columnFilter.id === "start_ts") {
				apiUrl.searchParams.set("start_ts", columnFilter.value);
			} else if (columnFilter.id === "end_ts") {
				apiUrl.searchParams.set("end_ts", columnFilter.value);
			} else if (columnFilter.id === "run_id") {
				apiUrl.searchParams.set("run_id", columnFilter.value);
			} else if (columnFilter.id === "last_task_date") {
				apiUrl.searchParams.set("last_task_date", columnFilter.value);
			}
		});

		httpService.getAPI(
			apiUrl,
			workFlowData,
			setWorkFlowData,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};
	useEffect(() => {
		fetchWorkFlowData();
	}, [
		fetchRequired,
		columnFilters,
		//globalFilter,
		pagination.pageIndex,
		pagination.pageSize,
		//sorting,
	]);

	const getAlert = () => {
		if (isError) {
			return {
				color: "error",
				children: "Error loading data",
			};
		}

		return undefined;
	};

	const minifyID = (ID) => {
		return ID.split("-")[0] + " " + "\u00B7".repeat(3);
	};

	const minifyRunID = (ID) => {
		return ID.split("_")[0] + " " + "\u00B7".repeat(3);
	};

	const [detailsOpen, setDetailsOpen] = useState(false);
	const [detailsRow, setDetailsRow] = useState({});
	const handleCloseDetails = () => {
		setDetailsOpen(false);
	};

	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "Workflow ID",
				size: 40,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
				Cell: ({ row }) => (
					<Tooltip title={row.original.id} arrow>
						<Typography variant="h6" color="primary">
							{minifyID(row.original.id)}
						</Typography>
					</Tooltip>
				),
			},
			{
				accessorKey: "run_id",
				header: "Run ID",
				size: 40,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
				Cell: ({ row }) => (
					<Tooltip title={row.original.run_id} arrow>
						<Typography variant="h6" color="primary">
							{minifyRunID(row.original.run_id)}
						</Typography>
					</Tooltip>
				),
			},
			{
				accessorKey: "dag_id",
				header: "DAG ID",
				size: 40,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
			},
			{
				accessorKey: "mainkey",
				header: "Main Key",
				size: 40,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
			},
			{
				accessorKey: "status",
				header: "Status",
				size: 40,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
			},
			{
				id: "start_ts",
				accessorFn: (row) =>
					row["start_ts"] ? convertToDate(row["start_ts"]) : "",
				header: "Start Date",
				maxSize: 100,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: "Filter: From indicated datetime",
									sx: { minWidth: "120px" },
									variant: "standard",
								},
								field: { clearable: true },
							}}
							ampm={false}
							format="dd/MM/yyyy HH:mm:ss"
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
			},
			{
				id: "last_task_date",
				accessorFn: (row) =>
					row["last_task_date"] ? convertToDate(row["last_task_date"]) : "",
				header: "Last Date",
				maxSize: 100,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: "Filter: From indicated datetime",
									sx: { minWidth: "120px" },
									variant: "standard",
								},
								field: { clearable: true },
							}}
							ampm={false}
							format="dd/MM/yyyy HH:mm:ss"
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
			},
		],
		[]
	);

	return (
		<Box m="20px">
			<Header title="Workflows" subtitle={"List of Workflows"} />
			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				positionActionsColumn="last"
				muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
				displayColumnDefOptions={{
					"mrt-row-actions": {
						muiTableHeadCellProps: {
							align: "left",
							sx: {
								fontWeight: "bold",
								color: colors.grey[100],
								typography: "h5",
							},
						},
						muiTableBodyCellProps: {
							align: "left",
						},
						size: 40,
					},
				}}
				columns={columns}
				data={workFlowData}
				//getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: false,
					density: "comfortable",
				}}
				// muiTopToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				// muiBottomToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				muiTableHeadCellProps={{
					align: "left",
					sx: {
						fontWeight: "bold",
						color: colors.grey[100],
						typography: "h5",
					},
				}}
				muiTableBodyCellProps={{
					align: "left",
					sx: {
						color: colors.grey[100],
						typography: "h6",
					},
				}}
				muiTableBodyProps={{
					sx: (theme) => ({
						"& tr:nth-of-type(odd)": {
							backgroundColor: darken(theme.palette.background.default, 0.05),
						},
					}),
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				//rowCount={rowCount}
				rowCount={
					workFlowData && workFlowData.length > 0
						? workFlowData[0].totalDBRowCount
						: 0
				}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableSorting={false}
				enableDensityToggle={false}
				enableColumnOrdering={false}
				enableHiding={false}
				enableRowActions={true}
				enableGlobalFilter={false}
				manualFiltering={true}
				manualPagination={true}
				manualSorting={false}
				renderRowActionMenuItems={({ closeMenu, row, table }) => [
					<MenuItem
						key="details"
						onClick={() => {
							closeMenu();
							setDetailsRow(row.original);
							setDetailsOpen(true);
						}}
					>
						<ListItemIcon>
							<DetailsIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: "h6" }}
							primary="Details"
						/>
					</MenuItem>,
					<MenuItem
						key="View on Airflow"
						disabled={!row.original.url}
						onClick={() => {
							closeMenu();
							window.open(row.original.url, "_blank").focus();
						}}
					>
						<ListItemIcon>
							<OpenInNewIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: "h6" }}
							primary="View on Airflow"
						/>
					</MenuItem>,
				]}
				muiTableBodyRowProps={({ row }) => ({
					onDoubleClick: (event) => {
						setDetailsRow(row.original);
						setDetailsOpen(true);
					},
					sx: {
						cursor: "pointer",
					},
				})}
				//renderDetailPanel={RenderDetail}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title="Refresh">
								<IconButton
									onClick={() => {
										setFetchRequired(prev => !prev);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
			/>
			{detailsOpen && (
				<RenderRightDrawer
					rowData={detailsRow}
					isOpen={detailsOpen}
					onClose={handleCloseDetails}
				/>
			)}
		</Box>
	);
};

const Tasks = ({ workflowId }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [tasksData, setTasksData] = useState([]);
	const [tasksDataIsError, setTasksDataIsError] = useState(false);
	const [tasksDataIsLoading, setTasksDataIsLoading] = useState(false);
	const [tasksDataIsRefetching, setTasksDataIsRefetching] =
		useState(false);
	const [tasksDataFetchRequired, setTasksDataFetchRequired] =
		useState(true);
	const [tasksDataRowCount, setTasksDataRowCount] = useState(0);

	const fetchTasksData = () => {
		const apiPath = "/sid/ServiceOrder_Dag_Task";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set("id", `${workflowId}`);
		httpService.getAPI(
			apiUrl,
			tasksData,
			setTasksData,
			setTasksDataRowCount,
			setTasksDataIsLoading,
			setTasksDataIsRefetching,
			setTasksDataIsError
		);
	};

	useEffect(() => {
		fetchTasksData();
	}, []);

	const columns = useMemo(
		() => [
			// {
			//     accessorKey: "dag_id",
			//     header: "DAG ID",
			//     size: 100,
			//     isInput: false,
			//     enableEditing: false,
			// },
			{
				accessorKey: "task_id",
				header: "Task ID",
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				id: "start_ts",
				accessorFn: (row) =>
					row["start_ts"] ? convertToDate(row["start_ts"]) : "",
				header: "Start Date",
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
			},
			{
				id: "end_ts",
				accessorFn: (row) =>
					row["end_ts"] ? convertToDate(row["end_ts"]) : "",
				header: "Last Date",
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
				// muiTableHeadCellFilterTextFieldProps: {
				// 	type: 'datetime-local',
				// },
			},
		],
		[]
	);

	return (
		tasksData && (
			<MaterialReactTable
				columns={columns}
				data={tasksData}
				enableColumnActions={false}
				enableColumnFilters={false}
				// enablePagination={true}
				enableSorting={false}
				enableGlobalFilter={false}
				enableDensityToggle={false}
				enableHiding={false}
				enableStickyHeader={true}
				// enableBottomToolbar={true}
				// enableTopToolbar={false}
				// enableRowSelection={false}
				// enableMultiRowSelection={false}
				// getRowId={(row) => row.id}
				// muiTableBodyRowProps={{ hover: false }}
				muiTableProps={{
					sx: {
						border: "1px solid rgba(81, 81, 81, 1)",
					},
				}}
				muiTableHeadCellProps={{
					sx: {
						border: "1px solid rgba(81, 81, 81, 1)",
					},
				}}
				muiTableBodyCellProps={{
					sx: {
						border: "1px solid rgba(81, 81, 81, 1)",
					},
				}}
			/>
		)
	);
};

const InputXML = ({ workflowId }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [inputXmlData, setInputXmlData] = useState([]);
	const [inputXmlDataIsError, setInputXmlDataIsError] = useState(false);
	const [inputXmlDataIsLoading, setInputXmlDataIsLoading] = useState(false);
	const [inputXmlDataIsRefetching, setInputXmlDataIsRefetching] =
		useState(false);
	const [inputXmlDataRowCount, setInputXmlDataRowCount] = useState(0);

	const fetchInputXmlData = () => {
		const apiPath = "/workflow/inputXML";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set("id", `${workflowId}`);
		httpService.getAPI(
			apiUrl,
			inputXmlData,
			setInputXmlData,
			setInputXmlDataRowCount,
			setInputXmlDataIsLoading,
			setInputXmlDataIsRefetching,
			setInputXmlDataIsError
		);
	};

	useEffect(() => {
		fetchInputXmlData();
	}, []);

	const handleCopyClick = () => {
		if (inputXmlData[0].xml_received) {
			navigator.clipboard.writeText(inputXmlData[0].xml_received);
		}
	};

	return (
		inputXmlData.length === 1 && (
			<Box m={2}>
				<Button variant="contained" color="primary" onClick={handleCopyClick}>
					Copy to Clipboard
				</Button>
				<pre className="xml-body">{inputXmlData[0].xml_received}</pre>
			</Box>
		)
	);
};

const ApiCalls = ({ runId }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [apiCallsData, setApiCallsData] = useState([]);
	const [apiCallsDataIsError, setApiCallsDataIsError] = useState(false);
	const [apiCallsDataIsLoading, setApiCallsDataIsLoading] = useState(false);
	const [apiCallsDataIsRefetching, setApiCallsDataIsRefetching] =
		useState(false);
	const [apiCallsDataRowCount, setApiCallsDataRowCount] = useState(0);

	const [showRequestBody, setShowRequestBody] = useState(false);
	const [showResponseBody, setShowResponseBody] = useState(false);
	const [requestBody, setRequestBody] = useState(null);
	const [responseBody, setResponseBody] = useState(null);

	const handleCloseRequestBody = () => {
		setShowRequestBody(false);
	};

	const handleCloseResponseBody = () => {
		setShowResponseBody(false);
	};

	const requestBodyRef = useRef(null);
	const handleCopyToClipboardRequestBody = () => {
		if (requestBodyRef.current) {
			requestBodyRef.current.select();
			navigator.clipboard.writeText(requestBodyRef.current.value);
		}
	};

	const responseBodyRef = useRef(null);
	const handleCopyToClipboardResponseBody = () => {
		if (responseBodyRef.current) {
			responseBodyRef.current.select();
			navigator.clipboard.writeText(responseBodyRef.current.value);
		}
	};

	const fetchApiCallsData = () => {
		const apiPath = "/workflow/taskResponse";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set("runid", `${runId}`);
		httpService.getAPI(
			apiUrl,
			apiCallsData,
			setApiCallsData,
			setApiCallsDataRowCount,
			setApiCallsDataIsLoading,
			setApiCallsDataIsRefetching,
			setApiCallsDataIsError
		);
	};

	useEffect(() => {
		fetchApiCallsData();
	}, []);

	const columns = useMemo(
		() => [
			{
				accessorKey: "api_type",
				header: "API Type",
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				id: "start_ts",
				accessorFn: (row) =>
					row["start_ts"] ? convertToDate(row["start_ts"]) : "",
				header: "Start Date",
				maxSize: 100,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
			},
			{
				accessorKey: "http_response_code",
				header: "Response Code",
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: "retries",
				header: "Retries",
				size: 100,
				isInput: false,
				enableEditing: false,
			},
		],
		[]
	);

	//console.log(apiCallsData);

	return (
		<>
			<MaterialReactTable
				columns={columns}
				data={apiCallsData}
				enableColumnActions={false}
				enableColumnFilters={false}
				// enablePagination={true}
				enableSorting={false}
				enableGlobalFilter={false}
				enableDensityToggle={false}
				enableHiding={false}
				enableStickyHeader={true}
				// enableBottomToolbar={true}
				// enableTopToolbar={false}
				getRowId={(row) => row.id}
				enableRowActions={true}
				positionActionsColumn="last"
				//muiTableBodyRowProps={{ hover: false }}
				muiTableProps={{
					sx: {
						border: "1px solid rgba(81, 81, 81, 1)",
					},
				}}
				muiTableHeadCellProps={{
					sx: {
						border: "1px solid rgba(81, 81, 81, 1)",
					},
				}}
				muiTableBodyCellProps={{
					sx: {
						border: "1px solid rgba(81, 81, 81, 1)",
					},
				}}
				renderRowActionMenuItems={({ closeMenu, row, table }) => [
					<MenuItem
						key="Request Body"
						onClick={() => {
							setRequestBody(row.original.http_request_body);
							setShowRequestBody(true);
						}}
					>
						<ListItemIcon>
							<EastIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: "h6" }}
							primary="Request Body"
						/>
					</MenuItem>,
					<MenuItem
						key="Response Body"
						onClick={() => {
							setResponseBody(row.original.http_response_body);
							setShowResponseBody(true);
						}}
					>
						<ListItemIcon>
							<WestIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: "h6" }}
							primary="Response Body"
						/>
					</MenuItem>,
				]}
			/>
			<div>
				<Dialog
					open={showRequestBody}
					onClose={handleCloseRequestBody}
					maxWidth="xl"
				>
					<DialogContent>
						<textarea
							ref={requestBodyRef}
							value={requestBody}
							readOnly
							style={{ width: "50vw", height: "300px", color: "#000000" }}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseRequestBody}>Close</Button>
						<Button onClick={handleCopyToClipboardRequestBody}>
							Copy to Clipboard
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={showResponseBody}
					onClose={handleCloseResponseBody}
					maxWidth="xl"
				>
					<DialogContent>
						<textarea
							ref={responseBodyRef}
							value={responseBody}
							readOnly
							style={{ width: "50vw", height: "300px", color: "#000000" }}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseResponseBody}>Close</Button>
						<Button onClick={handleCopyToClipboardResponseBody}>
							Copy to Clipboard
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</>
	);
};

const RenderRightDrawer = ({ isOpen, onClose, rowData }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [detailsActiveTab, setDetailsActiveTab] = useState(0);
	const detailsHandleTabChange = (newValue) => {
		// Update the selected tab when the user changes tabs
		setDetailsActiveTab(newValue);
	};

	const [activeTab, setActiveTab] = useState(0);
	const handleTabChange = (newValue) => {
		// Update the selected tab when the user changes tabs
		setActiveTab(newValue);
	};

	// Define attributes to show with their display names
	const attributesToShow = [
		{
			key: "id",
			displayName: "Workflow ID",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "dag_id",
			displayName: "DAG ID",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "mainkey",
			displayName: "Main Key",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "status",
			displayName: "Status",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "start_ts",
			displayName: "Start Date",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "datetime",
		},
		{
			key: "last_task_date",
			displayName: "Last Date",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "datetime",
		},
		{
			key: "run_id",
			displayName: "Run ID",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
	];

	const tabsData = [
		{
			label: "Tasks",
			content: <Tasks workflowId={rowData.id} />,
		},
		{
			label: "keys",
			content: (
				<Box
					sx={{
						margin: "auto",
						width: "100%",
					}}
				>
					<pre>{JSON.stringify(rowData.keys, null, 2)}</pre>
				</Box>
			),
		},
		{
			label: "Input XML",
			content: <InputXML workflowId={rowData.id} />,
		},
		{
			label: "API Calls",
			content: <ApiCalls runId={rowData.run_id} />,
		},
	];

	const detailsTabsData = [
		{
			label: "",
			disabled: true,
			content: (
				<Box>
					<Box>
						<DetailsBox
							detailsData={rowData}
							attributesToShow={attributesToShow}
						/>
					</Box>
				</Box>
			),
		},
	];

	return (
		<RightDrawer
			isOpen={isOpen}
			onClose={onClose}
			title={`Workflow ID: ${rowData["id"]}`}
			tabs={tabsData}
			activeTab={activeTab}
			onTabChange={handleTabChange}
			detailsTabs={detailsTabsData}
			detailsActiveTab={detailsActiveTab}
			detailsOnTabChange={detailsHandleTabChange}
			detailsPanelTitle={"Details"}
		/>
	);
};

export default WorkFlows;
