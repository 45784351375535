import React, { createContext, useContext, useState } from 'react';
import UserService from '../services/UserService';

const UserContext = createContext();
const UpdateUserContext = createContext();

const useUserContext = () => {
	return useContext(UserContext);
};

const useUpdateUserContext = () => {
	return useContext(UpdateUserContext);
};

const UserContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);

	const getUser = () => {
		return user;
	};

	const logout = () => {
		UserService.doLogout();
		setUser(null);
	};

	const initUser = () => {
		if (!UserService.isLoggedIn()) {
			UserService.doLogin();
		} else if (!user) {
			setUser({
				name: UserService.getUsername() || 'Mr Unknown',
				realmRoles: UserService.getRealmAccessRoles(),
				resourceRoles: UserService.getResourceAccessRoles(),
				token: UserService.getToken(),
				firstName: UserService.getFirstName() || 'Unknown',
				lastName: UserService.getLastName() || 'User',
				hasRole: UserService.hasRole,
			});
		}
	};

	!user && initUser();

	return (
		<UserContext.Provider value={{ setUser, getUser, logout }}>
			<UpdateUserContext.Provider value={setUser}>
				{children}
			</UpdateUserContext.Provider>
		</UserContext.Provider>
	);
};

export { UserContextProvider, useUserContext, useUpdateUserContext };
