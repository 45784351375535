import {
	useTheme,
	Box,
	IconButton,
	Tooltip,
	darken,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import Header from '../../components/Header/Header.jsx';
import useHttpService from '../../customHooks/useHttpService.js';
import { Refresh } from '@mui/icons-material';
import DetailsIcon from '@mui/icons-material/Details';
import RightDrawer from '../../components/RightDrawer/RightDrawer';
import DetailsBox from '../../components/DetailsBox/DetailsBox';
import { MaterialReactTable } from 'material-react-table';
import { HistoryOutlined, WorkOutline } from '@mui/icons-material';
import { tokens } from '../../theme.js';
import { useNavigate } from 'react-router-dom';
import { convertToDate } from '../../utils';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import KeyValueGrid from '../../components/KeyValueGrid/KeyValueGrid.jsx';

const SaleOrders = () => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const tableInstanceRef = useRef(null);

	const navigate = useNavigate();

	// main table -> SalesOrders
	const [salesOrdersData, setSalesOrdersData] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const [detailsOpen, setDetailsOpen] = useState(false);
	const [detailsRow, setDetailsRow] = useState({});

	const fetchSalesOrdersData = () => {
		const apiPath = '/sid/v_dashboard_salesorder';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		apiUrl.searchParams.set(
			'start',
			`${pagination.pageIndex * pagination.pageSize}`
		);
		apiUrl.searchParams.set('size', `${pagination.pageSize}`);
		//console.log(columnFilters);

		columnFilters.forEach((columnFilter) => {
			if (columnFilter.id == 'customerId') {
				apiUrl.searchParams.set('customerid', columnFilter.value);
			} else if (columnFilter.id == 'ServiceOrderId') {
				apiUrl.searchParams.set('serviceorderid', columnFilter.value);
			} else if (columnFilter.id == 'state') {
				apiUrl.searchParams.set('state', columnFilter.value);
			} else if (columnFilter.id == 'status') {
				apiUrl.searchParams.set('status', columnFilter.value);
			} else if (columnFilter.id == 'phase') {
				apiUrl.searchParams.set('phase', columnFilter.value);
			} else if (columnFilter.id == 'requestedStartDate') {
				apiUrl.searchParams.set('requestedstartdate', columnFilter.value);
			} else if (columnFilter.id == 'max_end_ts') {
				apiUrl.searchParams.set('maxendts', columnFilter.value);
			} else if (columnFilter.id == 'max_version') {
				if (columnFilter.value[0])
				{
					apiUrl.searchParams.set('version_min', columnFilter.value[0]);
				}
				if (columnFilter.value[1])
				{
					apiUrl.searchParams.set('version_max', columnFilter.value[1]);
				}
			} else if (columnFilter.id == 'estimate_events') {
				if (columnFilter.value[0])
				{
					apiUrl.searchParams.set('estimate_events_min', columnFilter.value[0]);
				}
				if (columnFilter.value[1])
				{
					apiUrl.searchParams.set('estimate_events_max', columnFilter.value[1]);
				}
			} else if (columnFilter.id == 'estimate_service') {
				if (columnFilter.value[0])
				{
					apiUrl.searchParams.set('estimate_service_min', columnFilter.value[0]);
				}
				if (columnFilter.value[1])
				{
					apiUrl.searchParams.set('estimate_service_max', columnFilter.value[1]);
				}
			}
		});

		httpService.getAPI(
			apiUrl,
			salesOrdersData,
			setSalesOrdersData,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};
	useEffect(() => {
		fetchSalesOrdersData();
	}, [
		fetchRequired,
		columnFilters,
		//globalFilter,
		pagination.pageIndex,
		pagination.pageSize,
		//sorting,
	]);

	const getAlert = () => {
		if (isError) {
			return {
				color: 'error',
				children: 'Error loading data',
			};
		}

		return undefined;
	};

	const handleCloseDetails = () => {
		setDetailsOpen(false);
	};

	const toWorkOrdersPage = (ServiceOrderId) => {
		navigate('/workorders', { state: { ServiceOrderId: ServiceOrderId } });
	};
	const toEventsPage = (ServiceOrderId) => {
		navigate('/events', { state: { ServiceOrderId: ServiceOrderId } });
	};

	const columns = useMemo(
		() => [
			{
				accessorKey: 'customerId',
				header: 'Customer ID',
				size: 80,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
				filterFn: 'contains',
			},
			{
				accessorKey: 'ServiceOrderId',
				header: 'Order ID',
				size: 80,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
				filterFn: 'contains',
			},
			{
				accessorKey: 'state',
				header: 'State',
				size: 80,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
				filterFn: 'contains',
			},
			{
				accessorKey: 'status',
				header: 'Status',
				size: 80,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
				filterFn: 'contains',
			},
			{
				accessorKey: 'phase',
				header: 'Phase',
				size: 80,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
				filterFn: 'contains',
			},
			{
				id: 'requestedStartDate',
				accessorFn: (row) =>
					row['requestedStartDate'] ? convertToDate(row['requestedStartDate']) : '',
				header: 'Created at',
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: 'Filter: From indicated datetime',
									sx: { minWidth: '120px' },
									variant: 'standard',
								},
								field: { clearable: true },
							}}
							ampm={false}
							format='dd/MM/yyyy HH:mm:ss'
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
			},
			{
				id: 'max_end_ts',
				accessorFn: (row) =>
					row['max_end_ts'] ? convertToDate(row['max_end_ts']) : '',
				header: 'Updated at',
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: 'Filter: From indicated datetime',
									sx: { minWidth: '120px' },
									variant: 'standard',
								},
								field: { clearable: true },
							}}
							ampm={false}
							format='dd/MM/yyyy HH:mm:ss'
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
			},
			{
				id: 'max_version',
				header: 'Versions',
				accessorFn: (originalRow) =>
					originalRow.max_version ? originalRow.max_version : '0',
				size: 80,
				isInput: false,
				enableEditing: false,
				columnFilterModeOptions: ['betweenInclusive'],
				filterFn: 'betweenInclusive',
			},
			{
				id: 'estimate_events',
				header: 'Events',
				accessorFn: (originalRow) =>
					originalRow.estimate_events ? originalRow.estimate_events : '0',
				size: 80,
				isInput: false,
				enableEditing: false,
				columnFilterModeOptions: ['betweenInclusive'],
				filterFn: 'betweenInclusive',
			},
			{
				id: 'estimate_service',
				header: 'Services',
				accessorFn: (originalRow) =>
					originalRow.estimate_service ? originalRow.estimate_service : '0',
				size: 80,
				isInput: false,
				enableEditing: false,
				columnFilterModeOptions: ['betweenInclusive'],
				filterFn: 'betweenInclusive',
			},
		],
		[]
	);

	return (
		<Box m='20px'>
			<Header title='Sales Orders' subtitle={'List of Sales Orders'} />
			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				positionActionsColumn='last'
				muiTableContainerProps={{ sx: { maxHeight: '55vh' } }}
				displayColumnDefOptions={{
					'mrt-row-actions': {
						muiTableHeadCellProps: {
							align: 'left',
							sx: {
								fontWeight: 'bold',
								color: colors.grey[100],
								typography: 'h5',
							},
						},
						muiTableBodyCellProps: {
							align: 'left',
						},
						size: 40,
					},
				}}
				columns={columns}
				data={salesOrdersData}
				//getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: false,
					density: 'comfortable',
				}}
				// muiTopToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				// muiBottomToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				muiTableHeadCellProps={{
					align: 'left',
					sx: {
						fontWeight: 'bold',
						color: colors.grey[100],
						typography: 'h5',
					},
				}}
				muiTableBodyCellProps={{
					align: 'left',
					sx: {
						color: colors.grey[100],
						typography: 'h6',
					},
				}}
				muiTableBodyProps={{
					sx: (theme) => ({
						'& tr:nth-of-type(odd)': {
							backgroundColor: darken(theme.palette.background.default, 0.05),
						},
					}),
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				rowCount={
				    salesOrdersData && salesOrdersData.length > 0
				        ? salesOrdersData[0].totalDBRowCount
				        : 0
				}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableSorting={false}
				enableDensityToggle={false}
				enableColumnOrdering={false}
				enableHiding={false}
				enableRowActions={true}
				enableGlobalFilter={false}
				manualFiltering={true}
				manualPagination={true}
				manualSorting={false}
				renderRowActionMenuItems={({ closeMenu, row, table }) => [
					<MenuItem
						key='Versions'
						onClick={() => {
							toWorkOrdersPage(row.original.ServiceOrderId);
						}}
					>
						<ListItemIcon>
							<WorkOutline />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: 'h6' }}
							primary='Versions'
						/>
					</MenuItem>,
					<MenuItem
						key='Events'
						onClick={() => {
							toEventsPage(row.original.ServiceOrderId);
						}}
					>
						<ListItemIcon>
							<HistoryOutlined />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: 'h6' }}
							primary='Events'
						/>
					</MenuItem>,
					<MenuItem
						key='details'
						onClick={() => {
							closeMenu();
							setDetailsRow(row.original);
							setDetailsOpen(true);
						}}
					>
						<ListItemIcon>
							<DetailsIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: 'h6' }}
							primary='Details'
						/>
					</MenuItem>,
				]}
				muiTableBodyRowProps={({ row }) => ({
					onDoubleClick: (event) => {
						setDetailsRow(row.original);
						setDetailsOpen(true);
					},
					sx: {
						cursor: 'pointer',
					},
				})}
				//renderDetailPanel={RenderDetail}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title='Refresh'>
								<IconButton
									onClick={() => {
										setFetchRequired(prev => !prev);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
			/>
			{detailsOpen && (
				<RenderRightDrawer
					rowData={detailsRow}
					isOpen={detailsOpen}
					onClose={handleCloseDetails}
				/>
			)}
		</Box>
	);
};

const OrderRequests = ({
	serviceOrderId,
	rowSelection,
	setRowSelection,
	setData,
}) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [orData, setOrData] = useState([]);
	const [orDataIsError, setOrDataIsError] = useState(false);
	const [orDataIsLoading, setOrDataIsLoading] = useState(false);
	const [orDataIsRefetching, setOrDataIsRefetching] = useState(false);
	const [orDataRowCount, setOrDataRowCount] = useState(0);

	const [localServiceOrderId, setLocalServiceOrderId] =
		useState(serviceOrderId);
	useEffect(() => {
		setLocalServiceOrderId(serviceOrderId);
	}, [serviceOrderId]);

	const fetchOrData = () => {
		const apiPath = '/sid/ServiceOrderService';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set('serviceorderid', `${localServiceOrderId}`);
		httpService.getAPI(
			apiUrl,
			orData,
			setOrData,
			setOrDataRowCount,
			setOrDataIsLoading,
			setOrDataIsRefetching,
			setOrDataIsError
		);
	};

	useEffect(() => {
		fetchOrData();
	}, []);

	const onRowSelection = (rowSelection) => {
		setRowSelection(rowSelection);
		setData(orData);
	};

	const columns = useMemo(
		() => [
			{
				accessorKey: 'description',
				header: 'Description',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: 'state',
				header: 'State',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: 'pon',
				header: 'PON',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
		],
		[]
	);

	return (
		<MaterialReactTable
			columns={columns}
			data={orData}
			enableColumnActions={false}
			enableColumnFilters={false}
			// enablePagination={true}
			enableSorting={false}
			enableGlobalFilter={false}
			enableDensityToggle={false}
			enableHiding={false}
			enableStickyHeader={true}
			// enableBottomToolbar={true}
			// enableTopToolbar={false}
			enableRowSelection={true}
			enableMultiRowSelection={false}
			getRowId={(row) => row.id}
			onRowSelectionChange={onRowSelection}
			state={{ rowSelection }}
			//muiTableBodyRowProps={{ hover: false }}
			muiTableBodyRowProps={({ row }) => ({
				onClick: row.getToggleSelectedHandler(),
				sx: { cursor: 'pointer' },
			})}
			muiTableProps={{
				sx: {
					border: '1px solid rgba(81, 81, 81, 1)',
				},
			}}
			muiTableHeadCellProps={{
				sx: {
					border: '1px solid rgba(81, 81, 81, 1)',
				},
			}}
			muiTableBodyCellProps={{
				sx: {
					border: '1px solid rgba(81, 81, 81, 1)',
				},
			}}
		/>
	);
};

const Products = ({ serviceOrderServiceId, title }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [prData, setPrData] = useState([]);
	const [prDataIsError, setPrDataIsError] = useState(false);
	const [prDataIsLoading, setPrDataIsLoading] = useState(false);
	const [prDataIsRefetching, setPrDataIsRefetching] = useState(false);
	const [prDataRowCount, setPrDataRowCount] = useState(0);

	const fetchPrData = () => {
		const apiPath = '/sid/ServiceOrderServiceItem';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set(
			'serviceorderserviceid',
			`${serviceOrderServiceId}`
		);
		httpService.getAPI(
			apiUrl,
			prData,
			setPrData,
			setPrDataRowCount,
			setPrDataIsLoading,
			setPrDataIsRefetching,
			setPrDataIsError
		);
	};

	useEffect(() => {
		if (serviceOrderServiceId !== null && serviceOrderServiceId !== undefined) {
			fetchPrData();
		}
	}, []);

	const columns = useMemo(
		() => [
			{
				accessorKey: 'Product',
				header: 'Product',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: 'ProductID',
				header: 'Product ID',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
		],
		[]
	);

	return (
		<>
			{serviceOrderServiceId ? (
				<MaterialReactTable
					columns={columns}
					data={prData}
					enableColumnActions={false}
					enableColumnFilters={false}
					// enablePagination={true}
					enableSorting={false}
					enableGlobalFilter={false}
					enableDensityToggle={false}
					enableHiding={false}
					enableStickyHeader={true}
					// enableBottomToolbar={true}
					// enableTopToolbar={false}
					//enableRowSelection={false}
					//enableMultiRowSelection={false}
					getRowId={(row) => row.id}
					// muiTableBodyRowProps={{ hover: false }}
					muiTableProps={{
						sx: {
							border: '1px solid rgba(81, 81, 81, 1)',
						},
					}}
					muiTableHeadCellProps={{
						sx: {
							border: '1px solid rgba(81, 81, 81, 1)',
						},
					}}
					muiTableBodyCellProps={{
						sx: {
							border: '1px solid rgba(81, 81, 81, 1)',
						},
					}}
					renderDetailPanel={({ row }) => (
						<div style={{ padding: '20px' }}>
							{row.original.json && <KeyValueGrid data={row.original.json} />}
						</div>
					)}
					renderTopToolbarCustomActions={({ table }) =>
						title && (
							<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
								<Typography variant='h6'>{title}</Typography>
							</Box>
						)
					}
				/>
			) : (
				<Box style={{ display: 'flex', justifyContent: 'center' }}>
					<Typography variant='h6'>
						Please select a service from previous tab to see the relevant
						products.
					</Typography>
				</Box>
			)}
		</>
	);
};

const CustomerDetails = ({ customerId }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [customerData, setCustomerData] = useState([]);
	const [customerDataIsError, setCustomerDataIsError] = useState(false);
	const [customerDataIsLoading, setCustomerDataIsLoading] = useState(false);
	const [customerDataIsRefetching, setCustomerDataIsRefetching] =
		useState(false);
	const [customerDataRowCount, setCustomerDataRowCount] = useState(0);

	const fetchCustomerData = () => {
		const apiPath = '/sid/Customer';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set('customerid', `${customerId}`);
		httpService.getAPI(
			apiUrl,
			customerData,
			setCustomerData,
			setCustomerDataRowCount,
			setCustomerDataIsLoading,
			setCustomerDataIsRefetching,
			setCustomerDataIsError
		);
	};

	useEffect(() => {
		fetchCustomerData();
	}, []);

	const attributesToShow = [
		{
			key: 'customerID',
			displayName: 'Customer ID',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'name',
			displayName: 'Name',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'type',
			displayName: 'Type',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'buildingId',
			displayName: 'Building ID',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'streetName',
			displayName: 'Street Name',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'streetNr',
			displayName: 'Street Number',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'staircase',
			displayName: 'Staircase',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'city',
			displayName: 'City',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'fiscalCode',
			displayName: 'Fiscal Code',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'PIVA',
			displayName: 'PIVA',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'phone',
			displayName: 'Phone',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'otherPhone',
			displayName: 'Other Phone',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'Province',
			displayName: 'Province',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
	];

	return (
        customerData.length === 1 &&
		<DetailsBox
			detailsData={customerData[0]}
			attributesToShow={attributesToShow}
		/>
	);
};

const RenderRightDrawer = ({ isOpen, onClose, rowData }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [selectedOrderId, setSelectedOrderId] = useState(
		rowData['id']
	);
	const [selectedServiceOrderId, setSelectedServiceOrderId] = useState(
		rowData['ServiceOrderId']
	);
	const [selectedServiceId, setSelectedServiceId] = useState(null);

	const [detailsActiveTab, setDetailsActiveTab] = useState(0);
	const detailsHandleTabChange = (newValue) => {
		// Update the selected tab when the user changes tabs
		setDetailsActiveTab(newValue);
	};

	const [activeTab, setActiveTab] = useState(0);
	const handleTabChange = (newValue) => {
		// Update the selected tab when the user changes tabs
		setActiveTab(newValue);
	};

	useEffect(() => {
		setSelectedOrderId(rowData['id']);
		setSelectedServiceOrderId(rowData['ServiceOrderId']);
	}, [rowData]);

	const [selectedData, setSelectedData] = useState(null);
	const [orData, setOrData] = useState(null);
	const [rowSelection, setRowSelection] = useState({});
	useEffect(() => {
		if (Object.keys(rowSelection).length === 0) {
			setSelectedServiceId(null);
		} else {
			// Get the first key and return it
			setSelectedServiceId(Object.keys(rowSelection)[0]);
			setSelectedData(
				orData.find((item) => item.id === Object.keys(rowSelection)[0])
			);
			setActiveTab(1);
		}
	}, [rowSelection]);

	// Define attributes to show with their display names
	const attributesToShow = [
		{
			key: 'customerId',
			displayName: 'Customer ID',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'ServiceOrderId',
			displayName: 'Order ID',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'state',
			displayName: 'State',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'status',
			displayName: 'Status',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'phase',
			displayName: 'Phase',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'requestedStartDate',
			displayName: 'Created at',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'datetime',
		},
		{
			key: 'max_end_ts',
			displayName: 'Updated at',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'datetime',
		},
	];

	const tabsData = [
		{
			label: 'Services',
			content: (
				<OrderRequests
					serviceOrderId={selectedOrderId}
					rowSelection={rowSelection}
					setRowSelection={setRowSelection}
					setData={setOrData}
				/>
			),
		},
		{
			label: 'Products',
			content: (
				<Products
				serviceOrderServiceId={selectedServiceId}
					title={
						selectedData
							? `Selected Service: ${selectedData.description}`
							: ' '
					}
				/>
			),
		},
	];

	const detailsTabsData = [
		{
			label: 'Order',
			content: (
				<Box>
					<Box>
						<DetailsBox
							detailsData={rowData}
							attributesToShow={attributesToShow}
						/>
					</Box>
				</Box>
			),
		},
		{
			label: 'Customer',
			content: (
                rowData &&
				<Box>
					<Box>
						<CustomerDetails customerId={rowData.customerId} />
					</Box>
				</Box>
			),
		},
	];

	return (
		<RightDrawer
			isOpen={isOpen}
			onClose={onClose}
			title={`Order ID: ${selectedServiceOrderId}`}
			tabs={tabsData}
			activeTab={activeTab}
			onTabChange={handleTabChange}
			detailsTabs={detailsTabsData}
			detailsActiveTab={detailsActiveTab}
			detailsOnTabChange={detailsHandleTabChange}
			detailsPanelTitle={'Details'}
		/>
	);
};

export default SaleOrders;
