import {
	useTheme,
	Box,
	IconButton,
	Tooltip,
	darken,
	MenuItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import useHttpService from "../../customHooks/useHttpService.js";
import { CollectionsBookmarkRounded, Refresh } from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";
import { tokens } from "../../theme";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { convertToDate } from "../../utils";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

const Events = () => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const tableInstanceRef = useRef(null);

	const location = useLocation();

	const navigate = useNavigate();
	const toWorkflowsPage = (runId) => {
		navigate("/workflows", { state: { runId: runId } });
	};

	// main table -> Events
	const [eventsData, setEventsData] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const [locationFilterApplied, setLocationFilterApplied] = useState(false);

	const fetchEventsData = () => {
		const apiPath = "/sid/WorkflowEvents";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		apiUrl.searchParams.set(
			"start",
			`${pagination.pageIndex * pagination.pageSize}`
		);
		apiUrl.searchParams.set("size", `${pagination.pageSize}`);

		columnFilters.forEach((columnFilter) => {
			if (columnFilter.id === "event_key") {
				apiUrl.searchParams.set("event_key", columnFilter.value);
			} else if (columnFilter.id === "event_type") {
				apiUrl.searchParams.set("event_type", columnFilter.value);
			} else if (columnFilter.id === "received_ts") {
				apiUrl.searchParams.set("received_ts", columnFilter.value);
			} else if (columnFilter.id === "managed_ts") {
				apiUrl.searchParams.set("managed_ts", columnFilter.value);
			} else if (columnFilter.id === "propagated_ts") {
				apiUrl.searchParams.set("propagated_ts", columnFilter.value);
			} else if (columnFilter.id === "notification_type") {
				apiUrl.searchParams.set("notification_type", columnFilter.value);
			} else if (columnFilter.id === "event_description") {
				apiUrl.searchParams.set("event_description", columnFilter.value);
			}
		});

		httpService.getAPI(
			apiUrl,
			eventsData,
			setEventsData,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	const getAlert = () => {
		if (isError) {
			return {
				color: "error",
				children: "Error loading data",
			};
		}

		return undefined;
	};

	const minifyID = (ID) => {
		return ID.split("-")[0] + " " + "\u00B7".repeat(3);
	};

	useEffect(() => {
		if (
			location.state &&
			location.state.ServiceOrderId &&
			!locationFilterApplied
		) {
			setColumnFilters((prev) => [
				...prev,
				{ id: "event_key", value: location.state.ServiceOrderId },
			]);
		}
		setLocationFilterApplied(true);
	}, [location]);

	useEffect(() => {
		if (locationFilterApplied) {
			fetchEventsData();
		}
		if (fetchRequired) {
			setFetchRequired(false);
		}
	}, [
		fetchRequired,
		columnFilters,
		//globalFilter,
		pagination.pageIndex,
		pagination.pageSize,
		//sorting,
	]);

	const columns = useMemo(
		() => [
			// {
			// 	accessorKey: 'id',
			// 	header: 'ID',
			// 	size: 80,
			// 	isInput: false,
			// 	enableEditing: false,
			// 	enableClickToCopy: true,

			// 	Cell: ({ row }) => (
			// 		<Tooltip title={row.original.id} arrow>
			// 			<Typography variant='h6' color='primary'>
			// 				{minifyID(row.original.id)}
			// 			</Typography>
			// 		</Tooltip>
			// 	),
			// },
			{
				accessorKey: "event_key",
				header: "Event Key",
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: "event_type",
				header: "Event Type",
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: "event_description",
				header: "Event Description",
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: "notification_type",
				header: "Notification Type",
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				id: "received_ts",
				accessorFn: (row) =>
					row["received_ts"] ? convertToDate(row["received_ts"]) : "",
				header: "Received",
				maxSize: 100,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: "Filter: From indicated datetime",
									sx: { minWidth: "120px" },
									variant: "standard",
								},
								field: { clearable: true },
							}}
							ampm={false}
							format="dd/MM/yyyy HH:mm:ss"
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
			},
			{
				id: "managed_ts",
				accessorFn: (row) =>
					row["managed_ts"] ? convertToDate(row["managed_ts"]) : "",
				header: "Managed",
				maxSize: 100,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: "Filter: From indicated datetime",
									sx: { minWidth: "120px" },
									variant: "standard",
								},
								field: { clearable: true },
							}}
							ampm={false}
							format="dd/MM/yyyy HH:mm:ss"
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
			},
			{
				id: "propagated_ts",
				accessorFn: (row) =>
					row["propagated_ts"] ? convertToDate(row["propagated_ts"]) : "",
				header: "Propagated",
				maxSize: 100,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: "Filter: From indicated datetime",
									sx: { minWidth: "120px" },
									variant: "standard",
								},
								field: { clearable: true },
							}}
							ampm={false}
							format="dd/MM/yyyy HH:mm:ss"
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
			},
			// {
			// 	accessorKey: 'run_id',
			// 	header: 'Run ID',
			// 	size: 100,
			// 	isInput: false,
			// 	enableEditing: false,
			// 	enableClickToCopy: true,
			// },
		],
		[]
	);

	return (
		<Box m="20px">
			<Header title="Events" subtitle={"List of Events"} />
			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
				displayColumnDefOptions={{
					"mrt-row-actions": {
						muiTableHeadCellProps: {
							align: "left",
							sx: {
								fontWeight: "bold",
								color: colors.grey[100],
								typography: "h5",
							},
						},
						muiTableBodyCellProps: {
							align: "left",
						},
						size: 40,
					},
				}}
				positionActionsColumn="last"
				columns={columns}
				data={eventsData}
				//getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: false,
					density: "comfortable",
				}}
				// muiTopToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				// muiBottomToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				muiTableHeadCellProps={{
					align: "left",
					sx: {
						fontWeight: "bold",
						color: colors.grey[100],
						typography: "h5",
					},
				}}
				muiTableBodyCellProps={{
					align: "left",
					sx: {
						color: colors.grey[100],
						typography: "h6",
					},
				}}
				muiTableBodyProps={{
					sx: (theme) => ({
						"& tr:nth-of-type(odd)": {
							backgroundColor: darken(theme.palette.background.default, 0.05),
						},
					}),
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				//rowCount={rowCount}
				rowCount={
					eventsData && eventsData.length > 0
						? eventsData[0].totalDBRowCount
						: 0
				}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableSorting={false}
				enableDensityToggle={false}
				enableColumnOrdering={false}
				enableHiding={false}
				enableRowActions={true}
				enableGlobalFilter={false}
				manualFiltering={true}
				manualPagination={true}
				manualSorting={false}
				renderRowActionMenuItems={({ closeMenu, row, table }) => [
					<MenuItem
						key="View Workflow"
						onClick={() => {
							toWorkflowsPage(row.original["run_id"]);
						}}
					>
						<ListItemIcon>
							<AccountTreeIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: "h6" }}
							primary="View Workflow"
						/>
					</MenuItem>,
				]}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title="Refresh">
								<IconButton
									onClick={() => {
										setFetchRequired(true);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
			/>
		</Box>
	);
};

export default Events;
