import {
	Typography,
	Box,
	useTheme,
	Drawer,
	AppBar,
	Toolbar,
	Button,
	Tabs,
	Tab,
	Card,
	CardContent,
	Grid,
	Divider,
	ThemeProvider,
} from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../theme";

const RightDrawer = ({
	isOpen,
	onClose,
	title,
	tabs,
	activeTab,
	onTabChange,
	detailsTabs,
	detailsActiveTab,
	detailsOnTabChange,
	detailsPanelTitle,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	//const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (event, newValue) => {
		//setTabValue(newValue);
		onTabChange(newValue);
	};

	const handleDetailsTabChange = (event, newValue) => {
		//setTabValue(newValue);
		detailsOnTabChange(newValue);
	};

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			PaperProps={{ style: { width: "80vw" } }}
		>
			<Grid container>
				<Grid item xs={3.5}>
					{/* Left Panel */}
					<AppBar position="static">
						<Toolbar style={{ display: "flex", justifyContent: "center" }}>
							<Typography variant="h6">{detailsPanelTitle}</Typography>
						</Toolbar>
					</AppBar>
					<Tabs
						value={detailsActiveTab}
						onChange={handleDetailsTabChange}
						indicatorColor="secondary"
						textColor="primary"
						variant="fullWidth"
					>
						{detailsTabs.map((tab, index) => (
							<Tab
								key={index}
								label={tab.label}
								disabled={tab.disabled ? tab.disabled : false}
								value={index}
							/>
						))}
					</Tabs>

					<Box mt={2} mb={2} ml={2} mr={2}>
						<Card elevation={3}>
							<CardContent style={{ maxHeight: "80vh", overflowY: "auto" }}>
								{detailsTabs.map(
									(tab, index) => detailsActiveTab === index && tab.content
								)}
							</CardContent>
						</Card>
					</Box>
				</Grid>

				<Grid item xs={0.1}>
					{/* Divider */}
				</Grid>

				<Grid item xs={8.4}>
					{/* Right Panel */}
					<AppBar position="static">
						<Toolbar>
							<Typography variant="h6" style={{ flexGrow: 1 }}>
								{title}
							</Typography>
							<Button variant="contained" color="secondary" onClick={onClose}>
								Close
							</Button>
						</Toolbar>
					</AppBar>

					<Tabs
						value={activeTab}
						onChange={handleTabChange}
						indicatorColor="secondary"
						textColor="primary"
						variant="fullWidth"
					>
						{tabs.map((tab, index) => (
							<Tab key={index} label={tab.label} value={index} />
						))}
					</Tabs>

					<Box mt={2} mb={2} ml={2} mr={2}>
						<Card elevation={3}>
							<CardContent style={{ maxHeight: "80vh", overflowY: "auto" }}>
								{tabs.map((tab, index) => activeTab === index && tab.content)}
							</CardContent>
						</Card>
					</Box>
				</Grid>
			</Grid>
		</Drawer>
	);
};

export default RightDrawer;
