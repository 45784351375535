import {
	Button,
	Box,
	darken,
	IconButton,
	Typography,
	useTheme,
	Checkbox,
	Tooltip,
	FormControlLabel,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { tokens } from "../../theme";
import useHttpService from "../Common/useHttpService";
import { Refresh } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { convertToDate } from "../../utils";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

function MassiveRetry(props) {
	const [manualTaskState, setManualTaskState] = useState([]);
	const [checkAll, setcheckAll] = useState(false);

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);

	const [locationFilterApplied, setLocationFilterApplied] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const [dialogIsOpen, setdialogIsOpen] = useState(false);
	const [isErrorUpdate, setIsErrorUpdate] = useState(false);
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [detailsRow, setDetailsRow] = useState({});
	const handleCloseDetails = () => {
		setDetailsOpen(false);
	};

	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const tableInstanceRef = useRef(null);
	const location = useLocation();

	const apiPathMassiveRetry = "/workflow/manual_tasks_retry";
	const apiUrlMassiveRetry = new URL(
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPathMassiveRetry
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPathMassiveRetry,
		process.env.NODE_ENV === "production"
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const apiPutPathMassiveRetry = "/workflow/manual_tasks_retry";
	const apiUrlPutMassiveRetry = new URL(
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPutPathMassiveRetry
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPutPathMassiveRetry,
		process.env.NODE_ENV === "production"
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const columns = useMemo(() => [
		{
			accessorKey: "dag_name",
			header: "Name",
			size: 100,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
		{
			accessorKey: "description",
			header: "Description",
			size: 200,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
		{
			accessorKey: "manual_task",
			header: "Manual Task",
			size: 20,
			isInput: false,
			enableEditing: false,
		},
		{
			accessorKey: "mainkey",
			header: "Main Key",
			size: 20,
			isInput: false,
			enableEditing: false,
		},
		{
			id: "start_ts",
			accessorFn: (row) =>
				row["start_ts"] ? convertToDate(row["start_ts"]) : "",
			header: "Start",
			maxSize: 200,
			enableEditing: false,
			isInput: false,
			muiTableBodyCellProps: {
				align: "left",
			},
			Filter: ({ column }) => (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateTimePicker
						onChange={(newValue) => {
							convertToDate(newValue) === convertToDate(null)
								? column.setFilterValue(null)
								: column.setFilterValue(new Date(newValue).toISOString());
						}}
						slotProps={{
							textField: {
								helperText: "Filter: From indicated datetime",
								sx: { minWidth: "120px" },
								variant: "standard",
								disabled: true,
							},
							field: { clearable: true },
						}}
						ampm={false}
						format="dd/MM/yyyy HH:mm:ss"
						allowSameDateSelection={true}

						//value={column.getFilterValue()}
					/>
				</LocalizationProvider>
			),
		},
		{
			accessorKey: "run_id",
			header: "Run ID",
			size: 20,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: false,
			Cell: ({ row }) => {
				return (
					<Tooltip title={row.original.run_id} arrow>
						<Typography variant="h6" color="primary">
							{minifyRunID(row.original.run_id)}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			accessorKey: "flag",
			header: "Retry Again?",
			size: 20,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: false,
			enableColumnFilter: false,
			enableColumnActions: false,

			Header: ({ header }) => {
				return (
					<FormControlLabel
						label="Retry Again?"
						labelPlacement="right"
						sx={{ marginLeft: "0px" }}
						control={
							<Checkbox
								checked={checkAll}
								onChange={(e) => {
									setcheckAll(e.target.checked);
									const _manualTaskState = [...manualTaskState];
									_manualTaskState.length > 0 &&
										_manualTaskState.map((el) => {
											el.flag = e.target.checked;
											return el;
										});
									//console.log(_manualTaskState);
									setManualTaskState(_manualTaskState);
								}}
								inputProps={{ "aria-label": "controlled" }}
							/>
						}
					/>
				);
			},
			Cell: ({ row }) => {
				const el =
					manualTaskState.length > 0 &&
					manualTaskState.find((el) => el.id === row.original.id);
				const flag = el.flag ? el.flag : row.original.flag;
				return (
					<Checkbox
						key={row.original.id}
						checked={flag || false}
						onChange={(e) => handleCheckCheckbox(e, row)}
						inputProps={{ "aria-label": "controlled" }}
					/>
				);
			},
		},
	]);

	useEffect(() => {
		if (location.state && location.state.runId && !locationFilterApplied) {
			setColumnFilters((prev) => [
				...prev,
				{ id: "id", value: location.state.runId },
			]);
		}
		setLocationFilterApplied(true);
	}, [location]);

	useEffect(() => {
		if (locationFilterApplied) {
			fetchManualTaskState();
		}
		if (fetchRequired) {
			setFetchRequired(false);
		}
	}, [fetchRequired, columnFilters, pagination.pageIndex, pagination.pageSize]);

	const fetchManualTaskState = () => {
		setIsLoading(true);
		apiUrlMassiveRetry.searchParams.set(
			"start",
			`${pagination.pageIndex * pagination.pageSize}`
		);
		apiUrlMassiveRetry.searchParams.set("size", `${pagination.pageSize}`);

		columnFilters.forEach((columnFilter) => {
			if (columnFilter.id === "dag_name") {
				apiUrlMassiveRetry.searchParams.set("dag_name", columnFilter.value);
			} else if (columnFilter.id === "description") {
				apiUrlMassiveRetry.searchParams.set("description", columnFilter.value);
			} else if (columnFilter.id === "mainkey") {
				apiUrlMassiveRetry.searchParams.set(
					"serviceorderid",
					columnFilter.value
				);
			} else if (columnFilter.id === "manual_task") {
				apiUrlMassiveRetry.searchParams.set("name", columnFilter.value);
			} else if (columnFilter.id === "start_ts") {
				apiUrlMassiveRetry.searchParams.set("start_ts", columnFilter.value);
			} else if (columnFilter.id === "run_id") {
				apiUrlMassiveRetry.searchParams.set("run_id", columnFilter.value);
			}
		});

		httpService.getAPI(apiUrlMassiveRetry.href, (data) => {
			setManualTaskState(data || []);
			setRowCount(data[0]?.totalDBRowCount || 0);
			setIsLoading(false);
			setIsRefetching(false);
			setIsError(false);
			setcheckAll(false);
		});
	};

	const handleCheckCheckbox = (e, row) => {
		const id = row.original.id;
		let _manualTaskState = [...manualTaskState];
		_manualTaskState = _manualTaskState.map((el) => {
			if (el.id === id) el.flag = e.target.checked;
			return el;
		});
		setManualTaskState(_manualTaskState);
	};

	const getAlert = () => {
		if (isError) {
			return {
				color: "error",
				children: "Error loading data",
			};
		}

		return undefined;
	};

	const minifyRunID = (ID) => {
		return ID.split("_")[0] + " " + "\u00B7".repeat(3);
	};

	const saveMassiveRetryData = () => {
		const data =
			manualTaskState.filter((el) => el.flag)?.map((el) => ({ id: el.id })) ||
			[];

		httpService.putAPI(apiUrlPutMassiveRetry.href, data, (res) => {
			if (!res)
				props.setsnack &&
					props.setsnack({
						open: true,
						message: `An error occurred while saving!`,
						status: "error",
					});
			httpService.getAPI(apiUrlMassiveRetry.href, (res) => {
				props.setsnack &&
					props.setsnack({
						open: true,
						message: `Operation successful completed`,
						status: "success",
					});
				setManualTaskState(res);
				setRowCount(data[0]?.totalDBRowCount || 0);
				setIsLoading(false);
				setIsRefetching(false);
				setIsError(false);
			});
		});
	};

	return (
		<Box>
			<Dialog
				open={dialogIsOpen}
				onClose={() => setdialogIsOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title" fontWeight="bold" fontSize="16pt">
					Confirmation Alert
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you really want to retry{" "}
						{manualTaskState.filter((el) => el.flag).length} tasks?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setdialogIsOpen(false)}
						variant="contained"
						color="secondary"
					>
						No
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={() => {
							setdialogIsOpen(false);
							saveMassiveRetryData();
						}}
						autoFocus
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
				columns={columns}
				data={manualTaskState}
				//getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: false,
					density: "comfortable",
				}}
				muiTableHeadCellProps={{
					align: "left",
					sx: {
						fontWeight: "bold",
						color: colors.grey[100],
						typography: "h5",
					},
				}}
				muiTableBodyCellProps={{
					align: "left",
					sx: {
						color: colors.grey[100],
						typography: "h6",
					},
				}}
				muiTableBodyProps={{
					sx: (theme) => ({
						"& tr:nth-of-type(odd)": {
							backgroundColor: darken(theme.palette.background.default, 0.05),
						},
					}),
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				//rowCount={rowCount}
				rowCount={
					manualTaskState && manualTaskState.length > 0
						? manualTaskState[0].totalDBRowCount
						: 0
				}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableSorting={false}
				enableDensityToggle={false}
				enableColumnOrdering={false}
				enableHiding={false}
				enableGlobalFilter={false}
				manualFiltering={true}
				manualPagination={true}
				manualSorting={false}
				//renderDetailPanel={RenderDetail}
				muiTableBodyRowProps={({ row }) => ({
					onDoubleClick: (event) => {
						setDetailsRow(row.original);
						setDetailsOpen(true);
					},
					sx: {
						cursor: "pointer",
					},
				})}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title="Refresh">
								<IconButton
									onClick={() => {
										setFetchRequired(true);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
			/>
			<Button
				sx={{ width: "7%", marginLeft: "20px", marginTop: "20px" }}
				variant="contained"
				color="secondary"
				disabled={manualTaskState.filter((el) => el.flag).length === 0}
				onClick={() => {
					setdialogIsOpen(true);
				}}
			>
				Save
			</Button>
		</Box>
	);
}

export default MassiveRetry;
