import { Typography, Box, Card, CardContent, Grid, Divider, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const Header = ({ statData }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Card elevation={5} style={{ borderRadius: "8px" }}>
            <CardContent sx={{ height: "200px" }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography
                        variant="h5"
                        gutterBottom
                        style={{ color: "#FFC300" }}
                    >
                        {statData.total.title}
                    </Typography>

                    <Typography variant="h3" color="primary" gutterBottom>
                        {statData.total.value}
                    </Typography>

                    <Divider
                        variant="middle"
                        style={{
                            width: "90%",
                            margin: "10px 0",
                        }}
                    />

                    <Box
                        width="100%"
                        mt={2}
                        p={1}
                        style={{
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
                            borderRadius: "8px",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={6}
                                container
                                justifyContent="center"
                                alignItems="center"
                                direction="column"
                                onClick={statData.left.onClick}
                                sx={{
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                      cursor: "pointer"
                                      }
                                  }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        color: "#757575",
                                    }}
                                >
                                    {statData.left.title}
                                </Typography>
                                <Typography variant="h4" color="primary">
                                    {statData.left.value}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                container
                                justifyContent="center"
                                alignItems="center"
                                direction="column"
                            >
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        color: "#757575",
                                    }}
                                >
                                    {statData.right.title}
                                </Typography>
                                <Typography variant="h4" color="primary">
                                    {statData.right.value}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Header;
