import { useCallback, useState, useEffect } from 'react';
import { Handle, useUpdateNodeInternals, Position } from 'reactflow';
import {
	AppBar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Tab,
	Tabs,
	TextField,
	Typography,
	Grid,
	Divider,
	Box,
	Paper,
	useTheme,
} from '@mui/material';
import OutputPortsPage from './OutputPortsPage';
import StepsPage from './StepsPage';
import SettingsPage from './SettingsPage';
import useHttpService from '../../customHooks/useHttpService.js';
import JsonSettingsPage from './JsonSettingsPage';

const initialNodeHeight = 200;

export default function TaskNode({ id, data }) {
	const httpService = useHttpService();
	const updateNodeInternals = useUpdateNodeInternals();
	const [nodeHeight, setNodeHeight] = useState(initialNodeHeight);
	const theme = useTheme();

	const activateJsons = data.manualId && data.python_file === 'manualStart.py';

	const readOnly = data.readOnly;

	const updateTask = (dataToSend) => {
		const apiPath = '/workflow/task_instances';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		// const onSuccess = (msg) => {
		// 	console.log('Tasks Duplicated Successfully!');
		// 	console.log(msg);
		// };

		// const onError = (msg) => {
		// 	console.log('Tasks Duplication Failed!');
		// 	console.log(msg);
		// };

		httpService.putAPI(
			apiUrl,
			dataToSend,
			() => {},
			data.setAutoRefresh,
		);
		data.saveLastUser();
	};

	const [outputPorts, setOutputPorts] = useState(
		data.sourceHandles ? data.sourceHandles : []
	);
	const [openOutputPorts, setOpenOutputPorts] = useState(false);
	const handleOpenOutputPorts = () => {
		setOpenOutputPorts(true);
	};
	const handleCloseOutputPorts = () => {
		setOpenOutputPorts(false);
	};

	const handleSaveOutputPorts = useCallback(
		(newOutputPorts) => {
			setOutputPorts(newOutputPorts);
			updateNodeInternals(id);
			setOpenOutputPorts(false);

			const transformedList = {};
			newOutputPorts.forEach((obj) => {
				transformedList[obj.name] = obj.condition;
			});
			updateTask({
				id: id,
				output_conditions: transformedList,
			});
		},
		[id, updateNodeInternals, updateTask]
	);

	const [steps, setSteps] = useState(data.steps ? data.steps : []);
	const [openSteps, setOpenSteps] = useState(false);
	const handleOpenSteps = () => {
		setOpenSteps(true);
	};
	const handleCloseSteps = () => {
		setOpenSteps(false);
	};

	const handleSaveSteps = (newSteps) => {
		setSteps(newSteps);
		setOpenSteps(false);
		updateTask({
			id: id,
			steps: newSteps,
		});
	};

	const [settings, setSettings] = useState({
		task_mame: data.task_mame,
		python_file: data.python_file,
		enabled: data.enabled,
		trigger_rule: data.trigger_rule,
		output_type: data.output_type,
	});
	const [openSettings, setOpenSettings] = useState(false);
	const handleOpenSettings = () => {
		setOpenSettings(true);
	};
	const handleCloseSettings = () => {
		setOpenSettings(false);
	};

	const handleSaveSettings = (newSettings) => {
		setSettings(newSettings);
		setOpenSettings(false);
		updateTask({
			id: id,
			enabled: newSettings.enabled,
			trigger_rule: newSettings.trigger_rule,
			output_type: newSettings.output_type,
		});
	};

	const [jsonData, setJsonData] = useState(JSON.stringify(data.json_data, null, 2));
	const [jsonSchema, setJsonSchema] = useState(JSON.stringify(data.json_schema, null, 2));
	const [openJsonSettings, setOpenJsonSettings] = useState(false);
	const handleOpenJsonSettings = () => {
		setOpenJsonSettings(true);
	};
	const handleCloseJsonSettings = () => {
		setOpenJsonSettings(false);
	};

	const handleSaveJsonSettings = (newJsonSettings) => {
		setJsonData(newJsonSettings.newJsonData);
		setJsonSchema(newJsonSettings.newJsonSchema);
		setOpenJsonSettings(false);
		updateTask({
			id: id,
			json_data: newJsonSettings.newJsonData,
			json_schema: newJsonSettings.newJsonSchema,
		});
	};

	useEffect(() => {
		const newHeight = Math.max(
			initialNodeHeight,
			(outputPorts.length + 1) * 20
		); // Adjust multiplier for spacing as needed
		setNodeHeight(newHeight);
	}, [outputPorts]);
	// console.log(outputPorts);
	// console.log(handleId);

	return (
		<>
			<div
				style={{
					height: nodeHeight,
					padding: '10px 20px',
					borderRadius: '5px',
					background: data.enabled ? '#003153' : '#8098a9',
					//color: '#40826D',
					border: '1px solid #40826D',
					width: 360,
					cursor: 'default'
				}}
			>
				{data.targetHandles.map((handle) => (
					<Handle
						key={handle.id}
						id={handle.id}
						type='target'
						position={Position.Left}
						style={{
							left: '-5px',
							width: '10px',
							height: '10px',
							borderRadius: '2px',
							background: '#778899',
						}}
					/>
				))}
				{outputPorts.map((handle, index) => (
					<Handle
						id={handle.id}
						key={handle.id}
						type='source'
						position={Position.Right}
						style={{
							top: `${(index + 1) * (nodeHeight / (outputPorts.length + 1))}px`,
							right: '-5px',
							width: '10px',
							height: '10px',
							borderRadius: '2px',
							background: '#778899',
						}}
					>
						<Typography
							style={{
								position: 'relative',
								width: '200px',
								transform: 'translate(11px, -60%)',
								fontSize: '10px',
								pointerEvents: 'none',
							}}
						>
							{handle.name}
						</Typography>
					</Handle>
				))}
				<Paper
					elevation={3}
					sx={{
						padding: 1,
						marginBottom: 2,
						backgroundColor: theme.palette.mode === 'dark' ? '#003153' : '#335a75',
						cursor: 'grab',
					}}
					className="custom-drag-handle"
				>
					<Typography
						style={{
							fontSize: '18px',
							pointerEvents: 'none',
							textAlign: 'center',
						}}
					>
						{data.label}
					</Typography>
				</Paper>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={1}>
						{/* Left side */}
						<Grid item xs={8}>
							<Paper
								elevation={3}
								sx={{
									padding: 1,
									backgroundColor: theme.palette.mode === 'dark' ? '#003153' : '#335a75',
								}}
							>
								<Typography
									style={{
										pointerEvents: 'none',
										marginBottom: '5px',
									}}
								>
									{settings.trigger_rule}
								</Typography>
								<Typography
									style={{
										pointerEvents: 'none',
										marginBottom: '5px',
									}}
								>
									{settings.output_type}
								</Typography>
							</Paper>
							{activateJsons && (
								<Paper
									elevation={3}
									sx={{
										padding: 1,
										backgroundColor: theme.palette.mode === 'dark' ? '#003153' : '#335a75',
										marginTop: 0.5,
									}}
								>
									<div>
										<Button onClick={handleOpenJsonSettings} fullWidth={true}>
											JSON Settings
										</Button>
									</div>
								</Paper>
							)}
						</Grid>

						{/* Right side */}
						<Grid item xs={4}>
							<Paper
								elevation={3}
								sx={{
									padding: 1,
									backgroundColor: theme.palette.mode === 'dark' ? '#003153' : '#335a75',
								}}
							>
								<div style={{ marginBottom: '1px' }}>
									<Button
										onClick={handleOpenSettings}
										fullWidth={true}
										style={{ justifyContent: 'flex-start' }}
									>
										Settings
									</Button>
								</div>
								<div style={{ marginBottom: '1px' }}>
									<Button
										onClick={handleOpenOutputPorts}
										fullWidth={true}
										style={{ justifyContent: 'flex-start' }}
									>
										Outputs
									</Button>
								</div>
								<div style={{ marginBottom: '1px' }}>
									<Button
										onClick={handleOpenSteps}
										fullWidth={true}
										style={{ justifyContent: 'flex-start' }}
									>
										Steps
									</Button>
								</div>
							</Paper>
						</Grid>
					</Grid>
				</Box>
				{openSettings && (
					<SettingsPage
						open={openSettings}
						initialSettings={settings}
						onClose={handleCloseSettings}
						onSave={handleSaveSettings}
						readOnly={readOnly}
					></SettingsPage>
				)}
				{openSteps && (
					<StepsPage
						open={openSteps}
						initialSteps={steps}
						onClose={handleCloseSteps}
						onSave={handleSaveSteps}
						readOnly={readOnly}
						pythonFile={data.python_file}
					></StepsPage>
				)}
				{openOutputPorts && (
					<OutputPortsPage
						id={id}
						open={openOutputPorts}
						initialOutputPorts={outputPorts}
						onClose={handleCloseOutputPorts}
						onSave={handleSaveOutputPorts}
						readOnly={readOnly}
					></OutputPortsPage>
				)}
				{openJsonSettings && (
					<JsonSettingsPage
						open={openJsonSettings}
						initialJsonData={jsonData}
						initialJsonSchema={jsonSchema}
						onClose={handleCloseJsonSettings}
						onSave={handleSaveJsonSettings}
						readOnly={readOnly}
					></JsonSettingsPage>
				)}
			</div>
		</>
	);
}
