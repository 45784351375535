import React, { useState, useRef } from 'react';
import {
	FormControl,
	InputLabel,
	Grid,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
    Typography,
} from '@mui/material';

export default function FileBox(props) {

    const [myData, setData] = useState(props.text);

	const [open, setOpen] = useState(false);
	const textRef = useRef(null);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCopyToClipboard = () => {
		if (textRef.current) {
			textRef.current.select();
			navigator.clipboard.writeText(textRef.current.value);
		}
	};

	const handleDownload = () => {
		const blob = new Blob([myData], { type: 'text/plain' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'data.txt';
		a.click();
		URL.revokeObjectURL(url);
	};

    const [uploadedFileName, setUploadedFileName] = useState(null);

	// const data = new Blob([myData], { type: 'text/plain' });
	// const downloadLink = window.URL.createObjectURL(data);

	const showFile = async (e) => {
		e.preventDefault();
		const reader = new FileReader();
		reader.onload = async (e) => {
			const text = e.target.result;
			setData(text);
			const myObj = { target: { name: props.name, value: text } };
			if (props.onTextChange) props.onTextChange(myObj);
		};
		reader.readAsText(e.target.files[0]);
        setUploadedFileName(e.target.files[0].name);
	};

	return (
		<FormControl fullWidth>
			<Grid container spacing={5} padding={5}>
				<Grid item md={12}>
					<InputLabel id='demo-simple-select-label'>{props.label}</InputLabel>
				</Grid>
				<Grid item md={6}>
					{/* <a download="data.txt" href={downloadLink}>
                    Download File
                </a> */}
					<div>
						<Button variant='contained' onClick={handleOpen}>
							View Content
						</Button>

						<Dialog open={open} onClose={handleClose} maxWidth='xl'>
							<DialogContent>
								<textarea
									ref={textRef}
									value={myData}
									readOnly
									style={{ width: '50vw', height: '300px', color: '#000000' }}
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose}>Close</Button>
								<Button onClick={handleCopyToClipboard}>
									Copy to Clipboard
								</Button>
								<Button onClick={handleDownload}>Download</Button>
							</DialogActions>
						</Dialog>
					</div>
				</Grid>
				<Grid item md={6}>
					{/* <input type='file' onChange={(e) => showFile(e)} /> */}
					{!props.disabled && (
						<div>
							<Button
								variant='contained'
								component='label'
								disabled={props.disabled}
							>
								Choose File
								<input type='file' hidden onChange={(e) => showFile(e)} />
							</Button>
							<Typography>
								{uploadedFileName ? uploadedFileName : 'No file chosen'}
							</Typography>
						</div>
					)}
				</Grid>
			</Grid>
		</FormControl>
	);
}
