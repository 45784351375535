import React, { useEffect } from "react";
import axios from "axios";
import UserService from "../../services/UserService";

const _axios = axios.create();

function useHttpService() {
	const configure = () => {
		_axios.interceptors.request.use((config) => {
			if (UserService.isLoggedIn()) {
				const cb = () => {
					if (UserService.getUsername() === "efesto")
						config.headers.Authorization = `Bearer ${localStorage.getItem(
							"token"
						)}`;
					else
						config.headers.Authorization = `Bearer ${UserService.getToken()}`;
					return Promise.resolve(config);
				};
				return UserService.updateToken(cb);
			}
		});
	};

	const getAxiosClient = () => _axios;

	const getAPI = (url, callback = () => {}) => {
		if (url == null) return;
		_axios
			.get(url)
			.then((res) => {
				url.includes("export") && res.data && callback(res.data);
				if (res.data.data) callback(res.data.data);
				else callback([]);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const downloadAPI = (url, callback = () => {}, method = "get", body = {}) => {
		if (url == null) return;
		if (method === "get") {
			_axios
				.get(url, { responseType: "blob" })
				.then((res) => {
					if (res.data) callback(res.data);
					else callback(null);
				})
				.catch(function (error) {});
		}
		if (method === "post") {
			_axios
				.post(url, body, { responseType: "blob" })
				.then((res) => {
					if (res.data) callback(res.data);
					else callback(null);
				})
				.catch(function (error) {});
		}
	};

	const postAPI = (
		url,
		data,
		callback = () => {},
		header = {},
		showSnackbar = true,
		errorCallback = () => {}
	) => {
		if (url == null) return;
		_axios
			.post(url, data)
			.then((res) => {
				console.log(res);
				if (res?.data?.data) callback(res?.data?.data);
				else callback();
			})
			.catch(function (error) {});
	};

	const putAPI = (url, data, callback = () => {}, errorCallback = () => {}) => {
		if (url == null) return;
		_axios
			.put(url, data)
			.then((res) => {
				if (res.data) callback(res.data);
			})
			.catch((error) => {
				if (error.response) {
					errorCallback(error.response);
				} else {
					errorCallback({
						message: "An error occurred while saving the data.",
					});
				}
			});
	};

	const deleteAPI = (url, data, callback) => {
		if (url == null) return;
		_axios
			.delete(url, {
				data,
			})
			.then((res) => {
				if (res?.data?.data) callback(res?.data?.data);
				else callback();
			})
			.catch(function (error) {});
	};

	useEffect(() => {
		configure();
	}, []);

	return {
		getAPI,
		postAPI,
		deleteAPI,
		putAPI,
		downloadAPI,
		getAxiosClient,
		configure,
	};
}

export default useHttpService;
