import {
	Button,
	Box,
	darken,
	IconButton,
	Typography,
	useTheme,
	Checkbox,
	Tooltip,
	FormControlLabel,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { tokens } from "../../theme";
import { Refresh } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { convertToDate } from "../../utils";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import useHttpService from "../Common/useHttpService";

function OperativeState() {
	const [operativeData, setoperativeData] = useState([]);

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);

	const [locationFilterApplied, setLocationFilterApplied] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const [dialogIsOpen, setdialogIsOpen] = useState(false);
	const [isErrorUpdate, setIsErrorUpdate] = useState(false);
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [detailsRow, setDetailsRow] = useState({});
	const handleCloseDetails = () => {
		setDetailsOpen(false);
	};

	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const tableInstanceRef = useRef(null);
	const location = useLocation();

	const apiPathOperativeState = "/workflow/operative_state";
	const apiUrlOperativeState = new URL(
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPathOperativeState
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPathOperativeState,
		process.env.NODE_ENV === "production"
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const columns = useMemo(() => [
		{
			accessorKey: "api",
			header: "API",
			size: 100,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
		{
			accessorKey: "retries",
			header: "Retries",
			size: 200,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
		{
			accessorKey: "http_response_code",
			header: "HTTP Response Code",
			size: 200,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
		{
			accessorKey: "operative_state",
			header: "Operative State",
			size: 200,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
			Cell: ({ row }) => {
				return (
					<Box
						key={row.original.id}
						sx={{
							width: "20px",
							height: "20px",
							borderRadius: "45px",
							...(row.original.operative_state === "ERROR"
								? { backgroundColor: "red" }
								: row.original.operative_state === "WORKING"
								? { backgroundColor: "#00ff00" }
								: { backgroundColor: "orange" }),
						}}
					/>
				);
			},
		},
		{
			accessorKey: "last_call",
			header: "Last Call",
			accessorFn: (row) =>
				row["last_call"] ? convertToDate(row["last_call"]) : "",
			size: 200,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
	]);

	useEffect(() => {
		fetchOperativeState();
	}, []);

	useEffect(() => {
		if (location.state && location.state.runId && !locationFilterApplied) {
			setColumnFilters((prev) => [
				...prev,
				{ id: "id", value: location.state.runId },
			]);
		}
		setLocationFilterApplied(true);
	}, [location]);

	useEffect(() => {
		if (locationFilterApplied) {
			fetchOperativeState();
		}
		if (fetchRequired) {
			setFetchRequired(false);
		}
	}, [fetchRequired, columnFilters, pagination.pageIndex, pagination.pageSize]);

	const fetchOperativeState = () => {
		setIsLoading(true);
		apiUrlOperativeState.searchParams.set(
			"start",
			`${pagination.pageIndex * pagination.pageSize}`
		);
		apiUrlOperativeState.searchParams.set("size", `${pagination.pageSize}`);

		columnFilters.forEach((columnFilter) => {
			if (columnFilter.id === "api") {
				apiUrlOperativeState.searchParams.set("api", columnFilter.value);
			} else if (columnFilter.id === "retries") {
				apiUrlOperativeState.searchParams.set("retries", columnFilter.value);
			} else if (columnFilter.id === "http_response_code") {
				apiUrlOperativeState.searchParams.set(
					"http_response_code",
					columnFilter.value
				);
			} else if (columnFilter.id === "operative_state") {
				apiUrlOperativeState.searchParams.set(
					"operative_state",
					columnFilter.value
				);
			} else if (columnFilter.id === "last_call") {
				apiUrlOperativeState.searchParams.set("last_call", columnFilter.value);
			}
		});

		httpService.getAPI(apiUrlOperativeState.href, (data) => {
			setoperativeData(data || []);
			setRowCount(data[0]?.totalDBRowCount || 0);
			setIsLoading(false);
			setIsRefetching(false);
			setIsError(false);
		});
	};

	const getAlert = () => {
		if (isError) {
			return {
				color: "error",
				children: "Error loading data",
			};
		}

		return undefined;
	};

	return (
		<Box>
			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableColumnFilters={false}
				enableColumnActions={false}
				enableStickyHeader
				muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
				columns={columns}
				data={operativeData}
				//getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: false,
					density: "comfortable",
				}}
				muiTableHeadCellProps={{
					align: "left",
					sx: {
						fontWeight: "bold",
						color: colors.grey[100],
						typography: "h5",
					},
				}}
				muiTableBodyCellProps={{
					align: "left",
					sx: {
						color: colors.grey[100],
						typography: "h6",
					},
				}}
				muiTableBodyProps={{
					sx: (theme) => ({
						"& tr:nth-of-type(odd)": {
							backgroundColor: darken(theme.palette.background.default, 0.05),
						},
					}),
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				//rowCount={rowCount}
				rowCount={
					operativeData && operativeData.length > 0
						? operativeData[0].totalDBRowCount
						: 0
				}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableSorting={false}
				enableDensityToggle={false}
				enableColumnOrdering={false}
				enableHiding={false}
				enableGlobalFilter={false}
				manualFiltering={true}
				manualPagination={true}
				manualSorting={false}
				//renderDetailPanel={RenderDetail}
				muiTableBodyRowProps={({ row }) => ({
					onDoubleClick: (event) => {
						setDetailsRow(row.original);
						setDetailsOpen(true);
					},
					sx: {
						cursor: "pointer",
					},
				})}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title="Refresh">
								<IconButton
									onClick={() => {
										setFetchRequired(true);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
			/>
		</Box>
	);
}

export default OperativeState;
