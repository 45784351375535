import {
    useTheme,
    Box,
    IconButton,
    Tooltip,
    darken,
} from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import Header from "../../components/Header/Header.jsx";
import useHttpService from "../../customHooks/useHttpService.js";
import { Refresh } from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";
import { tokens } from "../../theme.js";
import { convertToDate } from '../../utils';
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

const Logs = () => {
    const httpService = useHttpService();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const tableInstanceRef = useRef(null);

    // main table -> Logs
    const [logsData, setLogsData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [fetchRequired, setFetchRequired] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const fetchLogsData = () => {
        const apiPath = "/workflow/log";
        const apiUrl = new URL(
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
                : process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
            process.env.NODE_ENV === "production"
                ? window.location.origin
                : process.env.REACT_APP_DEV_API_BASE_URL
        );

        apiUrl.searchParams.set(
            "start",
            `${pagination.pageIndex * pagination.pageSize}`
        );
        apiUrl.searchParams.set("size", `${pagination.pageSize}`);

        columnFilters.forEach((columnFilter) => {
            if (columnFilter.id === "log") {
                apiUrl.searchParams.set("log", columnFilter.value);
            } else if (columnFilter.id === "ts") {
                apiUrl.searchParams.set("ts", columnFilter.value);
            }
        });

        httpService.getAPI(
            apiUrl,
            logsData,
            setLogsData,
            setRowCount,
            setIsLoading,
            setIsRefetching,
            setIsError
        );
    };

    useEffect(() => {
        fetchLogsData();
        // const refreshInterval = setInterval(() => {
        //     fetchLogsData(); // Fetch data at regular intervals (e.g., every 5 minutes)
        // }, 10000); // 5 minutes in milliseconds
    
        // return () => {
        //     clearInterval(refreshInterval); // Cleanup when the component unmounts
        // };

    }, [
        fetchRequired,
        columnFilters,
        //globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        //sorting,
    ]);

    const getAlert = () => {
        if (isError) {
            return {
                color: "error",
                children: "Error loading data",
            };
        }

        return undefined;
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "log",
                header: "Log",
                size: 551,
                isInput: false,
                enableEditing: false,
            },
            
            {
                id: 'ts',
				accessorFn: (row) =>
					row['ts']
						? convertToDate(row['ts'])
						: '',
                header: "Timestamp",
                maxSize: 120,
                enableEditing: false,
                isInput: false,
                muiTableBodyCellProps: {
                    align: "left",
                },
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: 'Filter: From indicated datetime',
									sx: { minWidth: '120px' },
									variant: 'standard',
								},
								field: { clearable: true },
							}}
							ampm={false}
							format='dd/MM/yyyy HH:mm:ss'
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
            },
        ],
        []
    );

    return (
        <Box m="20px">
            <Header title="Logs" subtitle={"List of Logs"} />
            <MaterialReactTable
                tableInstanceRef={tableInstanceRef}
                enableStickyHeader
                positionActionsColumn="last"
                muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
                displayColumnDefOptions={{
                    "mrt-row-actions": {
                        muiTableHeadCellProps: {
                            align: "left",
                            sx: {
                                fontWeight: "bold",
                                color: colors.grey[100],
                                typography: "h5",
                            },
                        },
                        muiTableBodyCellProps: {
                            align: "left",
                        },
                        size: 40,
                    },
                }}
                columns={columns}
                data={logsData}
                //getRowId={(row) => row.id}
                initialState={{
                    showColumnFilters: true,
                    showGlobalFilter: false,
                    density: "comfortable",
                }}
                // muiTopToolbarProps={{
                // 	sx: {
                // 		background:
                // 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
                // 	},
                // }}
                // muiBottomToolbarProps={{
                // 	sx: {
                // 		background:
                // 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
                // 	},
                // }}
                muiTableHeadCellProps={{
                    align: "left",
                    sx: {
                        fontWeight: "bold",
                        color: colors.grey[100],
                        typography: "h5",
                    },
                }}
                muiTableBodyCellProps={{
                    align: "left",
                    sx: {
                        color: colors.grey[100],
                        typography: "h6",
                    },
                }}
                muiTableBodyProps={{
                    sx: (theme) => ({
                        "& tr:nth-of-type(odd)": {
                            backgroundColor: darken(
                                theme.palette.background.default,
                                0.05
                            ),
                        },
                    }),
                }}
                muiToolbarAlertBannerProps={getAlert()}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                //rowCount={rowCount}
                rowCount={
                    logsData && logsData.length > 0
                        ? logsData[0].totalDBRowCount
                        : 0
                }
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                }}
                enableSorting={false}
                enableDensityToggle={false}
                enableColumnOrdering={false}
                enableHiding={false}
                enableRowActions={false}
                enableGlobalFilter={false}
                manualFiltering={true}
                manualPagination={true}
                manualSorting={false}
                //renderDetailPanel={RenderDetail}
                renderBottomToolbarCustomActions={() => {
                    return (
                        <div>
                            <Tooltip arrow title="Refresh">
                                <IconButton
                                    onClick={() => {
                                        setFetchRequired(prev => !prev);
                                    }}
                                >
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                }}
            />
        </Box>
    );
};

export default Logs;