import React, { useState } from 'react';
import { Button, Menu, MenuItem, TextField, useTheme } from '@mui/material';
import { tokens } from '../../theme';

const JumpToDropDown = ({ items, onSelect }) => {

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [anchorEl, setAnchorEl] = useState(null);
	const [searchText, setSearchText] = useState('');

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (itemId) => {
		const foundItem = items.find((obj) => obj.id === itemId);
		onSelect(foundItem);
		handleClose();
	};

	const filteredItems = items.filter((item) =>
		item.data.label.toLowerCase().includes(searchText.toLowerCase())
	);

	return (
		<div>
			<Button
				color='inherit'
				style={{ color: colors.grey[100], marginLeft: '8px' }}
				onClick={handleClick}
			>
				Jump To
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{
					'& .MuiMenu-paper': {
						marginTop: '8px',
						background: colors.primary[400],
					},
				}}
			>
				<TextField
					label='Search'
					variant='outlined'
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					onClick={(e) => e.stopPropagation()}
					onKeyDown={(e) => e.stopPropagation()}
					fullWidth
					sx={{ marginBottom: '8px' }}
				/>
				{filteredItems.map((item) => (
					<MenuItem
						key={item.id}
						onClick={() => handleSelect(item.id)}
						sx={{
							'&:hover': {
								backgroundColor: colors.primary[500],
							},
						}}
					>
						{item.data.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default JumpToDropDown;
