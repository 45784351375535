import {
	useTheme,
	Box,
	IconButton,
	Tooltip,
	darken,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
} from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import useHttpService from "../../customHooks/useHttpService.js";
import {
	CollectionsBookmarkRounded,
	Description,
	Refresh,
	Delete,
} from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";
import { tokens } from "../../theme";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { convertToDate } from "../../utils";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddDagPage from "./AddDagPage.jsx";
import { useUserContext } from "../../contexts/userContext.js";

const DagTemplates = () => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const userContext = useUserContext();
	const user = userContext.getUser();

	if (user && !user.hasRole("ngom-businessuser")) window.location.href = "/";

	const tableInstanceRef = useRef(null);

	const navigate = useNavigate();
	const toDagDesigner = (dagName) => {
		navigate("/dag_templates/" + dagName);
	};

	// main table -> Events
	const [dagTemplates, setDagTemplates] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 100,
	});
	const [isErrorDelete, setIsErrorDelete] = useState(false);

	const [newDagOpen, setNewDagOpen] = useState(false);
	const [newOrderOpen, setNewOrderOpen] = useState(false);

	const fetchDagTemplates = () => {
		const apiPath = "/workflow/dag_template";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.getAPI(
			apiUrl,
			dagTemplates,
			setDagTemplates,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	const handleDeleteRow = (row) => {
		// if (
		// 	!window.confirm(
		// 		`Are you sure you want to delete ${row.getValue('userLabel')}`
		// 	)
		// ) {
		// 	return;
		// }
		const apiPath = "/workflow/dag_template";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.deleteAPI(
			apiUrl,
			{ ids: [row.original.id] },
			setIsErrorDelete,
			() => setFetchRequired(true)
		);
	};

	const onSubmitNewDag = (newDag) => {
		const createDagTemplate = (data) => {
			const apiPath = "/workflow/dag_template";
			const apiUrl = new URL(
				process.env.NODE_ENV === "production"
					? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
					: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
				process.env.NODE_ENV === "production"
					? window.location.origin
					: process.env.REACT_APP_DEV_API_BASE_URL
			);

			const onSuccess = (msg) => {
				// console.log("New DAG Template Created Successfully!");
				// console.log(msg);
			};

			const onError = (msg) => {
				// console.log("New DAG Template Creation Failed!");
				// console.log(msg);
			};

			data["last_user"] = user.name;

			httpService.postAPI(apiUrl, data, onSuccess, onError);
		};

		const duplicateTasks = (dag_name, new_dag_name) => {
			const apiPath = "/workflow/dag_template_duplicates";
			const apiUrl = new URL(
				process.env.NODE_ENV === "production"
					? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
					: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
				process.env.NODE_ENV === "production"
					? window.location.origin
					: process.env.REACT_APP_DEV_API_BASE_URL
			);

			const onSuccess = (msg) => {
				// console.log("Tasks Duplicated Successfully!");
				// console.log(msg);
			};

			const onError = (msg) => {
				// console.log("Tasks Duplication Failed!");
				// console.log(msg);
			};

			const data = {
				dag_name_in: dag_name,
				new_dag_name: new_dag_name,
			};

			httpService.postAPI(apiUrl, data, onSuccess, onError);
		};

		if (newDag.cloneAnotherDag) {
			const data = {
				dag_name: newDag.dag_name,
				description: newDag.description,
				order_type_id: newDag.order_type_id,
				designer_json: newDag.selectedDagToClone.designer_json,
			};

			createDagTemplate(data);

			duplicateTasks(newDag.selectedDagToClone.dag_name, newDag.dag_name);
		} else {
			const data = {
				dag_name: newDag.dag_name,
				description: newDag.description,
				order_type_id: newDag.order_type_id,
			};

			createDagTemplate(data);
		}

		// console.log(newDag);
		setNewDagOpen(false);
	};

	const onCloseNewDag = () => {
		setNewDagOpen(false);
	};

	const onSubmitNewOrderSuccess = (msg) => {
		// console.log("New Order Created Successfully!");
		// console.log(msg);
	};

	const onSubmitNewOrderError = (msg) => {
		// console.log("New Order Creation Failed!");
		// console.log(msg);
	};

	const onSubmitNewOrder = (newOrder) => {
		const apiPath = "/workflow/order_type";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		const data = newOrder;

		httpService.postAPI(
			apiUrl,
			data,
			onSubmitNewOrderSuccess,
			onSubmitNewOrderError
		);
		//console.log(newOrder);
		setNewOrderOpen(false);
	};

	const onCloseNewOrder = () => {
		setNewOrderOpen(false);
	};

	const getAlert = () => {
		if (isError) {
			return {
				color: "error",
				children: "Error loading data",
			};
		}

		return undefined;
	};

	const minifyID = (ID) => {
		return ID.split("-")[0] + " " + "\u00B7".repeat(3);
	};

	useEffect(() => {
		fetchDagTemplates();
		if (fetchRequired) {
			setFetchRequired(false);
		}
	}, [fetchRequired]);

	const columns = useMemo(
		() => [
			{
				accessorKey: "order_type_name",
				header: "Order",
				size: 50,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: "dag_name",
				header: "Name",
				size: 50,
				isInput: false,
				enableEditing: false,
				enableGrouping: false,
			},
			{
				accessorKey: "description",
				header: "Description",
				size: 50,
				isInput: false,
				enableEditing: false,
				enableGrouping: false,
			},
			{
				accessorKey: "last_user",
				header: "Last User",
				size: 80,
				isInput: false,
				enableEditing: false,
				enableGrouping: false,
				enableClickToCopy: true,
			},
			{
				id: "last_user_ts",
				accessorFn: (row) =>
					row["last_user_ts"] ? convertToDate(row["last_user_ts"]) : "",
				header: "Last Modified",
				maxSize: 100,
				enableEditing: false,
				enableGrouping: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: "Filter: From indicated datetime",
									sx: { minWidth: "120px" },
									variant: "standard",
								},
								field: { clearable: true },
							}}
							ampm={false}
							format="dd/MM/yyyy HH:mm:ss"
							allowSameDateSelection={true}
						/>
					</LocalizationProvider>
				),
				filterFn: (row, id, filterValue) => {
					if (!filterValue) return true;
					const date1 = new Date(filterValue);
					const date2 = new Date(row.original.last_user_ts);
					return date2 > date1;
				},
			},
			{
				id: "run_count",
				header: "Running",
				accessorFn: (originalRow) =>
					originalRow.run_count ? originalRow.run_count : "0",
				size: 20,
				isInput: false,
				enableEditing: false,
				enableGrouping: false,
				aggregationFn: "sum",
				AggregatedCell: ({ cell, table }) => (
					<>
						Sum:{" "}
						<Box
							sx={{ color: "info.main", display: "inline", fontWeight: "bold" }}
						>
							{cell.getValue()}
						</Box>
					</>
				),
			},
			{
				id: "done_count",
				header: "Done",
				accessorFn: (originalRow) =>
					originalRow.done_count ? originalRow.done_count : "0",
				size: 20,
				isInput: false,
				enableEditing: false,
				enableGrouping: false,
				aggregationFn: "sum",
				AggregatedCell: ({ cell, table }) => (
					<>
						Sum:{" "}
						<Box
							sx={{ color: "info.main", display: "inline", fontWeight: "bold" }}
						>
							{cell.getValue()}
						</Box>
					</>
				),
			},
		],
		[]
	);

	return user && !user.hasRole("ngom-businessuser") ? (
		<Box></Box>
	) : (
		<Box m="20px">
			<Header title="DAG Templates" subtitle={"List of DAG Templates"} />
			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
				displayColumnDefOptions={{
					"mrt-row-actions": {
						muiTableHeadCellProps: {
							align: "left",
							sx: {
								fontWeight: "bold",
								color: colors.grey[100],
								typography: "h5",
							},
						},
						muiTableBodyCellProps: {
							align: "left",
						},
						size: 40,
					},
				}}
				positionActionsColumn="last"
				columns={columns}
				data={dagTemplates}
				//getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: false,
					density: "comfortable",
					grouping: ["order_type_name"],
					expanded: true,
				}}
				// muiTopToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				// muiBottomToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				muiTableHeadCellProps={{
					align: "left",
					sx: {
						fontWeight: "bold",
						color: colors.grey[100],
						typography: "h5",
					},
				}}
				muiTableBodyCellProps={{
					align: "left",
					sx: {
						color: colors.grey[100],
						typography: "h6",
					},
				}}
				muiTableBodyProps={{
					sx: (theme) => ({
						"& tr:nth-of-type(odd)": {
							backgroundColor: darken(theme.palette.background.default, 0.05),
						},
					}),
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				//rowCount={rowCount}
				// rowCount={
				// 	dagTemplates && dagTemplates.length > 1
				// 		? dagTemplates[0].totalDBRowCount
				// 		: 0
				// }
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableSorting={false}
				enableDensityToggle={false}
				enableColumnOrdering={false}
				enableHiding={false}
				enableRowActions={true}
				enableGlobalFilter={false}
				enableGrouping={true}
				manualFiltering={false}
				manualPagination={false}
				manualSorting={false}
				renderRowActionMenuItems={({ closeMenu, row, table }) => [
					<MenuItem
						key="Designer"
						onClick={() => {
							toDagDesigner(row.original["dag_name"]);
							closeMenu();
						}}
					>
						<ListItemIcon>
							<AccountTreeIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: "h6" }}
							primary="Designer"
						/>
					</MenuItem>,
					!row.original.done_count && (
						<MenuItem
							key="delete"
							onClick={() => {
								closeMenu();
								handleDeleteRow(row);
							}}
						>
							<ListItemIcon>
								<Delete color="error" />
							</ListItemIcon>
							<ListItemText
								primaryTypographyProps={{ variant: "h6" }}
								primary="Delete"
							/>
						</MenuItem>
					),
				]}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title="Refresh">
								<IconButton
									onClick={() => {
										setFetchRequired(true);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
				renderTopToolbarCustomActions={() => (
					<Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
						<Button
							color="secondary"
							onClick={() => setNewDagOpen(true)}
							variant="contained"
						>
							<Typography variant="h6" sx={{ color: colors.grey[100] }}>
								Create New Dag Template
							</Typography>
						</Button>
						<Button
							color="secondary"
							onClick={() => setNewOrderOpen(true)}
							variant="contained"
						>
							<Typography variant="h6" sx={{ color: colors.grey[100] }}>
								Create New Order
							</Typography>
						</Button>
					</Box>
				)}
			/>
			{newOrderOpen && (
				<NewOrderDialog
					open={newOrderOpen}
					onClose={onCloseNewOrder}
					onSave={onSubmitNewOrder}
				/>
			)}
			{newDagOpen && dagTemplates && (
				<AddDagPage
					open={newDagOpen}
					onClose={onCloseNewDag}
					onAdd={onSubmitNewDag}
					dagTemplates={dagTemplates}
				/>
			)}
		</Box>
	);
};

const NewOrderDialog = ({ open, onClose, onSave }) => {
	const [inputName, setInputName] = useState("");

	const handleSave = () => {
		if (inputName.trim() !== "") {
			onSave({ name: inputName });
			onClose();
		}
	};

	const handleCancel = () => {
		onClose();
	};

	const handleNameChange = (event) => {
		setInputName(event.target.value);
	};

	return (
		<Dialog open={open} onClose={handleCancel}>
			<DialogTitle>Create New Order</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					label="Order Name"
					fullWidth
					required
					value={inputName}
					onChange={handleNameChange}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleSave}
					color="primary"
					disabled={inputName.trim() === ""}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DagTemplates;
