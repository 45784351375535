import {
	Box,
	useTheme,
	darken,
	MenuItem,
	ListItemText,
	Tooltip,
	IconButton,
	ListItemIcon,
	Grid,
	TextField,
	Button,
	Divider,
	Typography,
	Snackbar,
	Alert,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React, { useState, useEffect, useMemo, useRef } from "react";
import Header from "../../components/Header/Header";
import useHttpService from "../../customHooks/useHttpService.js";
import { tokens } from "../../theme";
import { MaterialReactTable } from "material-react-table";
import { Details, Https, Refresh } from "@mui/icons-material";
import DetailsIcon from "@mui/icons-material/Details";
import RightDrawer from "../../components/RightDrawer/RightDrawer";
import DetailsBox from "../../components/DetailsBox/DetailsBox";
import JsonSchemaBox from "../../components/Common/JsonSchemaBox";
import { useLocation } from "react-router-dom";
import { convertToDate } from "../../utils";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useUserContext } from "../../contexts/userContext.js";
import { default as useCommonHttpService } from "../../components/Common/useHttpService.js";

const Wizard = () => {
	const httpService = useHttpService();
	const commonHttpService = useCommonHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const tableInstanceRef = useRef(null);

	const location = useLocation();
	const userContext = useUserContext();
	const user = userContext.getUser();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	// dags
	const [manualTaskState, setManualTaskState] = useState([]);

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);

	const [snack, setsnack] = useState({
		open: false,
		message: "",
		status: "success",
	});

	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const apiPath = "/workflow/manual_tasks";
	const apiUrl = new URL(
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
		process.env.NODE_ENV === "production"
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const columns = useMemo(
		() => [
			{
				accessorKey: "name",
				header: "Name",
				size: 100,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
			},
			{
				accessorKey: "description",
				header: "Description",
				size: 200,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
			},
			{
				accessorKey: "retries",
				header: "Retries",
				size: 20,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: "customerId",
				header: "Customer ID",
				size: 20,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: "ServiceOrderId",
				header: "Order ID",
				size: 20,
				isInput: false,
				enableEditing: false,
			},
			{
				id: "start_ts",
				accessorFn: (row) =>
					row["start_ts"] ? convertToDate(row["start_ts"]) : "",
				header: "Start",
				maxSize: 200,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: "Filter: From indicated datetime",
									sx: { minWidth: "120px" },
									variant: "standard",
								},
								field: { clearable: true },
							}}
							ampm={false}
							format="dd/MM/yyyy HH:mm:ss"
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
			},
			{
				accessorKey: "run_id",
				header: "Run ID",
				size: 20,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
				Cell: ({ row }) => (
					<Tooltip title={row.original.run_id} arrow>
						<Typography variant="h6" color="primary">
							{minifyRunID(row.original.run_id)}
						</Typography>
					</Tooltip>
				),
			},
			//TODO: uncomment the following to enable user check on double click
			{
				accessorKey: "last_user",
				header: "Last User",
				size: 200,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
			},
			{
				id: "last_user_ts",
				accessorFn: (row) =>
					row["last_user_ts"] ? convertToDate(row["last_user_ts"]) : "",
				header: "Last Visited",
				maxSize: 200,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: "left",
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: "Filter: From indicated datetime",
									sx: { minWidth: "120px" },
									variant: "standard",
								},
								field: { clearable: true },
							}}
							ampm={false}
							format="dd/MM/yyyy HH:mm:ss"
							allowSameDateSelection={true}
						/>
					</LocalizationProvider>
				),
			},
		],
		[]
	);

	const getManualTask = (callback, callbackParams) => {
		commonHttpService.getAPI(apiUrl.href, (res) => {
			res = res ?? [];
			setManualTaskState(res);
			callback && callback(res, callbackParams);
		});
	};

	const putManualTask = (
		data,
		showSnack,
		successCallback,
		errorCallback = (errorResponse) => {
			if (errorResponse.status === 400) {
				setErrorMessage(
					"Warning! The task has been already managed (another user changed it). Please, press 'CLOSE' to exit."
				);
				setErrorDialogOpen(true);
				getManualTask();
			} else {
				setErrorMessage(
					`Warning! The task has been already managed (another user changed it). Please, press "CLOSE" to exit.`
				);
				setErrorDialogOpen(true);
			}
		}
	) => {
		commonHttpService.putAPI(
			apiUrl.href,
			data,
			(res) => {
				if (showSnack) {
					setsnack({
						open: true,
						status: "success",
						message: "Your data have been correctly saved!",
					});
				}
				successCallback && successCallback(res);
			},
			errorCallback
		);
	};
	const handleDoubleclick = (row) => {
		setIsLoading(true);
		const taskUrl = new URL(apiUrl.href);
		taskUrl.searchParams.set("id", row.original.id);

		commonHttpService.getAPI(taskUrl.href, (res) => {
			setIsLoading(false);
			const currentRowData = res[0];

			if (!currentRowData) {
				console.error("No data found for the task.");
				return;
			}

			setdetailsWindowData(currentRowData);

			if (isAnotherUserModifying(user, currentRowData.last_user)) {
				handleOpenDoubleclickDialog(currentRowData);
			} else {
				if (!currentRowData.last_user) {
					const updatedRow = {
						...currentRowData,
						last_user: user.name,
						last_user_ts: new Date().toISOString(),
					};
					putManualTask(updatedRow, false, () => {
						openDetailsWindow(updatedRow);
					});
				} else {
					openDetailsWindow(currentRowData);
				}
			}
		});
	};

	const onDoubleClick = (fetchedData, row) => {
		const data = fetchedData.find((el) => el.id === row.original.id);
		if (isAnotherUserModifying(user, data.last_user)) {
			setdetailsWindowData(data);
			handleOpenDoubleclickDialog(data);
		} else {
			openDetailsWindow(data);
		}
	};

	const [isOpenDetailsWindow, setisOpenDetailsWindow] = useState(false);
	const [detailsWindowData, setdetailsWindowData] = useState(null);
	const [isOpenDoubleclickDialog, setisOpenDoubleclickDialog] = useState(false);
	const [detailsWindowDataToSave, setDetailsWindowDataToSave] = useState(null);
	const [isOpenSaveDialog, setisOpenSaveDialog] = useState(false);
	const [confirmSaveDialogOpen, setConfirmSaveDialogOpen] = useState(false);
	const handleOpenDoubleclickDialog = (dataToShow) => {
		setisOpenDoubleclickDialog(true);
		setdetailsWindowData(dataToShow);
	};

	const openDetailsWindow = (dataToShow) => {
		setisOpenDetailsWindow(true);
		setdetailsWindowData(dataToShow);
	};

	const onAcceptDoubleclickDialog = () => {
		setisOpenDoubleclickDialog(false);

		openDetailsWindow(detailsWindowData);
	};

	const handleCloseDoubleclickDialog = () => {
		setisOpenDoubleclickDialog(false);
	};

	const handleSaveDetailsWindow = (dataToSave) => {
		const currentUser = userContext.getUser();
		const lastUser = detailsWindowData.last_user;

		const dataWithCurrentUser = {
			...detailsWindowData,
			...dataToSave,
			last_user: currentUser.name,
			json_out: dataToSave,
		};

		if (!lastUser || lastUser !== currentUser.name) {
			setDetailsWindowDataToSave(dataWithCurrentUser);
			setConfirmSaveDialogOpen(true);
		} else {
			putManualTask(dataWithCurrentUser, true, () => {
				closeDetailsWindow();
				getManualTask(onSaveDetailsWindow, dataToSave);
			});
		}
	};
	const onSaveDetailsWindow = (fetchedData, dataToSave) => {
		const row = fetchedData.find((el) => el.id === detailsWindowData.id);
		if (isJsonOutSet(row.json_out)) {
			setsnack({
				open: true,
				status: "warning",
				message:
					"Is it not possible to save your modifies because this task has been already completed by another user. Please close this window.",
			});
			return;
		}

		row.json_out = dataToSave;

		if (isAnotherUserModifying(user, row.last_user)) {
			handleOpenSaveDialog();
			setDetailsWindowDataToSave(row);
		} else {
			putManualTask(row, true);
			closeDetailsWindow();
		}
	};

	const handleOpenSaveDialog = () => {
		setisOpenSaveDialog(true);
	};
	const handleCloseSaveDialog = () => {
		setisOpenSaveDialog(false);
	};

	const onAcceptSaveDetailsWindow = () => {
		putManualTask(detailsWindowDataToSave, true, () => {
			closeDetailsWindow();
			getManualTask(onSaveDetailsWindow, detailsWindowDataToSave);
			handleCloseSaveDialog();
		});
	};

	const handleCloseDetailsWindow = () => {
		getManualTask(onCloseDetailsWindow);
		setErrorDialogOpen(false);
		setErrorMessage("");
		closeDetailsWindow();
	};

	const isJsonOutSet = (obj) => Object.keys(obj).length > 0;

	const onCloseDetailsWindow = (data) => {
		const row = data.find((el) => el.id === detailsWindowData.id);
		// console.log(data, row);
		if (
			isAnotherUserModifying(user, row.last_user) ||
			isJsonOutSet(row.json_out)
		) {
			closeDetailsWindow();
			setErrorMessage("");
			setErrorDialogOpen(false);
		} else {
			row.last_user = "";
			row.last_user_ts = "";
			putManualTask(row, false);
			closeDetailsWindow();
			setErrorMessage("");
			setErrorDialogOpen(false);
		}
	};

	const closeDetailsWindow = () => {
		setisOpenDetailsWindow(false);
		setdetailsWindowData(null);
		setDetailsWindowDataToSave(null);
		setErrorDialogOpen(false);
		setErrorMessage("");
	};

	const isEmptyUser = (emptyUser) => {
		return emptyUser === "" || emptyUser == null;
	};

	const isAnotherUserModifying = (accessingUser, accessedUser) => {
		if (!accessingUser.hasRole("ngom-user")) {
			if (isEmptyUser(accessedUser)) return false;
			if (accessingUser.name === accessedUser) return false;
			return true;
		}
		return false;
	};

	const [locationFilterApplied, setLocationFilterApplied] = useState(false);

	const fetchManualTaskState = () => {
		const apiPath = "/workflow/manual_tasks";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		apiUrl.searchParams.set(
			"start",
			`${pagination.pageIndex * pagination.pageSize}`
		);
		apiUrl.searchParams.set("size", `${pagination.pageSize}`);

		columnFilters.forEach((columnFilter) => {
			if (columnFilter.id === "name") {
				apiUrl.searchParams.set("name", columnFilter.value);
			} else if (columnFilter.id === "description") {
				apiUrl.searchParams.set("description", columnFilter.value);
			} else if (columnFilter.id === "customerId") {
				apiUrl.searchParams.set("customerid", columnFilter.value);
			} else if (columnFilter.id === "ServiceOrderId") {
				apiUrl.searchParams.set("serviceorderid", columnFilter.value);
			} else if (columnFilter.id === "retries") {
				apiUrl.searchParams.set("retries", columnFilter.value);
			} else if (columnFilter.id === "start_ts") {
				apiUrl.searchParams.set("start_ts", columnFilter.value);
			} else if (columnFilter.id === "last_user") {
				apiUrl.searchParams.set("last_user", columnFilter.value);
			} else if (columnFilter.id === "last_user_ts") {
				apiUrl.searchParams.set("last_user_ts", columnFilter.value);
			} else if (columnFilter.id === "run_id") {
				apiUrl.searchParams.set("run_id", columnFilter.value);
			}
		});

		httpService.getAPI(
			apiUrl,
			manualTaskState,
			setManualTaskState,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	useEffect(() => {
		if (location.state && location.state.runId && !locationFilterApplied) {
			setColumnFilters((prev) => [
				...prev,
				{ id: "run_id", value: location.state.runId },
			]);
		}
		setLocationFilterApplied(true);
	}, [location]);

	useEffect(() => {
		if (locationFilterApplied) {
			fetchManualTaskState();
		}
		if (fetchRequired) {
			setFetchRequired(false);
		}

		// const refreshInterval = setInterval(() => {
		//     fetchManualTaskState();
		// }, 10000);

		// return () => {
		//     clearInterval(refreshInterval); // Cleanup when the component unmounts
		// };
	}, [
		fetchRequired,
		columnFilters,
		//globalFilter,
		pagination.pageIndex,
		pagination.pageSize,
		//sorting,
	]);

	const getAlert = () => {
		if (isError) {
			return {
				color: "error",
				children: "Error loading data",
			};
		}

		return undefined;
	};

	const minifyRunID = (ID) => {
		return ID.split("_")[0] + " " + "\u00B7".repeat(3);
	};

	return (
		<Box m="20px">
			<Dialog
				open={isOpenDoubleclickDialog}
				onClose={handleCloseDoubleclickDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title" fontWeight="bold" fontSize="16pt">
					Confirmation Alert
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Another user is already working on this task. Please confirm your
						request.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCloseDoubleclickDialog}
						variant="contained"
						color="secondary"
					>
						Leave
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={() => {
							// accessTask();
							onAcceptDoubleclickDialog();
						}}
						autoFocus
					>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
				<DialogTitle id="alert-dialog-title" fontWeight="bold" fontSize="16pt">
					Alert
				</DialogTitle>{" "}
				<DialogContent>
					<DialogContentText>{errorMessage}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setErrorDialogOpen(false)}
						variant="contained"
						color="secondary"
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={confirmSaveDialogOpen}
				onClose={() => setConfirmSaveDialogOpen(false)}
				aria-labelledby="confirm-save-dialog-title"
				aria-describedby="confirm-save-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" fontWeight="bold" fontSize="16pt">
					Confirmation Alert
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="confirm-save-dialog-description">
						Another user is already working on this task, are you sure you want
						to override the changes?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setConfirmSaveDialogOpen(false)}
						variant="contained"
						color="secondary"
						autoFocus
					>
						No
					</Button>
					<Button
						onClick={() => {
							setConfirmSaveDialogOpen(false);

							onAcceptSaveDetailsWindow();
						}}
						variant="contained"
						color="secondary"
						autoFocus
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={snack.open}
				autoHideDuration={6000}
				onClose={() => setsnack((prev) => ({ ...prev, open: false }))}
			>
				<Alert severity={snack.status} variant="filled" sx={{ width: "100%" }}>
					{snack.message}
				</Alert>
			</Snackbar>
			<Header
				title="Wizard"
				subtitle={"Task's spellbook: Fill, Save, Finish!"}
			/>

			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				positionActionsColumn="last"
				muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
				displayColumnDefOptions={{
					"mrt-row-actions": {
						muiTableHeadCellProps: {
							align: "left",
							sx: {
								fontWeight: "bold",
								color: colors.grey[100],
								typography: "h5",
							},
						},
						muiTableBodyCellProps: {
							align: "left",
						},
						size: 40,
					},
				}}
				columns={columns}
				data={manualTaskState}
				//getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: false,
					density: "comfortable",
				}}
				muiTableHeadCellProps={{
					align: "left",
					sx: {
						fontWeight: "bold",
						color: colors.grey[100],
						typography: "h5",
					},
				}}
				muiTableBodyCellProps={{
					align: "left",
					sx: {
						color: colors.grey[100],
						typography: "h6",
					},
				}}
				muiTableBodyProps={{
					sx: (theme) => ({
						"& tr:nth-of-type(odd)": {
							backgroundColor: darken(theme.palette.background.default, 0.05),
						},
					}),
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				//rowCount={rowCount}
				rowCount={
					manualTaskState && manualTaskState.length > 0
						? manualTaskState[0].totalDBRowCount
						: 0
				}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableSorting={false}
				enableDensityToggle={false}
				enableColumnOrdering={false}
				enableHiding={false}
				enableRowActions={true}
				enableGlobalFilter={false}
				manualFiltering={true}
				manualPagination={true}
				manualSorting={false}
				//renderDetailPanel={RenderDetail}
				renderRowActionMenuItems={({ closeMenu, row, table }) => [
					<MenuItem
						key="details"
						onClick={() => {
							closeMenu();
							// console.log(row);
							!isJsonOutSet(row.original.json_out) && handleDoubleclick(row);
						}}
					>
						<ListItemIcon>
							<DetailsIcon fontSize="large" />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: "h6" }}
							primary="Details"
						/>
					</MenuItem>,
				]}
				muiTableBodyRowProps={({ row }) => ({
					onDoubleClick: (event) => {
						//TODO: uncomment the following to enable user check on double click
						// Object.keys(row.original.json_out).length === 0 && checkIfBusy(row);
						!isJsonOutSet(row.original.json_out) && handleDoubleclick(row);
					},
					sx: {
						// cursor: "pointer",

						...(!isJsonOutSet(row.original.json_out) && {
							cursor: "pointer",
						}),
					},
				})}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title="Refresh">
								<IconButton
									onClick={() => {
										setFetchRequired(true);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
			/>
			{isOpenDetailsWindow && (
				<RenderRightDrawer
					rowData={detailsWindowData}
					isOpen={isOpenDetailsWindow}
					// onClose={handleCloseDetails}
					onClose={handleCloseDetailsWindow}
					// onSave={handleSave}
					onSave={handleSaveDetailsWindow}
				/>
			)}
		</Box>
	);
};

const RenderRightDrawer = ({ isOpen, onClose, rowData, onSave }) => {
	// Setting initial form state
	//const [formData, setFormData] = useState(rowData.json_in);
	const userContext = useUserContext();
	const user = userContext.getUser();

	const [formData, setFormData] = useState(rowData.json_in);
	const [editedFormData, setEditedFormData] = useState({});
	const [schema, setSchema] = useState(rowData.json_schema);
	const [tabs, setTabs] = useState([]);

	function onDataChange(data) {
		//formData is OLD, data is NEW
		setEditedFormData(data);
	}

	// Handle Save
	const handleSave = () => {
		// Call the onSave prop with the current formData
		onSave(editedFormData);
	};

	const [detailsActiveTab, setDetailsActiveTab] = useState(0);
	const detailsHandleTabChange = (newValue) => {
		// Update the selected tab when the user changes tabs
		setDetailsActiveTab(newValue);
	};

	const [activeTab, setActiveTab] = useState(0);
	const handleTabChange = (newValue) => {
		// Update the selected tab when the user changes tabs
		setActiveTab(newValue);
	};

	const tabsData = [
		{
			label: "Intervention",
			content: (
				<Grid container direction="column" spacing={2}>
					<JsonSchemaBox
						schema={schema}
						formData={formData}
						onChange={onDataChange}
						tabs={tabs}
					/>
					<Grid container item justifyContent="flex-end">
						<Button
							variant="contained"
							color="secondary"
							onClick={handleSave}
							disabled={user.hasRole("ngom-user")}
						>
							Save
						</Button>
					</Grid>
				</Grid>
			),
		},
	];

	const attributesToShow = [
		{
			key: "name",
			displayName: "Name",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "description",
			displayName: "Description",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "customerId",
			displayName: "Customer ID",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "ServiceOrderId",
			displayName: "Order ID",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "retries",
			displayName: "Retries",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "run_id",
			displayName: "Run ID",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "text",
		},
		{
			key: "start_ts",
			displayName: "Start",
			enableCopy: true,
			enableInfo: true,
			infoText: "",
			valueType: "datetime",
		},
	];

	const detailsTabsData = [
		{
			label: "",
			disabled: true,
			content: (
				<Box>
					<Box>
						<DetailsBox
							detailsData={rowData}
							attributesToShow={attributesToShow}
						/>
					</Box>
				</Box>
			),
		},
	];

	return (
		<RightDrawer
			isOpen={isOpen}
			onClose={onClose}
			title={rowData["name"]}
			tabs={tabsData}
			activeTab={activeTab}
			onTabChange={handleTabChange}
			detailsTabs={detailsTabsData}
			detailsActiveTab={detailsActiveTab}
			detailsOnTabChange={detailsHandleTabChange}
			detailsPanelTitle={"Details"}
		/>
	);
};

export default Wizard;
