import React, { useState, useEffect } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useTheme,
} from "@mui/material";
import useHttpService from "../../customHooks/useHttpService.js";
import { tokens } from "../../theme";

function AddTaskPage({ open, onClose, onAdd, dagName }) {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const httpService = useHttpService();

	const [taskNameError, setTaskNameError] = useState(false);
	const [pythonFileError, setPythonFileError] = useState(false);

	const [task, setTask] = useState({
		task_name: "",
		python_file: "",
		steps: [],
		outputs: {},
		output_conditions: {},
		enabled: false,
		trigger_rule: "none_failed_min_one_success",
		dag_name: dagName,
		manual_id: null,
		json_data: {},
		json_schema: {},
		output_type: "branch",
	});

	const handleChange = (prop) => (event) => {
		let isValidName = true;
		let isValidPythonFile = true;
		if (prop === "task_name") {
			isValidName =
				event.target.value !== "" &&
				/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(event.target.value);
			if (!isValidName) {
				setTaskNameError(true);
			} else {
				setTaskNameError(false);
			}
		}
		else if (prop === "python_file") {
			isValidPythonFile =
				event.target.value !== "" && event.target.value !== null 
			if (!isValidPythonFile) {
				setPythonFileError(true);
			} else {
				setPythonFileError(false);
			}
		}
		setTask({ ...task, [prop]: event.target.value });
	};

	const handleAddTask = () => {
		let isValidName = true;
		let isValidPythonFile = true;
		isValidName =
			task.task_name !== "" &&
			/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(task.task_name);
		if (!isValidName) {
			setTaskNameError(true);
			return
		} else {
			setTaskNameError(false);
		}
		isValidPythonFile =
			task.python_file !== "" && task.python_file !== null;
		if (!isValidPythonFile) {
			setPythonFileError(true);
			return
		} else {
			setPythonFileError(false);
		}
		onAdd(task);
		setTask({
			task_name: "",
			python_file: "",
			steps: [],
			outputs: {},
			output_conditions: {},
			enabled: false,
			trigger_rule: "none_failed_min_one_success",
			dag_name: dagName,
			manual_id: null,
			json_data: {},
			json_schema: {},
			output_type: "branch",
		});
		onClose();
	};

	const [taskTemplatesData, setTaskTemplatesData] = useState([]);
	const [taskTemplatesDataIsError, setTaskTemplatesDataIsError] =
		useState(false);
	const [taskTemplatesDataIsLoading, setTaskTemplatesDataIsLoading] =
		useState(false);
	const [taskTemplatesDataIsRefetching, setTaskTemplatesDataIsRefetching] =
		useState(false);
	const [taskTemplatesDataRowCount, setTaskTemplatesDataRowCount] = useState(0);

	const fetchTaskTemplatesData = () => {
		const apiPath = "/workflow/task_templates";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set("json_in", "{}");
		httpService.getAPI(
			apiUrl,
			taskTemplatesData,
			setTaskTemplatesData,
			setTaskTemplatesDataRowCount,
			setTaskTemplatesDataIsLoading,
			setTaskTemplatesDataIsRefetching,
			setTaskTemplatesDataIsError
		);
	};

	useEffect(() => {
		fetchTaskTemplatesData();
	}, []);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
			PaperProps={{ sx: { bgcolor: colors.primary[400] } }}
		>
			<DialogTitle>Add Task</DialogTitle>
			<DialogContent>
				<TextField
					style={{ marginBottom: "20px" }}
					label="Name"
					value={task.task_name}
					onChange={handleChange("task_name")}
					fullWidth
					margin="normal"
					required
					error={taskNameError}
					helperText={taskNameError && "Invalid Name"}
				/>
				<FormControl fullWidth style={{ marginBottom: "20px" }}>
					<InputLabel id="python-file-select-label">Python File</InputLabel>
					<Select
						labelId="python-file-select-label"
						id="python-file-select"
						label="Python File"
						value={task.python_file}
						onChange={handleChange("python_file")}
						required
						error={pythonFileError}
						helperText={pythonFileError && "No File selected"}
					>
						{taskTemplatesData.map((taskTemplate, index) => (
							<MenuItem key={index} value={taskTemplate.python_file}>
								{taskTemplate.python_file}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth style={{ marginBottom: "20px" }}>
					<InputLabel id="trigger-rule-select-label">Trigger Rule</InputLabel>
					<Select
						labelId="trigger-rule-select-label"
						id="trigger-rule-select"
						label="Trigger Rule"
						value={task.trigger_rule}
						onChange={handleChange("trigger_rule")}
					>
						<MenuItem value={"start"}>start</MenuItem>
						<MenuItem value={"all_success"}>all_success</MenuItem>
						<MenuItem value={"all_failed"}>all_failed</MenuItem>
						<MenuItem value={"all_done"}>all_done</MenuItem>
						<MenuItem value={"one_failed"}>one_failed</MenuItem>
						<MenuItem value={"one_success"}>one_success</MenuItem>
						<MenuItem value={"none_failed"}>none_failed</MenuItem>
						<MenuItem value={"none_skipped"}>none_skipped</MenuItem>
						<MenuItem value={"none_failed_min_one_success"}>
							none_failed_min_one_success
						</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth style={{ marginBottom: "20px" }}>
					<InputLabel id="output-type-select-label">Output Type</InputLabel>
					<Select
						labelId="output-type-select-label"
						id="output-type-select"
						label="Output Type"
						value={task.output_type}
						onChange={handleChange("output_type")}
					>
						<MenuItem value={"branch"}>branch</MenuItem>
						<MenuItem value={"defer"}>defer</MenuItem>
						<MenuItem value={"multi"}>multi</MenuItem>
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button disabled={taskNameError || pythonFileError} onClick={handleAddTask}>Add</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AddTaskPage;
