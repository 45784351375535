import {
	Typography,
	Box,
	useTheme,
	FormControlLabel,
	Switch,
	FormControl,
	InputLabel,
	Select,
	Button,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { tokens } from "../../theme";
import useHttpService from "../Common/useHttpService";

function Setting(props) {
	const [settingsData, setsettingsData] = useState([]);
	const [originalSettingsData, setoriginalSettingsData] = useState([]);
	const [dialogIsOpen, setdialogIsOpen] = useState(false);
	const [dialogData, setdialogData] = useState([]);

	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const apiPathSettings = "/ftth_coverage/settings";
	const apiUrlSettings = new URL(
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPathSettings
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPathSettings,
		process.env.NODE_ENV === "production"
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	useEffect(() => {
		getSettingsData();
	}, []);

	const getSettingsData = async () => {
		httpService.getAPI(apiUrlSettings.href, onSettingsDataReceived);
	};

	const onSettingsDataReceived = (data) => {
		setsettingsData(data);
		setoriginalSettingsData(JSON.parse(JSON.stringify(data)));
	};

	const fromStringToBool = (str) => {
		if (str === "false") return false;
		if (str === "true") return true;
		return str;
	};

	const fromBoolToString = (bool) => {
		// console.log(bool);
		return bool.toString();
	};

	const buildDialogMessages = () => {
		const messages = [];

		settingsData.forEach((setting, index) => {
			// console.log(setting.value, originalSettingsData[index].value);
			if (setting.value !== originalSettingsData[index].value) {
				if (setting.name === "BUSINESS_SIMULATION")
					messages.push("Change the service coverage rules");
				if (
					setting.name === "LOCK_ORDER_WORKFLOWS" &&
					setting.value === "false"
				)
					messages.push("Activate Order Workflows");
				if (setting.name === "LOCK_ORDER_WORKFLOWS" && setting.value === "true")
					messages.push("Deactivate Order Workflows");
				if (
					setting.name === "LOCK_EVENT_WORKFLOWS" &&
					setting.value === "false"
				)
					messages.push("Activate Event Workflows");
				if (setting.name === "LOCK_EVENT_WORKFLOWS" && setting.value === "true")
					messages.push("Deactivate Event Workflows");
			}
		});

		setdialogData(messages);
	};

	useEffect(() => {
		// console.log(dialogData);
		if (dialogData.length > 0) {
			setdialogIsOpen(true);
		}
	}, [dialogData]);

	const saveSettingsData = () => {
		let _settingsData = [...settingsData];

		let promises = [];
		_settingsData.forEach((el) => {
			// console.log(el);
			if (el.name !== "TASK_ID_TO_CLEAR")
				promises.push(httpService.putAPI(apiUrlSettings.href, el));
		});

		Promise.all(promises)
			.then((res) => {
				setoriginalSettingsData(JSON.parse(JSON.stringify(_settingsData)));
				setdialogData([]);
				props.setsnack &&
					props.setsnack({
						open: true,
						message: `Operation successful completed`,
						status: "success",
					});
			})
			.catch((err) => {
				setdialogData([]);
				console.err(err);
				props.setsnack &&
					props.setsnack({
						open: true,
						message: `An error occurred while saving!`,
						status: "error",
					});
			});
	};

	// console.log(settingsData, originalSettingsData);

	return (
		<>
			{dialogData.length > 0 && (
				<Dialog
					open={dialogIsOpen}
					onClose={() => setdialogIsOpen(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth="xs"
					fullWidth
				>
					<DialogTitle
						id="alert-dialog-title"
						fontWeight="bold"
						fontSize="16pt"
					>
						Confirmation Alert
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<b>Warning! </b>You are about to:
							<ul>
								{dialogData.map((el) => {
									return <li>{el}</li>;
								})}
							</ul>
							<div>Do you want to continue?</div>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setsettingsData(
									JSON.parse(JSON.stringify(originalSettingsData))
								);
								setdialogIsOpen(false);
								setdialogData([]);
							}}
							variant="contained"
							color="secondary"
						>
							No
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								setdialogIsOpen(false);
								saveSettingsData();
							}}
							autoFocus
						>
							Yes
						</Button>
					</DialogActions>
				</Dialog>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "flex-start",
					width: "100%",
					marginTop: "20px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						width: "30%",
						marginTop: "20px",
						marginLeft: "20px",
					}}
				>
					<Typography>Business Simulation</Typography>
					<FormControl>
						<InputLabel shrink={true} id="demo-select-small-label">
							Business Simulation
						</InputLabel>
						<Select
							labelId="demo-select-small-label"
							id="demo-select-small"
							value={
								settingsData.length > 0
									? fromBoolToString(
											settingsData.find(
												(el) => el.name === "BUSINESS_SIMULATION"
											)?.value
									  )
									: ""
							}
							label="Business Simulation"
							displayEmpty
							notched={true}
							onChange={(e) => {
								let _administrationData = [...settingsData];
								_administrationData.forEach((el) => {
									if (el.name === "BUSINESS_SIMULATION")
										el.value = fromBoolToString(e.target.value);
								});
								setsettingsData(_administrationData);
							}}
						>
							<MenuItem value={true}>Check Whitelist</MenuItem>
							<MenuItem value={false}>Check Blacklist</MenuItem>
							<MenuItem value="">Passthrough</MenuItem>
						</Select>
					</FormControl>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						width: "30%",
						marginTop: "20px",
						marginLeft: "20px",
					}}
				>
					<Typography>Lock Event Workflows</Typography>
					<FormControl required={false}>
						<FormControlLabel
							control={
								<Switch
									name="Lock Event Workflows"
									checked={
										fromStringToBool(
											settingsData?.find(
												(el) => el.name === "LOCK_EVENT_WORKFLOWS"
											)?.value
										) || false
									}
									color="secondary"
									disabled={false}
									onChange={(e) => {
										let _administrationData = [...settingsData];
										_administrationData.forEach((el) => {
											if (el.name === "LOCK_EVENT_WORKFLOWS")
												el.value = fromBoolToString(e.target.checked);
										});

										setsettingsData(_administrationData);
									}}
								/>
							}
						></FormControlLabel>
					</FormControl>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						width: "30%",
						marginTop: "20px",
						marginLeft: "20px",
					}}
				>
					<Typography>Lock Order Workflows</Typography>
					<FormControl
						required={false}
						// error={jsonSchema[key].mandatory && !formData[key]}
					>
						<FormControlLabel
							control={
								<Switch
									name="Lock Order Workflows"
									color="secondary"
									checked={
										fromStringToBool(
											settingsData?.find(
												(el) => el.name === "LOCK_ORDER_WORKFLOWS"
											)?.value
										) || false
									}
									disabled={false}
									onChange={(e) => {
										let _administrationData = [...settingsData];
										_administrationData.forEach((el) => {
											if (el.name === "LOCK_ORDER_WORKFLOWS")
												el.value = fromBoolToString(e.target.checked);
										});
										setsettingsData(_administrationData);
									}}
								/>
							}
						></FormControlLabel>
					</FormControl>
				</Box>
				<Button
					sx={{ width: "7%", marginLeft: "20px", marginTop: "20px" }}
					variant="contained"
					color="secondary"
					onClick={() => {
						buildDialogMessages();
					}}
				>
					Save
				</Button>
			</Box>
		</>
	);
}

export default Setting;
