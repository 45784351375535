import {
	useTheme,
	Box,
	IconButton,
	Tooltip,
	darken,
	ListItemIcon,
	ListItemText,
	MenuItem,
} from '@mui/material';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import Header from '../../components/Header/Header';
import useHttpService from '../../customHooks/useHttpService.js';
import { Refresh, HistoryOutlined } from '@mui/icons-material';
import { MaterialReactTable } from 'material-react-table';
import { tokens } from '../../theme';
import { Typography } from '@mui/material';
import DetailsIcon from '@mui/icons-material/Details';
import RightDrawer from '../../components/RightDrawer/RightDrawer';
import DetailsBox from '../../components/DetailsBox/DetailsBox';
import StatCard from '../../components/StatCard/StatCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertToDate } from '../../utils';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import KeyValueGrid from '../../components/KeyValueGrid/KeyValueGrid.jsx';

const WorkOrders = () => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const tableInstanceRef = useRef(null);

	const location = useLocation();

	// main table -> WorkOrders
	const [workOrdersData, setWorkOrdersData] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [columnFilters, setColumnFilters] = useState(() => {
		let initialFilters = [];
		if (location.state) {
			if (location.state?.ServiceOrderId) {
				initialFilters.push({ id: "order id", value: location.state.ServiceOrderId });
			}
			if (location.state?.WorkOrderState) {
				initialFilters.push({ id: "WorkorderStateID", value: location.state.WorkOrderState });
			}
		}
		return initialFilters;
	});
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

    const navigate = useNavigate();
    const toEventsPage = (ServiceOrderId) => {
        navigate("/events", { state: { ServiceOrderId: ServiceOrderId } });
    };

	const fetchWorkOrdersData = () => {
		const apiPath = '/sid/v_dashboard_workorder';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		apiUrl.searchParams.set(
			'start',
			`${pagination.pageIndex * pagination.pageSize}`
		);
		apiUrl.searchParams.set('size', `${pagination.pageSize}`);

		columnFilters.forEach((columnFilter) => {
			if (columnFilter.id == 'workorder type') {
				apiUrl.searchParams.set('workordertype', columnFilter.value);
			} else if (columnFilter.id == 'OrderAction') {
				apiUrl.searchParams.set('action', columnFilter.value);
			} else if (columnFilter.id == 'WorkorderStateID') {
				apiUrl.searchParams.set('workorderstateid', columnFilter.value);
			} else if (columnFilter.id == 'order id') {
				apiUrl.searchParams.set('orderid', columnFilter.value);
			} else if (columnFilter.id == 'version') {
				apiUrl.searchParams.set('version', columnFilter.value);
			} else if (columnFilter.id == 'customer id') {
				apiUrl.searchParams.set('customerid', columnFilter.value);
			} else if (columnFilter.id == 'start date') {
				apiUrl.searchParams.set('startdate', columnFilter.value);
			} else if (columnFilter.id == 'last date') {
				apiUrl.searchParams.set('lastdate', columnFilter.value);
			} else if (columnFilter.id == 'workorder phase') {
				apiUrl.searchParams.set('workorderphase', columnFilter.value);
			}
		});

		//console.log(columnFilters);
		//console.log(pagination);
		//apiUrl.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
		//apiUrl.searchParams.set("globalFilter", globalFilter ?? "");
		//apiUrl.searchParams.set("sorting", JSON.stringify(sorting ?? []));
		//console.log(apiUrl);
		httpService.getAPI(
			apiUrl,
			workOrdersData,
			setWorkOrdersData,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	useEffect(() => {
		fetchWorkOrdersData();
	}, [
		fetchRequired,
		columnFilters,
		//globalFilter,
		pagination.pageIndex,
		pagination.pageSize,
		//sorting,
	]);

	// Service Order Action
	const [actionData, setActionData] = useState([]);
	const [actionDataIsError, setActionDataIsError] = useState(false);
	const [actionDataIsLoading, setActionDataIsLoading] = useState(false);
	const [actionDataIsRefetching, setActionDataIsRefetching] = useState(false);
	const [actionDataRowCount, setActionDataRowCount] = useState(0);

	const fetchActionData = () => {
		const apiPath = '/sid/list_ServiceOrderAction';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.getAPI(
			apiUrl,
			actionData,
			setActionData,
			setActionDataRowCount,
			setActionDataIsLoading,
			setActionDataIsRefetching,
			setActionDataIsError
		);
	};

	useEffect(() => {
		fetchActionData();
	}, []);

	// Work Order Type
	const [typeData, setTypeData] = useState([]);
	const [typeDataIsError, setTypeDataIsError] = useState(false);
	const [typeDataIsLoading, setTypeDataIsLoading] = useState(false);
	const [typeDataIsRefetching, setTypeDataIsRefetching] = useState(false);
	const [typeDataRowCount, setTypeDataRowCount] = useState(0);

	const fetchTypeData = () => {
		const apiPath = '/sid/list_WorkOrderType';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.getAPI(
			apiUrl,
			typeData,
			setTypeData,
			setTypeDataRowCount,
			setTypeDataIsLoading,
			setTypeDataIsRefetching,
			setTypeDataIsError
		);
	};

	useEffect(() => {
		fetchTypeData();
	}, []);

	// Work Order State Config
	const [stateData, setStateData] = useState([]);
	const [stateDataIsError, setStateDataIsError] = useState(false);
	const [stateDataIsLoading, setStateDataIsLoading] = useState(false);
	const [stateDataIsRefetching, setStateDataIsRefetching] = useState(false);
	const [stateDataRowCount, setStateDataRowCount] = useState(0);

	const fetchStateData = () => {
		const apiPath = '/sid/list_WorkOrderStateConfig';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.getAPI(
			apiUrl,
			stateData,
			setStateData,
			setStateDataRowCount,
			setStateDataIsLoading,
			setStateDataIsRefetching,
			setStateDataIsError
		);
	};

	useEffect(() => {
		fetchStateData();
	}, []);

	const getAlert = () => {
		if (isError) {
			return {
				color: 'error',
				children: 'Error loading data',
			};
		}

		return undefined;
	};

	const minifyID = (ID) => {
		return ID.split('-')[0] + ' ' + '\u00B7'.repeat(3);
	};

	const [detailsOpen, setDetailsOpen] = useState(false);
	const [detailsRow, setDetailsRow] = useState({});
	const handleCloseDetails = () => {
		setDetailsOpen(false);
	};

	const columns = useMemo(
		() => [
			{
				accessorKey: 'customer id',
				header: 'Customer ID',
				size: 150,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: 'order id',
				header: 'Order ID',
				size: 150,
				isInput: false,
				enableEditing: false,
				enableClickToCopy: true,
			},
			{
				accessorKey: 'version',
				header: 'Version',
				size: 30,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: 'workorder type',
				header: 'Type',
				size: 200,
				isInput: false,
				enableEditing: false,
				filterVariant: 'select',
				filterSelectOptions: typeData.map((item) => item.list),
			},
			{
				accessorKey: 'OrderAction',
				header: 'Action',
				size: 80,
				isInput: false,
				enableEditing: false,
				filterVariant: 'select',
				filterSelectOptions: actionData.map((item) => item.list),
			},
			{
				accessorKey: 'WorkorderStateID',
				header: 'State',
				size: 80,
				isInput: false,
				enableEditing: false,
				filterVariant: 'select',
				filterSelectOptions: stateData.map((item) => item.list),
			},
			{
				accessorKey: 'workorder phase',
				header: 'Phase',
				size: 80,
				isInput: false,
				enableEditing: false,
			},
			{
				id: 'start date',
				accessorFn: (row) =>
					row['start date'] ? convertToDate(row['start date']) : '',
				header: 'Start Date',
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: 'Filter: From indicated datetime',
									sx: { minWidth: '120px' },
									variant: 'standard',
								},
								field: { clearable: true },
							}}
							ampm={false}
							format='dd/MM/yyyy HH:mm:ss'
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
			},
			{
				id: 'last date',
				accessorFn: (row) =>
					row['last date'] ? convertToDate(row['last date']) : '',
				header: 'Last Date',
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
				Filter: ({ column }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							onChange={(newValue) => {
								convertToDate(newValue) === convertToDate(null)
									? column.setFilterValue(null)
									: column.setFilterValue(new Date(newValue).toISOString());
							}}
							slotProps={{
								textField: {
									helperText: 'Filter: From indicated datetime',
									sx: { minWidth: '120px' },
									variant: 'standard',
								},
								field: { clearable: true },
							}}
							ampm={false}
							format='dd/MM/yyyy HH:mm:ss'
							allowSameDateSelection={true}
							//value={column.getFilterValue()}
						/>
					</LocalizationProvider>
				),
				// muiTableHeadCellFilterTextFieldProps: {
				// 	type: 'datetime-local',
				// },
			},
		],
		[actionData, typeData, stateData]
	);

	return (
		<Box m='20px'>
			<Header title='Work Orders' subtitle={'List of Work Orders'} />
			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				positionActionsColumn='last'
				muiTableContainerProps={{ sx: { maxHeight: '55vh' } }}
				displayColumnDefOptions={{
					'mrt-row-actions': {
						muiTableHeadCellProps: {
							align: 'left',
							sx: {
								fontWeight: 'bold',
								color: colors.grey[100],
								typography: 'h5',
							},
						},
						muiTableBodyCellProps: {
							align: 'left',
						},
						size: 40,
					},
				}}
				columns={columns}
				data={workOrdersData}
				//getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: false,
					density: 'comfortable',
				}}
				// muiTopToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				// muiBottomToolbarProps={{
				// 	sx: {
				// 		background:
				// 			'linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)',
				// 	},
				// }}
				muiTableHeadCellProps={{
					align: 'left',
					sx: {
						fontWeight: 'bold',
						color: colors.grey[100],
						typography: 'h5',
					},
				}}
				muiTableBodyCellProps={{
					align: 'left',
					sx: {
						color: colors.grey[100],
						typography: 'h6',
					},
				}}
				muiTableBodyProps={{
					sx: (theme) => ({
						'& tr:nth-of-type(odd)': {
							backgroundColor: darken(theme.palette.background.default, 0.05),
						},
					}),
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				//rowCount={rowCount}
				rowCount={
					workOrdersData && workOrdersData.length > 0
						? workOrdersData[0].totalDBRowCount
						: 0
				}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableSorting={false}
				enableDensityToggle={false}
				enableColumnOrdering={false}
				enableHiding={false}
				enableRowActions={true}
				enableGlobalFilter={false}
				manualFiltering={true}
				manualPagination={true}
				manualSorting={false}
				//renderDetailPanel={RenderDetail}
				renderRowActionMenuItems={({ closeMenu, row, table }) => [
					<MenuItem
						key='Events'
						onClick={() => {
							toEventsPage(row.original["order id"]);
						}}
					>
						<ListItemIcon>
							<HistoryOutlined />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: 'h6' }}
							primary='Events'
						/>
					</MenuItem>,
					<MenuItem
						key='details'
						onClick={() => {
							closeMenu();
							setDetailsRow(row.original);
							setDetailsOpen(true);
						}}
					>
						<ListItemIcon>
							<DetailsIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: 'h6' }}
							primary='Details'
						/>
					</MenuItem>,
				]}
				muiTableBodyRowProps={({ row }) => ({
					onDoubleClick: (event) => {
						setDetailsRow(row.original);
						setDetailsOpen(true);
					},
					sx: {
						cursor: 'pointer',
					},
				})}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title='Refresh'>
								<IconButton
									onClick={() => {
										setFetchRequired(prev => !prev);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
			/>
			{detailsOpen && (
				<RenderRightDrawer
					rowData={detailsRow}
					isOpen={detailsOpen}
					onClose={handleCloseDetails}
				/>
			)}
		</Box>
	);
};

const OrderRequests = ({
	serviceOrderId,
	version,
	rowSelection,
	setRowSelection,
	setData,
}) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [orData, setOrData] = useState([]);
	const [orDataIsError, setOrDataIsError] = useState(false);
	const [orDataIsLoading, setOrDataIsLoading] = useState(false);
	const [orDataIsRefetching, setOrDataIsRefetching] = useState(false);
	const [orDataRowCount, setOrDataRowCount] = useState(0);

	const fetchOrData = () => {
		const apiPath = '/sid/bl_ServiceProduct';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set('serviceorderid', `${serviceOrderId}`);
		apiUrl.searchParams.set('version', `${version}`);
		httpService.getAPI(
			apiUrl,
			orData,
			setOrData,
			setOrDataRowCount,
			setOrDataIsLoading,
			setOrDataIsRefetching,
			setOrDataIsError
		);
	};

	useEffect(() => {
		fetchOrData();
	}, []);

	const onRowSelection = (rowSelection) => {
		setRowSelection(rowSelection);
		setData(orData);
	};

	const columns = useMemo(
		() => [
			{
				accessorKey: 'description',
				header: 'Description',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: 'action',
				header: 'Action',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
                accessorKey: "state",
                header: "State",
                size: 100,
                isInput: false,
                enableEditing: false,
            },
			{
				id: 'requestedStartDate',
				accessorFn: (row) =>
					row['requestedStartDate']
						? convertToDate(row['requestedStartDate'])
						: '',
				header: 'Start Date',
				maxSize: 100,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
			},
		],
		[]
	);

	return (
		<MaterialReactTable
			columns={columns}
			data={orData}
			enableColumnActions={false}
			enableColumnFilters={false}
			// enablePagination={true}
			enableSorting={false}
			enableGlobalFilter={false}
			enableDensityToggle={false}
			enableHiding={false}
			enableStickyHeader={true}
			// enableBottomToolbar={true}
			// enableTopToolbar={false}
			enableRowSelection={true}
			enableMultiRowSelection={false}
			getRowId={(row) => row.id}
			onRowSelectionChange={onRowSelection}
			state={{ rowSelection }}
			//muiTableBodyRowProps={{ hover: false }}
			muiTableBodyRowProps={({ row }) => ({
				onClick: row.getToggleSelectedHandler(),
				sx: { cursor: 'pointer' },
			})}
			muiTableProps={{
				sx: {
					border: '1px solid rgba(81, 81, 81, 1)',
				},
			}}
			muiTableHeadCellProps={{
				sx: {
					border: '1px solid rgba(81, 81, 81, 1)',
				},
			}}
			muiTableBodyCellProps={{
				sx: {
					border: '1px solid rgba(81, 81, 81, 1)',
				},
			}}
		/>
	);
};

const Products = ({ serviceOrderRequestId, title }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [prData, setPrData] = useState([]);
	const [prDataIsError, setPrDataIsError] = useState(false);
	const [prDataIsLoading, setPrDataIsLoading] = useState(false);
	const [prDataIsRefetching, setPrDataIsRefetching] = useState(false);
	const [prDataRowCount, setPrDataRowCount] = useState(0);

	const fetchPrData = () => {
		const apiPath = '/sid/ServiceOrderRequestItem';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set(
			'serviceorderrequestid',
			`${serviceOrderRequestId}`
		);
		httpService.getAPI(
			apiUrl,
			prData,
			setPrData,
			setPrDataRowCount,
			setPrDataIsLoading,
			setPrDataIsRefetching,
			setPrDataIsError
		);
	};

	useEffect(() => {
		if (serviceOrderRequestId !== null && serviceOrderRequestId !== undefined) {
			fetchPrData();
		}
	}, []);

	const columns = useMemo(
		() => [
			{
				accessorKey: 'Product',
				header: 'Product',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: 'ProductID',
				header: 'Product ID',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
		],
		[]
	);

	return (
		<>
			{serviceOrderRequestId ? (
				<MaterialReactTable
					columns={columns}
					data={prData}
					enableColumnActions={false}
					enableColumnFilters={false}
					// enablePagination={true}
					enableSorting={false}
					enableGlobalFilter={false}
					enableDensityToggle={false}
					enableHiding={false}
					enableStickyHeader={true}
					// enableBottomToolbar={true}
					// enableTopToolbar={false}
					//enableRowSelection={false}
					//enableMultiRowSelection={false}
					getRowId={(row) => row.id}
					// muiTableBodyRowProps={{ hover: false }}
					muiTableProps={{
						sx: {
							border: '1px solid rgba(81, 81, 81, 1)',
						},
					}}
					muiTableHeadCellProps={{
						sx: {
							border: '1px solid rgba(81, 81, 81, 1)',
						},
					}}
					muiTableBodyCellProps={{
						sx: {
							border: '1px solid rgba(81, 81, 81, 1)',
						},
					}}
					renderDetailPanel={({ row }) => (
						<div style={{ padding: '20px' }}>
							{row.original.json && <KeyValueGrid data={row.original.json} />}
						</div>
					)}
					renderTopToolbarCustomActions={({ table }) =>
						title && (
							<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
								<Typography variant='h6'>{title}</Typography>
							</Box>
						)
					}
				/>
			) : (
				<Box style={{ display: 'flex', justifyContent: 'center' }}>
					<Typography variant='h6'>
						Please select a service from previous tab to see the relevant
						products.
					</Typography>
				</Box>
			)}
		</>
	);
};

const Tasks = ({ serviceOrderId, version }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [tasksData, setTasksData] = useState([]);
	const [tasksDataIsError, setTasksDataIsError] = useState(false);
	const [tasksDataIsLoading, setTasksDataIsLoading] = useState(false);
	const [tasksDataIsRefetching, setTasksDataIsRefetching] = useState(false);
	const [tasksDataRowCount, setTasksDataRowCount] = useState(0);

	const fetchTasksData = () => {
		const apiPath = '/sid/ServiceOrder_Dag_Task';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set('serviceorderid', `${serviceOrderId}`);
		apiUrl.searchParams.set('version', `${version}`);
		//apiUrl.searchParams.set('tasks', `1`);
		httpService.getAPI(
			apiUrl,
			tasksData,
			setTasksData,
			setTasksDataRowCount,
			setTasksDataIsLoading,
			setTasksDataIsRefetching,
			setTasksDataIsError
		);
	};

	useEffect(() => {
		fetchTasksData();
	}, []);

	const columns = useMemo(
		() => [
			{
				accessorKey: 'dag_id',
				header: 'DAG ID',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: 'task_id',
				header: 'Task ID',
				size: 100,
				isInput: false,
				enableEditing: false,
			},
			{
				id: 'start_ts',
				accessorFn: (row) =>
					row['start_ts'] ? convertToDate(row['start_ts']) : '',
				header: 'Start Date',
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
			},
			{
				id: 'end_ts',
				accessorFn: (row) =>
					row['end_ts'] ? convertToDate(row['end_ts']) : '',
				header: 'Last Date',
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
				// muiTableHeadCellFilterTextFieldProps: {
				// 	type: 'datetime-local',
				// },
			},
		],
		[]
	);

	return (
		serviceOrderId &&
		version && (
			<MaterialReactTable
				columns={columns}
				data={tasksData}
				enableColumnActions={false}
				enableColumnFilters={false}
				// enablePagination={true}
				enableSorting={false}
				enableGlobalFilter={false}
				enableDensityToggle={false}
				enableHiding={false}
				enableStickyHeader={true}
				// enableBottomToolbar={true}
				// enableTopToolbar={false}
				// enableRowSelection={false}
				// enableMultiRowSelection={false}
				// getRowId={(row) => row.id}
				// muiTableBodyRowProps={{ hover: false }}
				muiTableProps={{
					sx: {
						border: '1px solid rgba(81, 81, 81, 1)',
					},
				}}
				muiTableHeadCellProps={{
					sx: {
						border: '1px solid rgba(81, 81, 81, 1)',
					},
				}}
				muiTableBodyCellProps={{
					sx: {
						border: '1px solid rgba(81, 81, 81, 1)',
					},
				}}
			/>
		)
	);
};

const CustomerDetails = ({ customerId }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [customerData, setCustomerData] = useState([]);
	const [customerDataIsError, setCustomerDataIsError] = useState(false);
	const [customerDataIsLoading, setCustomerDataIsLoading] = useState(false);
	const [customerDataIsRefetching, setCustomerDataIsRefetching] =
		useState(false);
	const [customerDataRowCount, setCustomerDataRowCount] = useState(0);

	const fetchCustomerData = () => {
		const apiPath = '/sid/Customer';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set('customerid', `${customerId}`);
		httpService.getAPI(
			apiUrl,
			customerData,
			setCustomerData,
			setCustomerDataRowCount,
			setCustomerDataIsLoading,
			setCustomerDataIsRefetching,
			setCustomerDataIsError
		);
	};

	useEffect(() => {
		fetchCustomerData();
	}, []);

	const attributesToShow = [
		{
			key: 'customerID',
			displayName: 'Customer ID',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'name',
			displayName: 'Name',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'type',
			displayName: 'Type',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'buildingId',
			displayName: 'Building ID',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'streetName',
			displayName: 'Street Name',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'streetNr',
			displayName: 'Street Number',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'staircase',
			displayName: 'Staircase',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'city',
			displayName: 'City',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'fiscalCode',
			displayName: 'Fiscal Code',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'PIVA',
			displayName: 'PIVA',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'phone',
			displayName: 'Phone',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'otherPhone',
			displayName: 'Other Phone',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'Province',
			displayName: 'Province',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
	];

	return (
        customerData.length === 1 &&
		<DetailsBox
			detailsData={customerData[0]}
			attributesToShow={attributesToShow}
		/>
	);
};

const RenderRightDrawer = ({ isOpen, onClose, rowData }) => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [selectedOrderId, setSelectedOrderId] = useState(rowData['order id']);
	const [selectedVersion, setSelectedVersion] = useState(rowData['version']);
	const [selectedRequestId, setSelectedRequestId] = useState(null);

	const [detailsActiveTab, setDetailsActiveTab] = useState(0);
	const detailsHandleTabChange = (newValue) => {
		// Update the selected tab when the user changes tabs
		setDetailsActiveTab(newValue);
	};

	const [activeTab, setActiveTab] = useState(0);
	const handleTabChange = (newValue) => {
		// Update the selected tab when the user changes tabs
		setActiveTab(newValue);
	};

	const navigate = useNavigate();
	const toWizardPage = (runId) => {
		navigate('/wizard', { state: { runId: runId } });
	};

	useEffect(() => {
		setSelectedOrderId(rowData['order id']);
		setSelectedVersion(rowData['version']);
	}, [rowData]);

	const [selectedData, setSelectedData] = useState(null);
	const [orData, setOrData] = useState(null);
	const [rowSelection, setRowSelection] = useState({});
	useEffect(() => {
		if (Object.keys(rowSelection).length === 0) {
			setSelectedRequestId(null);
		} else {
			// Get the first key and return it
			setSelectedRequestId(Object.keys(rowSelection)[0]);
			setSelectedData(
				orData.find((item) => item.id === Object.keys(rowSelection)[0])
			);
			setActiveTab(1);
		}
	}, [rowSelection]);

	// Define attributes to show with their display names
	const attributesToShow = [
		{
			key: 'customer id',
			displayName: 'Customer ID',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'order id',
			displayName: 'Order ID',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'version',
			displayName: 'Version',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'workorder type',
			displayName: 'Type',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'OrderAction',
			displayName: 'Action',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'WorkorderStateID',
			displayName: 'State',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
        {
			key: 'workorder phase',
			displayName: 'Phase',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'text',
		},
		{
			key: 'start date',
			displayName: 'Start Date',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'datetime',
		},
		{
			key: 'last date',
			displayName: 'Last Date',
			enableCopy: true,
			enableInfo: true,
			infoText: '',
			valueType: 'datetime',
		},
	];

	function onClickManualTask() {
		onClose();
		toWizardPage(rowData['run_id']);
	}

	const statData = {
		total: {
			title: 'Pending Tasks',
			value:
				rowData['manual tasks waiting(USER)'] +
				rowData['service tasks waiting(SOM)'],
		},
		left: {
			title: 'Manual',
			value: rowData['manual tasks waiting(USER)'],
			onClick: onClickManualTask,
		},
		right: {
			title: 'SOM',
			value: rowData['service tasks waiting(SOM)'],
		},
	};

	const tabsData = [
		{
			label: 'Services',
			content: (
				<OrderRequests
					serviceOrderId={selectedOrderId}
					version={selectedVersion}
					rowSelection={rowSelection}
					setRowSelection={setRowSelection}
					setData={setOrData}
				/>
			),
		},
		{
			label: 'Products',
			content: (
				<Products
					serviceOrderRequestId={selectedRequestId}
					title={
						selectedData
							? `Selected Service: ${selectedData.description} | ${
									selectedData.action
							  } | ${
									selectedData.requestedStartDate
										? convertToDate(selectedData.requestedStartDate)
										: null
							  }`
							: ' '
					}
				/>
			),
		},
		{
			label: 'Tasks',
			content: (
				<Tasks serviceOrderId={selectedOrderId} version={selectedVersion} />
			),
		},
	];

	const detailsTabsData = [
		{
			label: 'Order',
			content: (
				<Box>
					<Box mb={3}>
						<StatCard statData={statData} />
					</Box>
					<Box>
						<DetailsBox
							detailsData={rowData}
							attributesToShow={attributesToShow}
						/>
					</Box>
				</Box>
			),
		},
		{
			label: 'Customer',
			content: (
                rowData &&
				<Box>
					<Box>
						<CustomerDetails customerId={rowData['customer id']} />
					</Box>
				</Box>
			),
		},
	];

	return (
		<RightDrawer
			isOpen={isOpen}
			onClose={onClose}
			title={`Order ID: ${selectedOrderId} | Version: ${selectedVersion}`}
			tabs={tabsData}
			activeTab={activeTab}
			onTabChange={handleTabChange}
			detailsTabs={detailsTabsData}
			detailsActiveTab={detailsActiveTab}
			detailsOnTabChange={detailsHandleTabChange}
			detailsPanelTitle={'Details'}
		/>
	);
};

export default WorkOrders;
