import { useState, useEffect } from "react";
import {
	AppBar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Tab,
	Tabs,
	TextField,
	Typography,
	Grid,
	useTheme,
} from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { tokens } from "../../theme";
import useHttpService from "../../customHooks/useHttpService.js";
import AvailableMethods from "./availableMethods.jsx";
import { TempleBuddhist } from "@mui/icons-material";


export default function StepsPage({
	open,
	initialSteps,
	onClose,
	onSave,
	readOnly,
	pythonFile,
}) {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const httpService = useHttpService();

	const [steps, setSteps] = useState(initialSteps);
	const [value, setValue] = useState(0);

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleAddStep = () => {
		const newStep = "";
		setSteps([...steps, newStep]);
		setValue(steps.length);
	};

	const handleRemoveStep = (index) => {
		const newSteps = steps.filter((_, i) => i !== index);
		setSteps(newSteps);
		setValue(0);
	};

	const handleCodeChange = (index, newCode) => {
		const newSteps = [...steps];
		newSteps[index] = newCode;
		setSteps(newSteps);
	};

	const handleSaveChanges = () => {
		// Handle saving changes here
		onSave(steps);
	};

	const handleClose = () => {
		onClose();
	};

	const [taskTemplatesData, setTaskTemplatesData] = useState([]);
	const [taskTemplatesDataIsError, setTaskTemplatesDataIsError] =
		useState(false);
	const [taskTemplatesDataIsLoading, setTaskTemplatesDataIsLoading] =
		useState(false);
	const [taskTemplatesDataIsRefetching, setTaskTemplatesDataIsRefetching] =
		useState(false);
	const [taskTemplatesDataRowCount, setTaskTemplatesDataRowCount] = useState(0);

	const fetchTaskTemplatesData = () => {
		const apiPath = "/workflow/task_templates";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set("json_in", `{"python_file": "${pythonFile}"}`);
		httpService.getAPI(
			apiUrl,
			taskTemplatesData,
			setTaskTemplatesData,
			setTaskTemplatesDataRowCount,
			setTaskTemplatesDataIsLoading,
			setTaskTemplatesDataIsRefetching,
			setTaskTemplatesDataIsError
		);
	};

	useEffect(() => {
		fetchTaskTemplatesData();
	}, []);

	const handleAddMethod = (item) => {
		const newSteps = [...steps];
		newSteps[value] = newSteps[value] + "\n" + item;
		setSteps(newSteps);
	};

	return (
		steps && (
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					fullWidth
					maxWidth="md"
					PaperProps={{ sx: { bgcolor: colors.primary[400] } }}
				>
					<AppBar position="static" style={{ background: colors.primary[400] }}>
						<Tabs value={value} onChange={handleTabChange} variant="scrollable">
							{steps.map((step, index) => (
								<Tab key={index} label={"Step " + (index + 1)} />
							))}
						</Tabs>
					</AppBar>
					<DialogContent>
						{steps.map((step, index) => (
							<div key={index} hidden={value !== index}>
								{/* <TextField
									label='Code'
									value={step}
									onChange={(e) => handleCodeChange(index, e.target.value)}
									fullWidth
									style={{ marginBottom: '10px' }} // Add margin bottom inline style
									disabled={readOnly}
								/> */}
								<CodeEditor
									disabled={readOnly}
									value={step}
									language="python"
									placeholder="Please enter Python Code."
									onChange={(e) => handleCodeChange(index, e.target.value)}
									padding={15}
									style={{
										fontSize: 16,
										//backgroundColor: '#5c534e',
										fontFamily:
											"ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
									}}
									data-color-mode={theme.palette.mode}
								/>
							</div>
						))}
					</DialogContent>
					<DialogActions>
						<Grid container justifyContent="space-between">
							<Grid item>
								{!readOnly && (
									<>
										<Button onClick={handleAddStep}>Add Step</Button>
										{steps.length > 0 && (
											<Button onClick={() => handleRemoveStep(value)}>
												Remove Step {value + 1}
											</Button>
										)}
										{taskTemplatesData.length > 0 && (
											<AvailableMethods items={taskTemplatesData[0].methods} onSelect={handleAddMethod} />
										)}
										
									</>
								)}
							</Grid>
							<Grid item>
								<Button onClick={handleClose}>Close</Button>
								{!readOnly && (
									<Button onClick={handleSaveChanges}>Save Changes</Button>
								)}
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</div>
		)
	);
}
