import React, { useState, useEffect } from "react";
import {
	Grid,
	Tooltip,
	Typography,
	TextField,
	MenuItem,
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormControl,
	FormHelperText,
	Tabs,
	Tab,
	Box,
	InputLabel,
	Switch,
} from "@mui/material";

import TextFieldCombo from "./TextFieldCombo";
import ListBox from "./ListBox";
import FileBox from "./FileBox";

import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import produce from "immer";
import { set, has, isEmpty, isUndefined } from "lodash";
import {
	isNonEmptyArray,
	isNonEmptyNumber,
	isNonEmptyString,
	checkIsConditionFullfilled,
} from "../../utils";

import { useNavigate } from "react-router-dom";

//funtion to manage json states
function enhancedReducer(state, updateArg) {
	if (isEmpty(updateArg)) return {};

	// check if the type of update argument is a callback function
	if (updateArg.constructor === Function) {
		return { ...state, ...updateArg(state) };
	}

	// if the type of update argument is an object
	if (updateArg.constructor === Object) {
		// does the update object have _path and _value as it's keys
		// if yes then use them to update deep object values
		if (has(updateArg, "_path") && has(updateArg, "_value")) {
			const { _path, _value } = updateArg;

			return produce(state, (draft) => {
				set(draft, _path, _value);
			});
		} else {
			return { ...state, ...updateArg };
		}
	}
}

function roundToDecimals(value, decimals) {
	if (value === "_") return "_";
	const result =
		Math.round(parseFloat(value) * 10 ** decimals) / 10 ** decimals;
	return isNaN(result) ? "_" : result;
}

export default function JsonSchemaBox(props) {
	const [tabs, setTabs] = useState([]);
	const [jsonSchema, setJsonSchema] = useState({});
	const [formData, setFormData] = React.useReducer(enhancedReducer, {});
	const [modifiedData, setModifiedData] = useState(null);
	const [selectedTab, setSelectedTab] = React.useState(0);
	const [hasListMokedData, sethasListMokedData] = useState(false);

	//const history = useHistory();
	const navigate = useNavigate();

	const admittedCharsForNumbers = /^[0-9\.]+$/;
	const admittedKeys = [
		"ArrowRight",
		"ArrowLeft",
		"ArrowUp",
		"ArrowDown",
		"Backspace",
		"Delete",
		"F5",
		"Control",
		"Tab",
	]; // list of permitted keys in keyDown event for type number fields

	useEffect(() => {
		if (props.schema) {
			const checkListMokedData = Object.entries(props.schema).find(
				(key) => key[1].type === "list" && key[1].hasMokedData
			);
			sethasListMokedData(checkListMokedData ? true : false);
		}
	}, []);

	//method to update json states with own setter
	const updateForm = React.useCallback(
		({ target: { value, name, type } }, state, stateSetter, decimals) => {
			const updatePath = name.split(".");

			let tmpValue = null;

			switch (type) {
				case "number":
					if (!isNonEmptyString(value)) tmpValue = "_";
					else if (decimals > 0) tmpValue = roundToDecimals(value, decimals);
					else
						tmpValue =
							value !== "_"
								? isNaN(parseInt(value))
									? "_"
									: parseInt(value)
								: "_";
					break;
				default:
					break;
			}

			// if the input is a checkbox then use callback function to update
			// the toggle state based on previous state
			if (type === "checkbox") {
				stateSetter((prevState) => ({
					[name]: !prevState[name],
				}));

				return;
			}

			// if we have to update the root level nodes in the form
			if (updatePath.length === 1) {
				const [key] = updatePath;

				stateSetter({
					[key]: isNonEmptyString(tmpValue) ? tmpValue : value,
				});
			}

			// if we have to update nested nodes in the form object
			// use _path and _value to update them.
			if (updatePath.length > 1) {
				// stateSetter({
				//   _path: updatePath,
				//   _value: value
				// });

				let currVal = {};
				currVal = state;
				if (type === "number")
					currVal[name] = isNonEmptyString(tmpValue) ? tmpValue : value;
				else currVal[name] = `${isNonEmptyString(tmpValue) ? tmpValue : value}`;
				stateSetter(currVal);
			}
		},
		[]
	);

	useEffect(() => {
		if (props.schema) {
			setJsonSchema(props.schema);

			let jTabs = [];

			if (props.tabs) jTabs = props.tabs;

			Object.keys(props.schema).forEach(function (k) {
				if (props.schema[k].tab) {
					if (!jTabs.includes(props.schema[k].tab))
						jTabs.push(props.schema[k].tab);
				}
			});

			setTabs(jTabs);
		}

		if (JSON.stringify(props.formData) !== "{}") {
			setFormData(props.formData);
		}
	}, [props.tabs, props.schema, props.formData]);

	useEffect(() => {
		if (props.onChange && JSON.stringify(formData) !== "{}") {
			props.onChange(formData);
		}
		if (props.onOnlyModifiedDataChange && modifiedData) {
			props.onOnlyModifiedDataChange(modifiedData);
		}
	}, [formData, modifiedData]);

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<Box sx={{ width: "100%", overflowY: "auto" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={selectedTab}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					{tabs &&
						tabs.map((tab) => (
							<Tab
								label={tab}
								key={tab}
								sx={{
									color: "primary.main",
								}}
							/>
						))}
				</Tabs>
			</Box>
			<Grid container spacing={props.spacing || 3} padding={props.padding || 3}>
				{jsonSchema &&
					Object.keys(jsonSchema).map((key) => {
						return (
							jsonSchema[key] &&
							(jsonSchema[key]?.tab == null ||
								jsonSchema[key].tab === tabs[selectedTab]) && (
								<Grid item md={jsonSchema[key].md || 2}>
									{/* This tooltip will work on MUI components. For custom components, 
                  we need to pass the tooltip as prop and then add the Tooltip inside the component itself */}
									<Tooltip
										title={jsonSchema[key].tooltip || ""}
										sx={{
											display: jsonSchema[key] ? "unset" : "none",
										}}
										placement="top"
									>
										{jsonSchema[key].type === "html" ? (
											<div>
												<InputLabel shrink>{jsonSchema[key].title}</InputLabel>
												{formData[key] && formData[key] != null
													? formData[key]
													: ""}
											</div>
										) : jsonSchema[key].type === "combo" ? (
											<TextFieldCombo
												label={jsonSchema[key].title}
												labelSize={14}
												apiURL={jsonSchema[key].url}
												valueMember={jsonSchema[key].valueMember}
												displayMember={jsonSchema[key].displayMember}
												selectedValue={formData[key]}
												onSelectChange={(e) => {
													setModifiedData((prev) => {
														return {
															...prev,
															[key]: e.target.value,
														};
													});
													updateForm(e, formData, setFormData);

													if (jsonSchema[key].onChange) {
														jsonSchema[key].onChange({
															...formData,
															[key]: e.target.value,
														});
													}
												}}
												type="text"
												name={key}
												required={jsonSchema[key].mandatory}
												showAsterisk={jsonSchema[key].showAsterisk}
												error={jsonSchema[key].mandatory && !formData[key]}
												helperText={
													jsonSchema[key].mandatory &&
													!formData[key] &&
													"Field is mandatory!"
												}
												mockedData={jsonSchema[key].mockedData || formData[key]}
												multiple={jsonSchema[key].multiple}
												disabled={jsonSchema[key].disabled}
												emptySelection={jsonSchema[key].allowEmptySelection}
												tooltip={jsonSchema[key].tooltip}
											/>
										) : jsonSchema[key].type === "list" ? (
											<ListBox
												label={jsonSchema[key].title}
												apiURL={jsonSchema[key].url}
												data={
													jsonSchema[key].data ||
													(hasListMokedData && props.formData[key])
												}
												valueMember={jsonSchema[key].valueMember}
												displayMember={jsonSchema[key].displayMember}
												multiselect={jsonSchema[key].multiselect}
												selectedValue={
													!jsonSchema[key].multiselect && formData[key]
												}
												onSelectChange={(e) => {
													if (!jsonSchema[key].multiselect) {
														setModifiedData((prev) => {
															return {
																...prev,
																[key]: e.target.value,
															};
														});
														updateForm(e, formData, setFormData);
													}
												}}
												checkedValues={
													jsonSchema[key].multiselect &&
													(hasListMokedData ? [] : formData[key])
												}
												onCheckChange={(e, data) => {
													if (data && hasListMokedData)
														sethasListMokedData(false);
													formData[key] = !jsonSchema[key].multiselect
														? JSON.stringify(data)
														: data;
													setModifiedData((prev) => {
														return {
															...prev,
															[key]: data,
														};
													});
													setFormData({
														...formData,
													});
												}}
												name={key}
												dense={jsonSchema[key].dense}
												disabled={jsonSchema[key].disabled || false}
												required={jsonSchema[key].mandatory || false}
												showAsterisk={jsonSchema[key].showAsterisk}
												tooltip={jsonSchema[key].tooltip}
												sx={{
													width: "100%",
												}}
											/>
										) : jsonSchema[key].type === "file" ? (
											<FileBox
												label={jsonSchema[key].title}
												text={
													formData[key] && formData[key] != null
														? formData[key]
														: ""
												}
												onTextChange={(e) => {
													if (!jsonSchema[key].multiselect) {
														setModifiedData((prev) => {
															return {
																...prev,
																[key]: e.target.value,
															};
														});
														updateForm(e, formData, setFormData);
													}
												}}
												name={key}
												dense={jsonSchema[key].dense}
												disabled={jsonSchema[key].disabled || false}
												required={jsonSchema[key].mandatory || false}
												showAsterisk={jsonSchema[key].showAsterisk}
												tooltip={jsonSchema[key].tooltip}
												sx={{
													width: "100%",
												}}
											/>
										) : jsonSchema[key].type === "switch" ? (
											<FormControl
												required={jsonSchema[key].mandatory}
												error={jsonSchema[key].mandatory && !formData[key]}
											>
												<FormControlLabel
													label={jsonSchema[key].title}
													control={
														<Switch
															name={key}
															checked={
																formData[key] !== null ? formData[key] : false
															}
															sx={jsonSchema[key].sx || null}
															disabled={jsonSchema[key].disabled || false}
															onChange={(e) => {
																formData[key] = e.target.checked;
																setModifiedData((prev) => {
																	return {
																		...prev,
																		[key]: e.target.checked,
																	};
																});
																setFormData({
																	...formData,
																});
															}}
														/>
													}
												></FormControlLabel>
												<FormHelperText sx={{ color: "text.error" }}>
													{jsonSchema[key].mandatory &&
														formData[key] == null &&
														"Field is mandatory!"}
												</FormHelperText>
											</FormControl>
										) : jsonSchema[key].type === "checkbox" ? (
											<FormControl
												required={jsonSchema[key].mandatory}
												error={jsonSchema[key].mandatory && !formData[key]}
											>
												<FormGroup>
													<FormControlLabel
														disabled={jsonSchema[key].disabled || false}
														control={
															<Checkbox
																fullWidth
																name={key}
																checked={
																	formData[key] !== null ? formData[key] : false
																}
																sx={jsonSchema[key].sx || null}
																disabled={jsonSchema[key].disabled || false}
																onChange={(e) => {
																	formData[key] = e.target.checked;
																	setModifiedData((prev) => {
																		return {
																			...prev,
																			[key]: e.target.checked,
																		};
																	});
																	setFormData({
																		...formData,
																	});
																}}
															/>
														}
														label={jsonSchema[key].title}
													/>
												</FormGroup>
												<FormHelperText sx={{ color: "text.error" }}>
													{jsonSchema[key].mandatory &&
														formData[key] == null &&
														"Field is mandatory!"}
												</FormHelperText>
											</FormControl>
										) : jsonSchema[key].type === "date" ? (
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DateTimePicker
													ampm={true}
													renderInput={(props) => (
														<Tooltip
															title={jsonSchema[key].tooltip || ""}
															placement="top"
														>
															<TextField {...props} />
														</Tooltip>
													)}
													label={jsonSchema[key].title}
													value={formData[key]}
													inputFormat="yyyy-MM-dd HH:mm:ss OOOO"
													allowSameDateSelection={true}
													disablePast={
														jsonSchema[key].disablePast !== null
															? jsonSchema[key].disablePast
															: true
													}
													InputProps={{
														onKeyDown: (e) => {
															e.preventDefault();
														},
														style: {
															minWidth: "310px",
														},
													}}
													onChange={(newValue) => {
														let tmp = {
															...formData,
														};
														try {
															tmp[key] = newValue.toISOString();
															setModifiedData((prev) => {
																return {
																	...prev,
																	[key]: newValue.toISOString(),
																};
															});
														} catch (e) {}
														setFormData(tmp);
													}}
												/>
											</LocalizationProvider>
										) : jsonSchema[key].type === "json" ? (
											<Box
												sx={{
													width: "100%",
													maxHeight: "400px",
													display: "flex",
													flexDirection: "column",
													alignItems: "flex-start",
													justifyContent: "flex-start",
												}}
											>
												<Typography
													variant="h7"
													sx={{
														color: "secondary.main",
													}}
												>
													{jsonSchema[key].title}
												</Typography>
												{formData[key] ? (
													<Box
														sx={{
															overflowY: "auto",
															width: "100%",
														}}
													>
														<TextField
															disabled={jsonSchema[key].disabled}
															multiline
															value={
																jsonSchema[key].disabled
																	? JSON.stringify(formData[key], null, 2)
																	: formData[key]
															}
															fullWidth
															onChange={(e) => {
																let tmp = {
																	...formData,
																};
																setModifiedData((prev) => {
																	return {
																		...prev,
																		[key]: e.target.value,
																	};
																});
																tmp[key] = e.target.value;
																setFormData(tmp);
															}}
														/>
													</Box>
												) : (
													<Box
														sx={{
															overflowY: "auto",
															width: "100%",
														}}
													>
														<TextField
															multiline
															value={formData[key]}
															fullWidth
															onChange={(e) => {
																let tmp = {
																	...formData,
																};
																setModifiedData((prev) => {
																	return {
																		...prev,
																		[key]: e.target.value,
																	};
																});
																tmp[key] = e.target.value;
																setFormData(tmp);
															}}
														/>
													</Box>
												)}
											</Box>
										) : (
											<TextField
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													inputProps: {
														min:
															jsonSchema[key].minValue != null
																? jsonSchema[key].minValue
																: jsonSchema[key].nonNegative
																? 0
																: null,
														max: jsonSchema[key].maxValue || null,
													},

													readOnly: jsonSchema[key].readOnly || false,
												}}
												disabled={checkIsConditionFullfilled(
													formData,
													formData[key],
													jsonSchema[key],
													"disabled"
												)}
												multiline={jsonSchema[key].multiline || false}
												select={jsonSchema[key].enum}
												fullWidth
												name={key}
												label={jsonSchema[key].title}
												value={
													(formData[key] || formData[key] === 0) &&
													formData[key] !== null
														? jsonSchema[key].type === "number"
															? roundToDecimals(
																	formData[key],
																	jsonSchema[key].decimals || 2
															  )
															: formData[key]
														: ""
												}
												error={
													!checkIsConditionFullfilled(
														formData,
														formData[key],
														jsonSchema[key],
														"mandatory"
													)
												}
												required={
													!checkIsConditionFullfilled(
														formData,
														formData[key],
														jsonSchema[key],
														"mandatory"
													) || jsonSchema[key].showAsterisk
												}
												helperText={
													!checkIsConditionFullfilled(
														formData,
														formData[key],
														jsonSchema[key],
														"mandatory"
													)
														? "Field is mandatory"
														: null
												}
												variant="standard"
												placeholder={jsonSchema[key].placeholder}
												type={jsonSchema[key].type}
												sx={jsonSchema[key].sx || null}
												onKeyDown={(e) => {
													if (
														jsonSchema[key].hasOwnProperty("admittedChars") &&
														!jsonSchema[key].admittedChars.test(e.key) &&
														!admittedKeys.find((k) => k === e.code)
													) {
														e.preventDefault();
													}
													if (
														jsonSchema[key].type === "number" &&
														jsonSchema[key].nonNegative &&
														!admittedKeys.find((k) => k === e.code) &&
														!admittedCharsForNumbers.test(e.key)
													) {
														e.preventDefault();
													}
												}}
												onClick={() => {
													// console.log("clicked");
													if (jsonSchema[key].hasOwnProperty("hyperlink")) {
														// console.log(jsonSchema[key].hyperlink);
														const link = jsonSchema[key].hyperlink.split(":id");
														if (link.length > 1) {
															navigate(`${link[0]}${formData.id}`);
														} else navigate(link[0]);
													}
												}}
												onChange={(e) => {
													setModifiedData((prev) => {
														return {
															...prev,
															[key]:
																!isNonEmptyString(e.target.value) &&
																jsonSchema[key].type === "number"
																	? "_"
																	: jsonSchema[key].decimals
																	? parseFloat(e.target.value).toFixed(
																			jsonSchema[key].decimals
																	  )
																	: jsonSchema[key].type === "number"
																	? parseInt(e.target.value)
																	: e.target.value,
														};
													});

													updateForm(
														e,
														formData,
														setFormData,
														jsonSchema[key].decimals
															? jsonSchema[key].decimals
															: 0
													);
												}}
											>
												{jsonSchema[key].enum &&
													jsonSchema[key].enum.map((option) => (
														<MenuItem key={option} value={option}>
															{option}
														</MenuItem>
													))}
											</TextField>
										)}
									</Tooltip>
								</Grid>
							)
						);
					})}
			</Grid>
		</Box>
	);
}
