import React, { createContext, useContext, useState } from 'react';

const Snackbar = createContext();
const UpdateSnackbar = createContext();

const useSnackbar = () => {
	return useContext(Snackbar);
};

const useUpdateSnackbar = () => {
	return useContext(UpdateSnackbar);
};

const SnackbarProvider = ({ children }) => {
	const [snackbarInfo, setSnackbarInfo] = useState();

	return (
		<Snackbar.Provider
			value={{
				snackbarInfo,
				setSnackbarInfo,
			}}>
			<UpdateSnackbar.Provider
				value={{
					snackbarInfo,
					setSnackbarInfo,
				}}>
				{children}
			</UpdateSnackbar.Provider>
		</Snackbar.Provider>
	);
};

export { SnackbarProvider, useSnackbar, useUpdateSnackbar };
