import React, { useState, useEffect } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useTheme,
} from "@mui/material";
import useHttpService from "../../customHooks/useHttpService.js";
import { Description } from "@mui/icons-material";
import { tokens } from "../../theme";

function AddManualTaskPage({ open, onClose, onAdd, dagName }) {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [inputName, setInputName] = useState("");
	const [inputDesc, setInputDesc] = useState("");

	const [taskNameError, setTaskNameError] = useState(false);

	const handleAdd = () => {
		let isValidName = true;
		isValidName =
		inputName !== "" &&
			/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(inputName);
		if (!isValidName) {
			setTaskNameError(true);
			return
		} else {
			setTaskNameError(false);
		}
		if (inputName.trim() !== "") {
			onAdd({ name: inputName, description: inputDesc });
			onClose();
		}
	};

	const handleCancel = () => {
		onClose();
	};

	const handleNameChange = (event) => {
		let isValidName = true;
		isValidName =
			event.target.value !== "" &&
			/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(event.target.value);
		if (!isValidName) {
			setTaskNameError(true);
		} else {
			setTaskNameError(false);
		}
		setInputName(event.target.value);
	};

	const handleDescChange = (event) => {
		setInputDesc(event.target.value);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCancel}
			PaperProps={{ sx: { bgcolor: colors.primary[400] } }}
		>
			<DialogTitle>Create New Manual Task</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					label="Name"
					fullWidth
					required
					value={inputName}
					onChange={handleNameChange}
					error={taskNameError}
					helperText={taskNameError && "Invalid Name"}
				/>
				<TextField
					autoFocus
					margin="dense"
					label="Description"
					fullWidth
					value={inputDesc}
					onChange={handleDescChange}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleAdd}
					color="primary"
					disabled={inputName.trim() === "" || taskNameError}
				>
					add
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AddManualTaskPage;
