import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useTheme,
} from '@mui/material';
import { tokens } from '../../theme';

function SettingsPage({ initialSettings, open, onClose, onSave, readOnly }) {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [settings, setSettings] = useState(initialSettings);

	const handleClose = () => {
		onClose();
	};

	const handleSave = () => {
		onSave(settings);
	};

	const handleChange = (prop) => (event) => {
		setSettings({ ...settings, [prop]: event.target.value });
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth='md'
			PaperProps={{ sx: { bgcolor: colors.primary[400] } }}
		>
			<DialogTitle>Manage Settings</DialogTitle>
			<DialogContent style={{ minWidth: '300px' }}>
				<TextField
					label='Task Name'
					value={settings.task_mame}
					disabled
					fullWidth
					style={{ marginBottom: '20px', marginTop: '20px' }}
				/>
				<TextField
					label='Python File'
					value={settings.python_file}
					disabled
					fullWidth
					style={{ marginBottom: '20px' }}
				/>
				<FormControl fullWidth style={{ marginBottom: '20px' }}>
					<InputLabel id='task-enabled-select-label'>Enabled</InputLabel>
					<Select
						labelId='task-enabled-select-label'
						id='task-enabled-select'
						label='Enabled'
						value={settings.enabled}
						onChange={handleChange('enabled')}
						disabled={readOnly}
					>
						<MenuItem value={true}>True</MenuItem>
						<MenuItem value={false}>False</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth style={{ marginBottom: '20px' }}>
					<InputLabel id='trigger-rule-select-label'>Trigger Rule</InputLabel>
					<Select
						labelId='trigger-rule-select-label'
						id='trigger-rule-select'
						label='Trigger Rule'
						value={settings.trigger_rule}
						onChange={handleChange('trigger_rule')}
						disabled={readOnly}
					>
						<MenuItem value={'start'}>start</MenuItem>
						<MenuItem value={'all_success'}>all_success</MenuItem>
						<MenuItem value={'all_failed'}>all_failed</MenuItem>
						<MenuItem value={'all_done'}>all_done</MenuItem>
						<MenuItem value={'one_failed'}>one_failed</MenuItem>
						<MenuItem value={'one_success'}>one_success</MenuItem>
						<MenuItem value={'none_failed'}>none_failed</MenuItem>
						<MenuItem value={'none_skipped'}>none_skipped</MenuItem>
						<MenuItem value={'none_failed_min_one_success'}>
							none_failed_min_one_success
						</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth style={{ marginBottom: '20px' }}>
					<InputLabel id='output-type-select-label'>Output Type</InputLabel>
					<Select
						labelId='output-type-select-label'
						id='output-type-select'
						label='Output Type'
						value={settings.output_type}
						onChange={handleChange('output_type')}
						disabled={readOnly}
					>
						<MenuItem value={'branch'}>branch</MenuItem>
						<MenuItem value={'defer'}>defer</MenuItem>
						<MenuItem value={'multi'}>multi</MenuItem>
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
				{!readOnly && <Button onClick={handleSave}>Save</Button>}
			</DialogActions>
		</Dialog>
	);
}

export default SettingsPage;
