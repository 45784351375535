import {
	Box,
	Button,
	IconButton,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import Header from "../../components/Header/Header";
import { PieChart } from "@mui/x-charts/PieChart";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import useHttpService from "../../customHooks/useHttpService.js";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import StatBox from "../../components/StatBox/StatBox";
import WebAssetOutlinedIcon from "@mui/icons-material/WebAssetOutlined";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SyncIcon from "@mui/icons-material/Sync";
import TaskIcon from "@mui/icons-material/Task";

const Dashboard = () => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const navigate = useNavigate();

	// main data table
	const [dataManualTaskPending, setDataManualTaskPending] = useState({
		estimate: 0,
	});
	const [dataSomTaskPending, setDataSomTaskPending] = useState({
		estimate: 0,
	});
	const [dataWorkOrderInProgress, setDataWorkOrderInProgress] = useState({
		estimate: 0,
	});
	const [dataWorkOrderCompletedOk, setDataWorkOrderCompletedOk] = useState({
		estimate: 0,
	});
	const [dataWorkOrderCompletedKo, setDataWorkOrderCompletedKo] = useState({
		estimate: 0,
	});

	const [fetchRequired, setFetchRequired] = useState(true);

	const toMTP = () => {
		navigate("/wizard");
	};
	const [statsManualTaskPending, setStatsManualTaskPending] = useState({
		number: dataManualTaskPending.estimate,
		percentage: 0,
		chartData: [
			{
				id: "MTP",
				value: dataManualTaskPending.estimate,
				label: "MTP",
				color: "#FFC300",
				onClick: toMTP,
			},
		],
	});
	const toSTP = () => {};
	const [statsSomTaskPending, setStatsSomTaskPending] = useState({
		number: dataSomTaskPending.estimate,
		percentage: 0,
		chartData: [
			{
				id: "STP",
				value: dataSomTaskPending.estimate,
				label: "STP",
				color: "#FFC300",
				onClick: toSTP,
			},
		],
	});
	const toWOP = () => {
		navigate("/workorders", { state: { WorkOrderState: "NotCompleted" } });
	};
	const [statsWorkOrderInProgress, setStatsWorkOrderInProgress] = useState({
		number: dataWorkOrderInProgress.estimate,
		percentage: 0,
		chartData: [
			{
				id: "WOP",
				value: dataWorkOrderInProgress.estimate,
				label: "WOP",
				color: "#FFC300",
				onClick: toWOP,
			},
		],
	});
	const toWOSuccess = () => {
		navigate("/workorders", { state: { WorkOrderState: "Completed" } });
	};
	const toWOFailed = () => {
		navigate("/workorders", { state: { WorkOrderState: "Completed" } });
	};
	const toWOCompleted = () => {
		navigate("/workorders", { state: { WorkOrderState: "Completed" } });
	};
	const [statsWorkOrderCompleted, setStatsWorkOrderInCompleted] = useState({
		number:
			dataWorkOrderCompletedOk.estimate + dataWorkOrderCompletedKo.estimate,
		percentage: 0,
		chartData: [
			{
				id: "Success",
				value: dataWorkOrderCompletedOk.estimate,
				label: "Success",
				color: "#229954",
				onClick: toWOSuccess,
			},
			{
				id: "Failed",
				value: dataWorkOrderCompletedKo.estimate,
				label: "Failed",
				color: "#C70039",
				onClick: toWOFailed,
			},
		],
	});

	const fetchStats = useCallback(() => {
		const apiConfigurations = [
			{
				apiPath: "/sid/v_Dashboard_ServiceOrder_Estimate_ManualTask",
				setDataFunction: setDataManualTaskPending,
			},
			{
				apiPath: "/sid/v_Dashboard_SOM_Tasks_Pending",
				setDataFunction: setDataSomTaskPending,
			},
			{
				apiPath: "/sid/v_Dashboard_ServiceOrder_Estimate_Pending",
				setDataFunction: setDataWorkOrderInProgress,
			},
			{
				apiPath: "/sid/v_Dashboard_ServiceOrder_Estimate_OK",
				setDataFunction: setDataWorkOrderCompletedOk,
			},
			{
				apiPath: "/sid/v_Dashboard_ServiceOrder_Estimate_KO",
				setDataFunction: setDataWorkOrderCompletedKo,
			},
		];

		apiConfigurations.forEach((config) => {
			let apiUrl = new URL(
				process.env.NODE_ENV === "production"
					? process.env.REACT_APP_PROD_API_BASE_PATH + config.apiPath
					: process.env.REACT_APP_DEV_API_BASE_PATH + config.apiPath,
				process.env.NODE_ENV === "production"
					? window.location.origin
					: process.env.REACT_APP_DEV_API_BASE_URL
			);

			httpService.getAPIStats(apiUrl, config.setDataFunction);
		});
	});

	useEffect(() => {
		if (fetchRequired) {
			fetchStats();
			setFetchRequired(false);
		}
	}, [fetchRequired, fetchStats]);

	useEffect(() => {
		const refreshInterval = setInterval(() => {
			fetchStats();
		}, 10000);

		return () => {
			clearInterval(refreshInterval);
		};
	}, [fetchStats]);

	useEffect(() => {
		setStatsManualTaskPending((prev) => ({
			...prev,
			number: dataManualTaskPending.estimate,
			chartData: [
				{
					id: "MTP",
					value: 100,
					label: "MTP",
					color: "#FFC300",
					onClick: toMTP,
				},
			],
		}));
	}, [dataManualTaskPending]);

	useEffect(() => {
		setStatsSomTaskPending((prev) => ({
			...prev,
			number: dataSomTaskPending.estimate,
			chartData: [
				{
					id: "STP",
					value: 100,
					label: "STP",
					color: "#FFC300",
					onClick: toSTP,
				},
			],
		}));
	}, [dataSomTaskPending]);

	useEffect(() => {
		setStatsWorkOrderInProgress((prev) => ({
			...prev,
			number: dataWorkOrderInProgress.estimate,
			chartData: [
				{
					id: "WOP",
					value: 100,
					label: "WOP",
					color: "#FFC300",
					onClick: toWOP,
				},
			],
		}));
	}, [dataWorkOrderInProgress]);

	useEffect(() => {
		setStatsWorkOrderInCompleted((prev) => ({
			...prev,
			number:
				dataWorkOrderCompletedOk.estimate + dataWorkOrderCompletedKo.estimate,
			chartData: [
				{
					id: "Success",
					value: dataWorkOrderCompletedOk.estimate,
					label: "Success",
					color: "#229954",
					onClick: toWOSuccess,
				},
				{
					id: "Failed",
					value: dataWorkOrderCompletedKo.estimate,
					label: "Failed",
					color: "#C70039",
					onClick: toWOFailed,
				},
			],
		}));
	}, [dataWorkOrderCompletedOk, dataWorkOrderCompletedKo]);

	return (
		<Box m="20px">
			{/* HEADER */}
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header title="DASHBOARD" subtitle="Welcome" />
			</Box>
			{/* GRID & CHARTS */}
			{
				<Box
					display="grid"
					gridTemplateColumns="repeat(12, 1fr)"
					gridAutoRows="240px"
					gap="20px"
				>
					<Box
						gridColumn="span 6"
						backgroundColor={colors.primary[500]}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<StatBox
							total={statsManualTaskPending.number}
							title="Manual Tasks Pending"
							icon={
								<PendingActionsIcon
									sx={{
										color: colors.skyAccent[500],
										fontSize: "30px",
									}}
								/>
							}
							data={statsManualTaskPending.chartData}
							linkTo="/wizard"
							enableArcLabels={false}
							enableLegends={false}
							enableTooltip={false}
							titleToolTip={
								"Tasks in a pending state that require manual intervention."
							}
							handleClickTitleAndCenter={toMTP}
						/>
					</Box>
					<Box
						gridColumn="span 6"
						backgroundColor={colors.primary[500]}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<StatBox
							total={statsSomTaskPending.number}
							title="SOM Not Responding"
							icon={
								<PendingActionsIcon
									sx={{
										color: colors.skyAccent[500],
										fontSize: "26px",
									}}
								/>
							}
							data={statsSomTaskPending.chartData}
							linkTo=""
							isInteractive={false}
							enableArcLabels={false}
							enableLegends={false}
							enableTooltip={false}
							titleToolTip={
								"The count of orders that have submitted at least one request to SOM but have not received any corresponding event from SOM."
							}
							handleClickTitleAndCenter={toSTP}
						/>
					</Box>

					<Box
						gridColumn="span 6"
						backgroundColor={colors.primary[500]}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<StatBox
							total={statsWorkOrderInProgress.number}
							title="WORK ORDERS NOT COMPLETED"
							icon={
								<SyncIcon
									sx={{
										color: colors.skyAccent[500],
										fontSize: "26px",
									}}
								/>
							}
							data={statsWorkOrderInProgress.chartData}
							linkTo="/workorders"
							enableArcLabels={false}
							enableLegends={false}
							enableTooltip={false}
							titleToolTip={
								"Work Orders not in the Completed state, residing in states like InProgress, Pending, and Started."
							}
							handleClickTitleAndCenter={toWOP}
						/>
					</Box>

					<Box
						gridColumn="span 6"
						backgroundColor={colors.primary[500]}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<StatBox
							total={statsWorkOrderCompleted.number}
							title="Work Orders Completed"
							icon={
								<TaskIcon
									sx={{
										color: colors.skyAccent[500],
										fontSize: "30px",
									}}
								/>
							}
							data={statsWorkOrderCompleted.chartData}
							linkTo="/workorders"
							enableArcLabels={true}
							enableLegends={true}
							enableTooltip={true}
							titleToolTip={
								"Work Orders that have reached the Completed state."
							}
							handleClickTitleAndCenter={toWOCompleted}
						/>
					</Box>
				</Box>
			}
		</Box>
	);
};

export default Dashboard;
