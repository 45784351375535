import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tab,
	Tabs,
	TextField,
	useTheme,
	Grid,
	Box,
} from "@mui/material";
import { tokens } from "../../theme";
import JsonSchemaBox from "../../components/Common/JsonSchemaBox";

const JsonSettingsPage = ({
	initialJsonData,
	initialJsonSchema,
	open,
	onClose,
	onSave,
	readOnly,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [tabIndex, setTabIndex] = useState(0);
	const [jsonData, setJsonData] = useState(initialJsonData);
	const [jsonSchema, setJsonSchema] = useState(initialJsonSchema);

	const [jsonDataError, setJsonDataError] = useState(false);
	const [jsonSchemaError, setJsonSchemaError] = useState(false);

	const handleClose = () => {
		onClose();
	};

	const handleSave = () => {
		// Validate and save JSON data and schema
		let jsonDataValid = true;
		let jsonSchemaValid = true;

		try {
			JSON.parse(jsonData);
		} catch (error) {
			jsonDataValid = false;
			setJsonDataError(true);
		}

		try {
			JSON.parse(jsonSchema);
		} catch (error) {
			jsonSchemaValid = false;
			setJsonSchemaError(true);
		}

		if (!jsonDataValid || !jsonSchemaValid) {
			return;
		}

		// Save JSON data and schema
		// console.log('JSON Data:', jsonData);
		// console.log('JSON Schema:', jsonSchema);
		onSave({ newJsonData: jsonData, newJsonSchema: jsonSchema });
	};

	const handleJsonDataChange = (event) => {
		setJsonData(event.target.value);
		try {
			JSON.parse(event.target.value);
			setJsonDataError(false);
		} catch (error) {
			setJsonDataError(true);
		}
	};

	const handleJsonSchemaChange = (event) => {
		setJsonSchema(event.target.value);
		try {
			JSON.parse(event.target.value);
			setJsonSchemaError(false);
		} catch (error) {
			setJsonSchemaError(true);
		}
	};
	const [tabs, setTabs] = useState([]);

	const jsonParseSanity = (j) => {
		try {
			return JSON.parse(j);
		} catch (error) {
			return null;
		}
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth="lg"
				PaperProps={{ sx: { bgcolor: colors.primary[400] } }}
			>
				<DialogTitle>JSON Editor</DialogTitle>

				<DialogContent>
					<Grid container spacing={1}>
						<Grid item xs={3.5}>
							{/* Left Section */}
							<Tabs
								value={tabIndex}
								onChange={(event, newValue) => setTabIndex(newValue)}
								style={{ marginBottom: "20px" }}
							>
								<Tab label="JSON Schema" />
								<Tab label="JSON Data" />
							</Tabs>
							{tabIndex === 0 && (
								<TextField
									autoFocus
									margin="dense"
									label="JSON Schema"
									fullWidth
									multiline
									value={jsonSchema}
									onChange={handleJsonSchemaChange}
									error={jsonSchemaError}
									helperText={jsonSchemaError && "Invalid JSON Schema"}
									rows={10}
									disabled={readOnly}
								/>
							)}
							{tabIndex === 1 && (
								<TextField
									autoFocus
									margin="dense"
									label="JSON Data"
									fullWidth
									multiline
									value={jsonData}
									onChange={handleJsonDataChange}
									error={jsonDataError}
									helperText={jsonDataError && "Invalid JSON Data"}
									rows={10}
									disabled={readOnly}
								/>
							)}
						</Grid>
						<Grid item xs={0.5}></Grid>
						<Grid item xs={8}>
							{/* Right Section */}
							{jsonParseSanity(jsonSchema) && jsonParseSanity(jsonData) && (
								<Box>
									<JsonSchemaBox
										schema={jsonParseSanity(jsonSchema) || {}}
										formData={jsonParseSanity(jsonData) || {}}
										onChange={() => {}}
										tabs={tabs}
									/>
								</Box>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					{!readOnly && (
						<Button onClick={handleSave} color="primary">
							Save
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default JsonSettingsPage;
