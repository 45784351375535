import { useState } from 'react';
import {
	AppBar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Tab,
	Tabs,
	TextField,
	Typography,
	Grid,
	useTheme,
} from '@mui/material';
import { tokens } from '../../theme';
import CodeEditor from '@uiw/react-textarea-code-editor';

export default function OutputPortsPage({
	id,
	open,
	initialOutputPorts,
	onClose,
	onSave,
	readOnly,
}) {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [outputPorts, setOutputPorts] = useState(initialOutputPorts);
	const [value, setValue] = useState(0);

	const maxNumber = Math.max(
		outputPorts.length + 1,
		...outputPorts.map((output) => {
			const match = output.name.match(/Output(\d+)/);
			return match ? parseInt(match[1]) + 1 : 0;
		})
	);

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleAddOutput = () => {
		const newOutputbName = `Output${maxNumber}`;
		const newOutput = {
			id: `handle_${id}_${newOutputbName}`,
			name: newOutputbName,
			condition: 'True',
		};
		setOutputPorts([...outputPorts, newOutput]);
		setValue(outputPorts.length);
	};

	const handleRemoveOutput = (index) => {
		const newOutputPorts = outputPorts.filter((_, i) => i !== index);
		setOutputPorts(newOutputPorts);
		setValue(0);
	};

	const handleNameChange = (index, newName) => {
		const newOutputPorts = [...outputPorts];
		newOutputPorts[index].name = newName;
		setOutputPorts(newOutputPorts);
	};

	const handleConditionChange = (index, newCondition) => {
		const newOutputPorts = [...outputPorts];
		newOutputPorts[index].condition = newCondition;
		setOutputPorts(newOutputPorts);
	};

	const handleSaveChanges = () => {
		// Handle saving changes here
		onSave(outputPorts);
	};

	const handleClose = () => {
		onClose();
	};

	return (
		outputPorts && (
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					fullWidth
					maxWidth='md'
					PaperProps={{ sx: { bgcolor: colors.primary[400] } }}
				>
					<AppBar position='static' style={{ background: colors.primary[400] }}>
						<Tabs value={value} onChange={handleTabChange} variant='scrollable'>
							{outputPorts.map((output, index) => (
								<Tab key={index} label={output.name} />
							))}
						</Tabs>
					</AppBar>
					<DialogContent>
						{outputPorts.map((output, index) => (
							<div key={index} hidden={value !== index}>
								<TextField
									label='Name'
									value={output.name}
									onChange={(e) => handleNameChange(index, e.target.value)}
									fullWidth
									style={{ marginBottom: '10px' }} // Add margin bottom inline style
									disabled={readOnly}
								/>
								{/* <TextField
									label='Condition'
									value={output.condition}
									onChange={(e) => handleConditionChange(index, e.target.value)}
									fullWidth
									style={{ marginBottom: '10px' }} // Add margin bottom inline style
									disabled={readOnly}
								/> */}
								<CodeEditor
									disabled={readOnly}
									value={output.condition}
									language='python'
									placeholder='Please enter Python Code.'
									onChange={(e) => handleConditionChange(index, e.target.value)}
									padding={15}
									style={{
										fontSize: 16,
										//backgroundColor: '#5c534e',
										fontFamily:
											'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
									}}
									data-color-mode={theme.palette.mode}
								/>
							</div>
						))}
					</DialogContent>
					<DialogActions>
						<Grid container justifyContent='space-between'>
							<Grid item>
								{!readOnly && (
									<>
										<Button onClick={handleAddOutput}>Add Output</Button>
										{outputPorts.length > 0 && (
											<Button onClick={() => handleRemoveOutput(value)}>
												Remove {outputPorts[value].name}
											</Button>
										)}
									</>
								)}
							</Grid>
							<Grid item>
								<Button onClick={handleClose}>Close</Button>
								{!readOnly && (
									<Button onClick={handleSaveChanges}>Save Changes</Button>
								)}
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</div>
		)
	);
}
