import React, { useState, useEffect } from "react";
import { Tooltip, Autocomplete, TextField, MenuItem } from "@mui/material";
import useHttpService from "./useHttpService.js";
import { uniqueId } from "lodash";

export default function TextFieldCombo(props) {
    const [myURL, setURL] = useState(props.apiURL || "");
    const [selectedValue, setSelected] = useState(props.selectedValue || "");
    const [myData, setData] = useState([]);
    const emptyValue = {
        id: "_",
        name: "Empty",
    };

    const httpService = useHttpService();

    useEffect(() => {
        if (!myURL) {
            setData(props.mockedData || props.selectedValue);
        }
    }, [props.selectedValue]);

    function queryAPI() {
        if (!myURL) {
            if (props.mockedData) {
                setData(props.mockedData);
            } else {
                setData([]);
            }
            return;
        }
        httpService.getAPI(myURL, onDataReceived);
    }

    function onDataReceived(data) {
        if (props.emptySelection) {
            if (data[0].hasOwnProperty("reliability")) {
                data = [
                    { ...emptyValue, reliability: "_", fullname: "Empty" },
                    ...data,
                ];
            } else if (data[0].hasOwnProperty("id")) {
                data = [emptyValue, ...data];
            } else {
                data = [{ val: "Empty", id: "_" }, ...data];
            }
            // console.log(data);
            setData(data);
        } else setData(data);
    }

    function selectChange(e, index) {
        //setSelected(e.target.value);

        if (props.multiple && props.onSelectChange) {
            let value = index.map((el) => {
                return el[props.valueMember];
            }); //this is actually the value, not the index
            props.onSelectChange({
                target: {
                    value,
                    type: props.type,
                    name: props.name,
                    row:
                        myData.find(
                            (row) => row[props.valueMember] === index
                        ) || props.mockedData?.find((row) => row === index),
                },
            });
            return;
        }

        if (props.onSelectChange) {
            const row =
                myData.find((row) => {
                    if (props.emptySelection && row[props.valueMember] === "")
                        return (
                            props.emptySelection &&
                            row[props.valueMember] === ""
                        );
                    return row[props.valueMember] === e.target.value;
                }) || props.mockedData[index];
            // console.log(row);
            props.onSelectChange({
                target: {
                    value: e.target.value,
                    type: props.type,
                    name: props.name,
                    row,
                },
            });
        }
    }

    useEffect(() => {
        queryAPI();
        if (props.selectedValue != null) setSelected(props.selectedValue);
        else setSelected("");
    }, [myURL]);

    useEffect(() => {
        setURL(props.apiURL);
        if (props.selectedValue != null) {
            setSelected(props.selectedValue);
        } else setSelected("");
    }, [props.apiURL, props.selectedValue]);

    // console.log(myData);

    return (
        <>
            <Tooltip title={props.tooltip || ""} placement="top">
                {(myData || props.mockedData) && props.multiple ? (
                    <Autocomplete
                        multiple={props.multiple}
                        id="checkboxes-tags-demo"
                        options={myData || props.mockedData}
                        disableCloseOnSelect={props.multiple}
                        limitTags={props.multiple ? 2 : null}
                        getOptionLabel={(option) => {
                            return option[props.valueMember] || option;
                        }}
                        onChange={(e, value) => {
                            // console.log(props.customOnChange);
                            selectChange(e, value);
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    label={props.label}
                                    variant="standard"
                                    required={
                                        props.required && props.showAsterisk
                                    }
                                />
                            );
                        }}
                        disabled={props.disabled}
                        // defaultValue={props.defaultValue}
                    />
                ) : (
                    <TextField
                        helperText={props.helperText || ""}
                        fullWidth
                        name={props.name || ""}
                        sx={{ width: props.fieldSize }}
                        select
                        label={props.label || ""}
                        value={selectedValue}
                        onChange={selectChange}
                        variant="standard"
                        required={props.required && props.showAsterisk}
                        error={props.error || false}
                        disabled={props.disabled}
                    >
                        {myData.map((option) => {
                            if (option)
                                return (
                                    <MenuItem
                                        key={uniqueId()}
                                        value={
                                            option[props.valueMember] != null
                                                ? option[props.valueMember]
                                                : typeof option !== "object"
                                                ? option
                                                : ""
                                        }
                                    >
                                        {option[props.displayMember] != null
                                            ? option[props.displayMember]
                                            : typeof option !== "object"
                                            ? option
                                            : ""}
                                    </MenuItem>
                                );
                        })}
                    </TextField>
                )}
            </Tooltip>
        </>
    );
}
