import React, { useState, useCallback, useEffect, useMemo } from "react";
import ReactFlow, {
	Controls,
	Background,
	applyNodeChanges,
	applyEdgeChanges,
	addEdge,
	isEdge,
	isNode,
	ReactFlowProvider,
	Panel,
	useNodesState,
	useEdgesState,
	useReactFlow,
	MarkerType,
	ConnectionLineType,
	MiniMap,
	Position,
	getNodesBounds,
	getViewportForBounds,
	getOutgoers,
	getIncomers,
	getConnectedEdges,
} from "reactflow";
import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	useTheme,
	Alert,
	Badge,
	Menu,
} from "@mui/material";
import "reactflow/dist/style.css";
import { tokens } from "../../theme";
import Header from "../../components/Header/Header";
import { Box } from "@mui/material";
import TaskNode from "./TaskNode";
import AddTaskPage from "./AddTaskPage";
import AddManualTaskPage from "./AddManualTaskPage";
import JumpToDropDown from "./JumpToDropDown";
import useHttpService from "../../customHooks/useHttpService.js";
import { useParams, useNavigate } from "react-router-dom";
import Dagre from "@dagrejs/dagre";
import ELK from "elkjs/lib/elk.bundled.js";
import { useUserContext } from "../../contexts/userContext.js";

const defaultEdgeOptions = {
	type: "step",
	markerEnd: {
		type: MarkerType.ArrowClosed,
		width: 20,
		height: 20,
		//color: '#FF0072',
	},
	//label: 'marker size and color',
	style: {
		strokeWidth: 3,
		//stroke: '#FF0072',
	},
};

const connectionLineStyle = {
	strokeWidth: 3,
	stroke: "#FF0072",
};

const layoutOptions = {
	"elk.algorithm": "layered",
	"elk.direction": "RIGHT",
	"elk.layered.spacing.nodeNodeBetweenLayers": "200",
	// //'elk.layered.spacing.edgeEdgeBetweenLayers': '150',
	"elk.spacing.nodeNode": "100",
	// //'elk.spacing.edgeEdge': '300',
	"elk.spacing.edgeNode": "400",
	// 'elk.layered.compaction.connectedComponents': true,
	// //'elk.graphviz.layerSpacingFactor': '10',
	// 'elk.hierarchyHandling': 'INCLUDE_CHILDREN',
	"elk.layered.nodePlacement.strategy": "SIMPLE",
};

const groupLayoutOptions = {
	"elk.algorithm": "layered",
	//'elk.direction': 'RIGHT',
	"elk.layered.spacing.edgeNodeBetweenLayers": "20",
	"elk.hierarchyHandling": "INCLUDE_CHILDREN",
	"elk.layered.nodePlacement.strategy": "SIMPLE",
};

const elk = new ELK();

const getLayoutedNodes = async (nodes, edges) => {
	// Function to organize objects into a hierarchical structure
	function organizeObjects(nodes) {
		const objects = nodes.map((n) => {
			const targetPorts = n.data.targetHandles.map((t) => ({
				id: t.id,

				// ⚠️ it's important to let elk know on which side the port is
				// in this example targets are on the left (WEST) and sources on the right (EAST)
				properties: {
					side: "WEST",
				},
			}));

			const sourcePorts = n.data.sourceHandles.map((s) => ({
				id: s.id,
				properties: {
					side: "EAST",
				},
			}));

			return {
				...n,
				//id: n.id,
				width: n.width ?? 360,
				height: n.height ?? 200,
				// ⚠️ we need to tell elk that the ports are fixed, in order to reduce edge crossings
				properties: {
					"org.eclipse.elk.portConstraints": "FIXED_ORDER",
				},
				// we are also passing the id, so we can also handle edges without a sourceHandle or targetHandle option
				ports: [...targetPorts, ...sourcePorts],
			};
		});
		const groupedObjects = {};

		// Create groups based on parent nodes
		objects.forEach((obj) => {
			if (obj.type === "group") {
				groupedObjects[obj.id] = {
					...obj,
					children: [],
					layoutOptions: groupLayoutOptions,
				};
			} else if (obj.parentId) {
			} else {
				groupedObjects[obj.id] = { ...obj };
			}
		});

		// Add children to their respective groups
		objects.forEach((obj) => {
			if (
				obj.type !== "group" &&
				obj.parentId &&
				groupedObjects[obj.parentId]
			) {
				groupedObjects[obj.parentId]["children"].push(obj);
			}
		});

		// Convert grouped objects back to a list
		const result = [];
		Object.values(groupedObjects).forEach((group) => {
			result.push(group);
		});

		return result;
	}

	const graph = {
		id: "root",
		layoutOptions: layoutOptions,
		children: organizeObjects(nodes),
		edges: edges.map((e) => ({
			id: e.id,
			sources: [e.sourceHandle || e.source],
			targets: [e.targetHandle || e.target],
		})),
	};

	const layoutedGraph = await elk.layout(graph);

	function flattenArray(array) {
		return array.reduce((acc, obj) => {
			acc.push(obj);
			if (obj.children && obj.children.length > 0) {
				acc = acc.concat(flattenArray(obj.children));
			}
			return acc;
		}, []);
	}

	const layoutedNodes = nodes.map((node) => {
		const layoutedNode = flattenArray(layoutedGraph.children)?.find(
			(lgNode) => lgNode.id === node.id
		);

		return {
			...node,
			position: {
				x: layoutedNode?.x ?? 0,
				y: layoutedNode?.y ?? 0,
			},
		};
	});

	return layoutedNodes;
};

const minimapStyle = {
	height: 120,
	//background: '#003153',
};

const dagreGraph = new Dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 360;
const nodeHeight = 200;

// const getLayoutedElements = (nodes, edges, direction = 'TB') => {
// 	const isHorizontal = direction === 'LR';
// 	dagreGraph.setGraph({ rankdir: direction });

// 	nodes.forEach((node) => {
// 		dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
// 	});

// 	edges.forEach((edge) => {
// 		dagreGraph.setEdge(edge.source, edge.target);
// 	});

// 	Dagre.layout(dagreGraph);

// 	nodes.forEach((node) => {
// 		const nodeWithPosition = dagreGraph.node(node.id);
// 		node.targetPosition = isHorizontal ? 'left' : 'top';
// 		node.sourcePosition = isHorizontal ? 'right' : 'bottom';

// 		// We are shifting the dagre node position (anchor=center center) to the top left
// 		// so it matches the React Flow node anchor point (top left).
// 		node.position = {
// 			x: nodeWithPosition.x - nodeWidth / 2,
// 			y: nodeWithPosition.y - nodeHeight / 2,
// 		};

// 		return node;
// 	});

// 	return { nodes, edges };
// };

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

function LayoutFlow(props) {
	const nodeTypes = useMemo(() => ({ task: TaskNode }), []);

	const userContext = useUserContext();
	const user = userContext.getUser();

	if (user && !user.hasRole("ngom-businessuser")) window.location.href = "/";

	const tasksData = props.tasksData;
	const dagData = props.dagData;
	const dagName = props.dagData.dag_name;
	const readOnly = props.dagData.run_count > 0;

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const httpService = useHttpService();

	const [rfInstance, setRfInstance] = useState(null);
	const { fitBounds, fitView, getNode, getNodes, getEdges } = useReactFlow();

	const [nodes, setNodes] = useState([]);
	const [edges, setEdges] = useState([]);

	const [outputsErrorCnt, setOutputsErrorCnt] = useState(0);
	const [inputsErrorCnt, setInputsErrorCnt] = useState(0);

	const [anchorChecks, setAnchorChecks] = useState(null);
	const handleChecksOpen = (event) => {
		setAnchorChecks(event.currentTarget);
	};

	const handleChecksClose = () => {
		setAnchorChecks(null);
	};

	const saveLastUser = useCallback(() => {
		if (dagData.hasOwnProperty("id")) {
			const flow = rfInstance.toObject();
			const data = {
				id: dagData.id,
				last_user: user.name,
			};

			const apiPath = "/workflow/dag_template";
			const apiUrl = new URL(
				process.env.NODE_ENV === "production"
					? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
					: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
				process.env.NODE_ENV === "production"
					? window.location.origin
					: process.env.REACT_APP_DEV_API_BASE_URL
			);

			httpService.putAPI(apiUrl, data, () => {}, props.setAutoRefresh);
		}
	}, [user, dagData, httpService, props.setAutoRefresh]);

	const onSaveLayoutDb = useCallback(() => {
		if (rfInstance) {
			if (dagData.hasOwnProperty("id")) {
				const flow = rfInstance.toObject();
				const data = {
					id: dagData.id,
					designer_json: flow,
					last_user: user.name,
				};

				const apiPath = "/workflow/dag_template";
				const apiUrl = new URL(
					process.env.NODE_ENV === "production"
						? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
						: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
					process.env.NODE_ENV === "production"
						? window.location.origin
						: process.env.REACT_APP_DEV_API_BASE_URL
				);

				httpService.putAPI(apiUrl, data, () => {}, props.setAutoRefresh);
			}
		}
	}, [user, rfInstance, dagData, httpService, props.setAutoRefresh]);

	const onNodesChange = useCallback((changes) => {
		// const nextChanges = changes.reduce((acc, change) => {
		// 	// if this change is supposed to remove a node we want to validate it first
		// 	if (change.type === 'remove') {
		// 		//   const node = getNode(change.id)

		// 		//   // if the node can be removed, keep the change, otherwise we skip the change and keep the node
		// 		//   if (shouldNodeBeRemoved(node)) {
		// 		// 	return [...acc, change];
		// 		//   }

		// 		// change is skipped, node is kept
		// 		return acc;
		// 	}

		// 	// all other change types are just put into the next changes arr
		// 	return [...acc, change];
		// }, []);

		// apply the changes we kept
		if (!readOnly) {
			setNodes((nds) => applyNodeChanges(changes, nds));
		}
	}, []);

	const onEdgesChange = useCallback((changes) => {
		if (!readOnly) {
			setEdges((eds) => applyEdgeChanges(changes, eds));
		}
	}, []);

	const onConnect = useCallback(
		(params) => {
			//console.log(params);
			//console.log(nodes);
			setEdges((eds) => addEdge(params, eds));
			const foundSrcNode = nodes.find((obj) => obj.id === params.source);
			const foundTgtNode = nodes.find((obj) => obj.id === params.target);
			const foundSrcHandle = foundSrcNode.data.sourceHandles.find(
				(obj) => obj.id === params.sourceHandle
			);

			const updateTask = (data) => {
				const apiPath = "/workflow/task_instances";
				const apiUrl = new URL(
					process.env.NODE_ENV === "production"
						? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
						: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
					process.env.NODE_ENV === "production"
						? window.location.origin
						: process.env.REACT_APP_DEV_API_BASE_URL
				);

				// const onSuccess = (msg) => {
				// 	console.log('Tasks Duplicated Successfully!');
				// 	console.log(msg);
				// };

				// const onError = (msg) => {
				// 	console.log('Tasks Duplication Failed!');
				// 	console.log(msg);
				// };

				httpService.putAPI(apiUrl, data, () => {}, props.setAutoRefresh);
				onSaveLayoutDb();
			};

			if (foundTgtNode.id === "ENDNODE") {
				const newOutputs = {
					...foundSrcNode.data.origOutputs,
					[foundSrcHandle.name]: "end",
				};

				updateTask({
					id: foundSrcNode.id,
					outputs: newOutputs,
				});
			} else if (foundSrcNode.id === "STARTNODE") {
				updateTask({
					id: foundTgtNode.id,
					trigger_rule: "start",
				});
			} else {
				const newOutputs = {
					...foundSrcNode.data.origOutputs,
					[foundSrcHandle.name]: foundTgtNode.data.label,
				};

				updateTask({
					id: foundSrcNode.id,
					outputs: newOutputs,
				});
			}
		},
		[nodes, props.setAutoRefresh, onSaveLayoutDb]
	);

	const onNodesDelete = useCallback(
		(deleted) => {
			const ids = [];
			for (const { id } of deleted) {
				ids.push(id);
			}
			const apiPath = "/workflow/task_instances";
			const apiUrl = new URL(
				process.env.NODE_ENV === "production"
					? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
					: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
				process.env.NODE_ENV === "production"
					? window.location.origin
					: process.env.REACT_APP_DEV_API_BASE_URL
			);

			const data = { ids: ids };

			httpService.deleteAPI(apiUrl, data, () => {}, props.setAutoRefresh);
			onSaveLayoutDb();
		},
		[nodes, edges, props.setAutoRefresh, onSaveLayoutDb]
	);

	const onEdgesDelete = useCallback(
		(deleted) => {
			//console.log(deleted);
			const updateTask = (data) => {
				const apiPath = "/workflow/task_instances";
				const apiUrl = new URL(
					process.env.NODE_ENV === "production"
						? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
						: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
					process.env.NODE_ENV === "production"
						? window.location.origin
						: process.env.REACT_APP_DEV_API_BASE_URL
				);

				httpService.putAPI(apiUrl, data, () => {}, props.setAutoRefresh);
			};

			const changesBuffer = {};

			for (const { source, sourceHandle, target } of deleted) {
				const foundSrcNode = nodes.find((obj) => obj.id === source);
				const foundTgtNode = nodes.find((obj) => obj.id === target);
				const foundSrcHandle = foundSrcNode.data.sourceHandles.find(
					(obj) => obj.id === sourceHandle
				);

				let newOutputs = {};
				if (changesBuffer.hasOwnProperty(foundSrcNode.id)) {
					newOutputs = {
						...changesBuffer[foundSrcNode.id],
					};
				} else {
					newOutputs = {
						...foundSrcNode.data.origOutputs,
					};
				}
				for (const key in newOutputs) {
					if (
						key === foundSrcHandle.name &&
						newOutputs[key] === foundTgtNode.data.label
					) {
						delete newOutputs[key];
						break;
					}
				}

				changesBuffer[foundSrcNode.id] = newOutputs;
			}

			for (const key in changesBuffer) {
				updateTask({ id: key, outputs: changesBuffer[key] });
			}

			onSaveLayoutDb();
		},
		[nodes, edges, props.setAutoRefresh, onSaveLayoutDb]
	);

	const onSaveLocalStorage = useCallback(() => {
		if (rfInstance) {
			const flow = rfInstance.toObject();
			localStorage.setItem(dagName, JSON.stringify(flow));
		}
	}, [rfInstance]);

	const onRestoreLocalStorage = useCallback(() => {
		const restoreFlow = async () => {
			const flow = JSON.parse(localStorage.getItem(dagName));

			if (flow) {
				const { x = 0, y = 0, zoom = 1 } = flow.viewport;
				setNodes(flow.nodes || []);
				setEdges(flow.edges || []);
				fitView({ x, y, zoom });
			}
		};

		restoreFlow();
	}, [fitView]);

	const onRestoreLayoutDb = useCallback(() => {
		// if (dagData.hasOwnProperty('designer_json')) {
		// 	const restoreFlow = async () => {
		// 		const flow = dagData['designer_json'];

		// 		if (flow) {
		// 			const { x = 0, y = 0, zoom = 1 } = flow.viewport;
		// 			setNodes(flow.nodes || []);
		// 			setEdges(flow.edges || []);
		// 			fitView({ x, y, zoom });
		// 		}
		// 	};

		// 	restoreFlow();
		// }
		props.setAutoRefresh(true);
	}, [props.setAutoRefresh]);

	// const onLayout = useCallback(
	// 	(direction) => {
	// 		const { nodes: layoutedNodes, edges: layoutedEdges } =
	// 			getLayoutedElements(nodes, edges, direction);

	// 		setNodes([...layoutedNodes]);
	// 		setEdges([...layoutedEdges]);
	// 	},
	// 	[nodes, edges]
	// );

	const onLayout = useCallback(() => {
		const layoutNodes = async () => {
			const layoutedNodes = await getLayoutedNodes(nodes, edges);
			setNodes(layoutedNodes);
		};

		layoutNodes();
	}, [nodes, edges]);

	useEffect(() => {
		const savedNodes = dagData?.designer_json?.nodes
			? dagData.designer_json.nodes
			: [];
		if (tasksData.length >= 1) {
			const generateOutputPorts = (task) => {
				const outputPorts = [];
				// Iterate over the keys in 'outputs'
				Object.keys(task.outputs).forEach((outputKey) => {
					// Check if there's a corresponding key in 'output_conditions'
					const condition = task.output_conditions[outputKey] || "True";
					// Create an object with 'name' and 'condition' attributes
					const outputObject = {
						name: outputKey,
						condition: condition,
					};
					// Push the object to the output list
					outputPorts.push(outputObject);
				});

				Object.keys(task.output_conditions).forEach((outputKey) => {
					if (!task.outputs.hasOwnProperty(outputKey)) {
						const outputObject = {
							name: outputKey,
							condition: task.output_conditions[outputKey],
						};
						outputPorts.push(outputObject);
					}
				});

				return outputPorts;
			};

			const generateSourceHandles = (task) => {
				const sourceHandles = [];
				Object.keys(task.outputs).forEach((outputKey) => {
					const condition = task.output_conditions[outputKey] || "True";
					const sourceHandle = {
						id: `handle_${task.id}_${outputKey}`,
						name: outputKey,
						condition: condition,
					};
					sourceHandles.push(sourceHandle);
				});

				Object.keys(task.output_conditions).forEach((outputKey) => {
					if (!task.outputs.hasOwnProperty(outputKey)) {
						const sourceHandle = {
							id: `handle_${task.id}_${outputKey}`,
							name: outputKey,
							condition: task.output_conditions[outputKey],
						};
						sourceHandles.push(sourceHandle);
					}
				});

				return sourceHandles;
			};

			const generateTergetHandles = (task) => {
				const targetHandles = [{ id: `handle_${task.id}_target` }];
				return targetHandles;
			};

			const newNodes = [];

			tasksData.forEach((task) => {
				const foundNode = savedNodes.find((obj) => obj.id === task.id);
				const newNode = {
					id: task.id,
					type: "task",
					position: {
						x: foundNode ? foundNode.position.x : 0,
						y: foundNode ? foundNode.position.y : 0,
					},
					dragHandle: ".custom-drag-handle",
					//style: { border: '1px solid #777' },
					data: {
						label: task.task_name,
						outputPorts: generateOutputPorts(task),
						steps: task.steps,
						task_mame: task.task_name,
						python_file: task.python_file,
						enabled: task.enabled,
						trigger_rule: task.trigger_rule,
						output_type: task.output_type,
						origOutputs: task.outputs,
						manualId: task.manual_id,
						json_data: task.json_data,
						json_schema: task.json_schema,
						targetHandles: generateTergetHandles(task),
						sourceHandles: generateSourceHandles(task),
						readOnly: readOnly,
						setAutoRefresh: props.setAutoRefresh,
						saveLastUser: saveLastUser,
					},
				};
				newNodes.push(newNode);
			});

			const foundStartNode = savedNodes.find((obj) => obj.id === "STARTNODE");
			const foundEndNode = savedNodes.find((obj) => obj.id === "ENDNODE");
			const initNodes = [
				{
					id: "STARTNODE",
					type: "input",
					data: {
						label: "start",
						targetHandles: [],
						sourceHandles: [{ id: "handle_start" }],
					},
					position: {
						x: foundStartNode ? foundStartNode.position.x : 0,
						y: foundStartNode ? foundStartNode.position.y : 0,
					},
					style: {
						color: "#FFFFFF",
						background: "#003153",
						border: "1px solid black",
						borderRadius: 15,
						fontSize: 16,
					},
					connectable: false,
					sourcePosition: Position.Right,
					targetPosition: Position.Left,
				},
				{
					id: "ENDNODE",
					type: "output",
					data: {
						label: "end",
						targetHandles: [{ id: "handle_end" }],
						sourceHandles: [],
					},
					position: {
						x: foundEndNode ? foundEndNode.position.x : 0,
						y: foundEndNode ? foundEndNode.position.y : 0,
					},
					style: {
						color: "#FFFFFF",
						background: "#003153",
						border: "1px solid black",
						borderRadius: 15,
						fontSize: 16,
					},
					sourcePosition: Position.Right,
					targetPosition: Position.Left,
				},
			];
			setNodes(initNodes.concat(newNodes));

			const newEdges = [];
			tasksData.forEach((task) => {
				const outputs = task.outputs;
				if (task.trigger_rule === "start") {
					newEdges.push({
						id: `e_start_${task.id}`,
						source: "STARTNODE",
						target: task.id,
						type: "step",
						//sourceHandle: `handle_${task.id}_${sourceHandle}`,
						markerEnd: {
							type: MarkerType.ArrowClosed,
							width: 20,
							height: 20,
							//color: '#FF0072',
						},
						//label: 'marker size and color',
						style: {
							strokeWidth: 3,
							//stroke: '#FF0072',
						},
					});
				}

				Object.entries(outputs).forEach(([sourceHandle, targetTaskName]) => {
					// Find the target task with the task_name equal to targetTaskName
					const targetTask = tasksData.find(
						(target) => target.task_name === targetTaskName
					);

					if (targetTask) {
						newEdges.push({
							id: `e_${task.id}_${sourceHandle}_${targetTask.id}`,
							source: task.id,
							target: targetTask.id,
							type: "step",
							sourceHandle: `handle_${task.id}_${sourceHandle}`,
							markerEnd: {
								type: MarkerType.ArrowClosed,
								width: 20,
								height: 20,
								//color: '#FF0072',
							},
							//label: 'marker size and color',
							style: {
								strokeWidth: 3,
								//stroke: '#FF0072',
							},
						});
					} else if (targetTaskName === "end") {
						newEdges.push({
							id: `e_${task.id}_${sourceHandle}_end`,
							source: task.id,
							target: "ENDNODE",
							type: "step",
							sourceHandle: `handle_${task.id}_${sourceHandle}`,
							markerEnd: {
								type: MarkerType.ArrowClosed,
								width: 20,
								height: 20,
								//color: '#FF0072',
							},
							//label: 'marker size and color',
							style: {
								strokeWidth: 3,
								//stroke: '#FF0072',
							},
						});
					}
				});
			});

			setEdges(newEdges);
		}
	}, [tasksData, dagData, readOnly]);

	useEffect(() => {
		let errorCounterOutputs = 0;
		let errorCounterInputs = 0;
		nodes.forEach((node) => {
			if (node.id !== "STARTNODE" && node.id !== "ENDNODE") {
				if (
					node.data.sourceHandles.length !==
					getOutgoers(node, nodes, edges).length
				) {
					let connectedEdges = getConnectedEdges([node], edges);
					if (
						node.data.sourceHandles.length !==
						connectedEdges.filter((edge) => edge.source == node.id).length
					) {
						errorCounterOutputs += 1;
					}
				}

				if (getIncomers(node, nodes, edges).length < 1) {
					errorCounterInputs += 1;
				}
			}
		});
		setOutputsErrorCnt(errorCounterOutputs);
		setInputsErrorCnt(errorCounterInputs);
	}, [nodes, edges]);

	const [openAddTask, setOpenAddTask] = useState(false);
	const handleOpenAddTask = () => {
		setOpenAddTask(true);
	};
	const handleCloseAddTask = () => {
		setOpenAddTask(false);
	};

	const [openAddManualTask, setOpenAddManualTask] = useState(false);
	const handleOpenAddManualTask = () => {
		setOpenAddManualTask(true);
	};
	const handleCloseAddManualTask = () => {
		setOpenAddManualTask(false);
	};

	const createTask = (data) => {
		const apiPath = "/workflow/task_instances";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		const onSuccess = (msg) => {
			console.log("Task Created Successfully!");
			console.log(msg);
			onSaveLayoutDb();
			//props.setAutoRefresh(true);
		};

		const onError = (msg) => {
			console.log("Task Creation Failed!");
			console.log(msg);
			//onSaveLayoutDb();
			props.setAutoRefresh(true);
		};

		httpService.postAPI(apiUrl, data, onSuccess, onError);
	};

	const handleAddTask = useCallback(
		(task) => {
			//console.log(task);
			createTask(task);
		},
		[createTask, onSaveLayoutDb]
	);

	const handleAddManualTask = useCallback(
		(manualTask) => {
			const newTasks = [
				{
					task_name: `manualAsk_${manualTask.name}`,
					python_file: "manualStart.py",
					steps: [],
					outputs: { OK: `manualWait_${manualTask.name}` },
					output_conditions: { OK: "True" },
					enabled: false,
					trigger_rule: "none_failed_min_one_success",
					dag_name: dagName,
					manual_id: null,
					json_data: {},
					json_schema: {},
					output_type: "branch",
				},
				{
					task_name: `manualWait_${manualTask.name}`,
					python_file: "manualWait.py",
					steps: [],
					outputs: { OK: `manualAnswered_${manualTask.name}` },
					output_conditions: { OK: "True" },
					enabled: false,
					trigger_rule: "none_failed_min_one_success",
					dag_name: dagName,
					manual_id: null,
					json_data: {},
					json_schema: {},
					output_type: "defer",
				},
				{
					task_name: `manualAnswered_${manualTask.name}`,
					python_file: "manualStop.py",
					steps: [],
					outputs: {},
					output_conditions: { OK: "True" },
					enabled: false,
					trigger_rule: "none_failed_min_one_success",
					dag_name: dagName,
					manual_id: null,
					json_data: {},
					json_schema: {},
					output_type: "branch",
				},
			];
			const apiPath = "/workflow/manual_template";
			const apiUrl = new URL(
				process.env.NODE_ENV === "production"
					? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
					: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
				process.env.NODE_ENV === "production"
					? window.location.origin
					: process.env.REACT_APP_DEV_API_BASE_URL
			);

			const onSuccess = (msg) => {
				console.log("Manual Task Created Successfully!");
				console.log(msg);
				const manualId = msg.id;
				newTasks.forEach((newTask) => {
					createTask({ ...newTask, manual_id: manualId });
				});
				onSaveLayoutDb();
				//props.setAutoRefresh(true);
			};

			const onError = (msg) => {
				console.log("Manual Task Creation Failed!");
				console.log(msg);
				props.setAutoRefresh(true);
			};

			httpService.postAPI(apiUrl, manualTask, onSuccess, onError);
			//createTask(task);
		},
		[createTask, props.setAutoRefresh, onSaveLayoutDb]
	);

	const onJumpTo = useCallback(
		(node) => {
			const bounds = getNodesBounds([node]);
			fitBounds(bounds);
		},
		[fitBounds]
	);

	const isValidConnection = useCallback(
		(connection) => {
			// we are using getNodes and getEdges helpers here
			// to make sure we create isValidConnection function only once
			const nodes = getNodes();
			const edges = getEdges();
			const target = nodes.find((node) => node.id === connection.target);
			const hasCycle = (node, visited = new Set()) => {
				if (visited.has(node.id)) return false;

				visited.add(node.id);

				for (const outgoer of getOutgoers(node, nodes, edges)) {
					if (outgoer.id === connection.source) return true;
					if (hasCycle(outgoer, visited)) return true;
				}
			};

			if (target.id === connection.source) return false;
			return !hasCycle(target);
		},
		[getNodes, getEdges]
	);

	// useEffect(() => {
	// 	nodes.forEach((node) => {
	// 		if (node.data.label === 'eventPropagate') {
	// 			console.log(node);
	// 			for (const outgoer of getOutgoers(node, nodes, edges)) {
	// 				console.log(outgoer);
	// 			}
	// 		}
	// 	});
	// }, [nodes, edges]);

	return user && !user.hasRole("ngom-businessuser") ? (
		<Box></Box>
	) : (
		dagData && tasksData && (
			<Box m="20px" sx={{ height: "80%", width: "95%" }}>
				<AppBar
					position="static"
					style={{ backgroundColor: "transparent", boxShadow: "none" }}
				>
					<Toolbar>
						<Typography
							variant="h5"
							style={{ flexGrow: 1, color: colors.grey[100] }}
						>
							{dagName}
						</Typography>
						{!readOnly && (
							<>
								<Button
									color="inherit"
									style={{ color: colors.grey[100], marginLeft: "8px" }}
									onClick={handleOpenAddManualTask}
									disabled={readOnly}
								>
									Add Manual Task
								</Button>
							</>
						)}
						{!readOnly && (
							<>
								<Button
									color="inherit"
									style={{ color: colors.grey[100], marginLeft: "8px" }}
									onClick={handleOpenAddTask}
									disabled={readOnly}
								>
									Add Task
								</Button>
							</>
						)}
						<JumpToDropDown items={nodes} onSelect={onJumpTo} />
						<Badge
							badgeContent={outputsErrorCnt + inputsErrorCnt}
							color="secondary"
						>
							<Button
								color="inherit"
								style={{ color: colors.grey[100], marginLeft: "8px" }}
								onClick={handleChecksOpen}
							>
								Health Check
							</Button>
						</Badge>
						{!readOnly && (
							<>
								<Button
									color="inherit"
									style={{ color: colors.grey[100], marginLeft: "8px" }}
									onClick={() => onLayout("LR")}
									disabled={readOnly}
								>
									Auto Layout
								</Button>
								<Button
									color="inherit"
									style={{ color: colors.grey[100], marginLeft: "8px" }}
									onClick={onRestoreLayoutDb}
									disabled={readOnly}
								>
									Restore Layout
								</Button>
								<Button
									color="inherit"
									style={{ color: colors.grey[100], marginLeft: "8px" }}
									onClick={onSaveLayoutDb}
									disabled={readOnly}
								>
									Save Layout
								</Button>
							</>
						)}
						<Menu
							anchorEl={anchorChecks}
							open={Boolean(anchorChecks)}
							onClose={handleChecksClose}
							anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
							transformOrigin={{ vertical: "top", horizontal: "right" }}
							sx={{
								mt: "1px",
								"& .MuiMenu-paper": {
									backgroundColor: colors.primary[400],
								},
							}}
						>
							<Box
								sx={{
									maxHeight: "400px",
									overflowY: "auto",
									padding: "10px",
									width: "500px",
								}}
							>
								<Typography color="error">
									Number of tasks with unconnected output ports:{" "}
									{outputsErrorCnt}
								</Typography>
								<Typography color="error">
									Number of tasks with unconnected input ports: {inputsErrorCnt}
								</Typography>
							</Box>
						</Menu>
					</Toolbar>
				</AppBar>
				{openAddTask && (
					<AddTaskPage
						open={openAddTask}
						onClose={handleCloseAddTask}
						onAdd={handleAddTask}
						dagName={dagName}
					></AddTaskPage>
				)}
				{openAddManualTask && (
					<AddManualTaskPage
						open={openAddManualTask}
						onClose={handleCloseAddManualTask}
						onAdd={handleAddManualTask}
						dagName={dagName}
					></AddManualTaskPage>
				)}
				{!readOnly && (
					<ReactFlow
						nodes={nodes}
						onNodesChange={onNodesChange}
						onNodesDelete={onNodesDelete}
						edges={edges}
						onEdgesChange={onEdgesChange}
						onEdgesDelete={onEdgesDelete}
						onConnect={onConnect}
						onInit={setRfInstance}
						isValidConnection={isValidConnection}
						connectionLineType={ConnectionLineType.Step}
						nodeTypes={nodeTypes}
						fitView
						defaultEdgeOptions={defaultEdgeOptions}
						connectionLineStyle={connectionLineStyle}
						//deleteKeyCode={[]}
					>
						<Background />
						{/* <MiniMap
						style={minimapStyle}
						zoomable
						pannable
						maskColor='#1F2662'
						position='top-left'
						nodeColor='#003153'
					/> */}
						<Controls />
					</ReactFlow>
				)}
				{readOnly && (
					<ReactFlow
						nodes={nodes}
						edges={edges}
						onNodesChange={onNodesChange}
						onEdgesChange={onEdgesChange}
						nodeTypes={nodeTypes}
						fitView
						onInit={setRfInstance}
						elementsSelectable={false}
						nodesConnectable={false}
						//nodesDraggable={false}
						deleteKeyCode={[]}
					>
						<Background />
						{/* <MiniMap
						style={minimapStyle}
						zoomable
						pannable
						maskColor='#1F2662'
						position='top-left'
						nodeColor='#003153'
					/> */}
						<Controls />
					</ReactFlow>
				)}
			</Box>
		)
	);
}

function Flow() {
	const httpService = useHttpService();
	const { dagName } = useParams();
	const [autoRefresh, setAutoRefresh] = useState(false);

	// get DAG Info
	const [dagsData, setDagsData] = useState([]);
	const fetchDagsData = useCallback(() => {
		const apiPath = "/workflow/dag_template";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set("dag_name", `${dagName}`);
		httpService.getAPI(
			apiUrl,
			dagsData,
			setDagsData,
			() => {},
			() => {},
			() => {},
			() => {}
		);
	}, [dagName, dagsData, setDagsData, httpService]);

	// get Tasks and Connections btw them from db
	const [tasksData, setTasksData] = useState([]);

	const fetchTasksData = useCallback(() => {
		const apiPath = "/workflow/task_instances";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		apiUrl.searchParams.set("json_in", `{"dag_name": "${dagName}"}`);
		httpService.getAPI(
			apiUrl,
			tasksData,
			setTasksData,
			() => {},
			() => {},
			() => {},
			() => {}
		);
	}, [dagName, tasksData, setTasksData, httpService]);

	useEffect(() => {
		fetchDagsData();
		fetchTasksData();
		if (autoRefresh) {
			setAutoRefresh(false);
		}
	}, [autoRefresh]);

	return (
		tasksData &&
		dagsData.length === 1 && (
			<ReactFlowProvider>
				<LayoutFlow
					tasksData={tasksData}
					dagData={dagsData[0]}
					setAutoRefresh={setAutoRefresh}
				/>
			</ReactFlowProvider>
		)
	);
}

export default Flow;
