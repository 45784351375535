import {
	Box,
	IconButton,
	useTheme,
	Typography,
	Popover,
	Button,
	Select,
	MenuItem,
	Divider,
	TextField,
	FormControl,
	InputLabel,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MuiAlert from "@mui/material/Alert";
import { useUserContext } from "../../contexts/userContext.js";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import useHttpService from "../../customHooks/useHttpService.js";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Topbar = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const colorMode = useContext(ColorModeContext);
	const userContext = useUserContext();
	const user = userContext.getUser();

	const [anchorEl1, setAnchorEl1] = React.useState(null);
	const open1 = Boolean(anchorEl1);
	const id1 = open1 ? "simple-popover" : undefined;

	const [anchorEl2, setAnchorEl2] = React.useState(null);
	const open2 = Boolean(anchorEl2);
	const id2 = open2 ? "simple-popover" : undefined;

	const handleOpenPopover1 = (event) => {
		setAnchorEl1(event.currentTarget);
	};

	const handleOpenPopover2 = (event) => {
		setAnchorEl2(event.currentTarget);
	};

	const handleClosePopover1 = () => {
		setAnchorEl1(null);
	};

	const handleClosePopover2 = () => {
		setAnchorEl2(null);
	};

	const RenderUserBox = () => {
		return (
			<Box>
				<Box sx={{ p: 2 }}>
					<Typography display="inline">Logged in as: </Typography>
					<Typography display="inline" fontWeight="bold" color="secondary">
						{user ? user.name : "Unknown User"}
					</Typography>
				</Box>
				<Box display="flex" justifyContent="center">
					<Button
						variant="contained"
						color="secondary"
						fullWidth
						sx={{ mt: 2 }}
						onClick={() => {
							userContext.logout();
						}}
						startIcon={<ExitToAppIcon />}
					>
						Sign out
					</Button>
				</Box>
			</Box>
		);
	};

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			p={1}
			sx={{
				background:
					"linear-gradient(90deg, #fb6f05, #ee1419 25%, #b90670 50%, #823a9c 65%, #1d50be 80%, #4cb4fc)",
			}}
		>
			{/* Spacer to push ICONS to the right */}
			<Box flex="1"></Box>

			{/* ICONS */}
			<Box display="flex">
				<IconButton onClick={colorMode.toggleColorMode}>
					{theme.palette.mode === "dark" ? (
						<DarkModeOutlinedIcon />
					) : (
						<LightModeOutlinedIcon />
					)}
				</IconButton>
				<IconButton onClick={handleOpenPopover2}>
					<PersonOutlinedIcon />
				</IconButton>
				<Popover
					id={id2}
					open={open2}
					anchorEl={anchorEl2}
					onClose={handleClosePopover2}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<RenderUserBox />
				</Popover>
			</Box>
		</Box>
	);
};

const RenderSettingBox = ({ handleClose }) => {
	const httpService = useHttpService();

	const [settingsData, setDettingsData] = useState([]);
	const [settingsDataIsError, setDettingsDataIsError] = useState(false);
	const [settingsDataIsLoading, setDettingsDataIsLoading] = useState(false);
	const [settingsDataIsRefetching, setDettingsDataIsRefetching] =
		useState(false);
	const [settingsDataFetchRequired, setDettingsDataFetchRequired] =
		useState(true);
	const [settingsDataRowCount, setDettingsDataRowCount] = useState(0);

	const fetchDettingsData = () => {
		const apiPath = "/ftth_coverage/settings";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.getAPI(
			apiUrl,
			settingsData,
			setDettingsData,
			setDettingsDataRowCount,
			setDettingsDataIsLoading,
			setDettingsDataIsRefetching,
			setDettingsDataIsError
		);
	};

	useEffect(() => {
		fetchDettingsData();
		if (settingsDataFetchRequired) {
			setDettingsDataFetchRequired(false);
		}
	}, [settingsDataFetchRequired]);

	const [businessSimulationData, setBusinessSimulationData] = useState(null);
	const [coverageLocationData, setCoverageLocationData] = useState(null);

	useEffect(() => {
		for (const item of settingsData) {
			if (item.name === "BUSINESS_SIMULATION") {
				setBusinessSimulationData(item);
			} else if (item.name === "coverageLocation") {
				setCoverageLocationData(item);
			}
		}
	}, [settingsData]);

	const handleBusinessSimulationChange = (event) => {
		if (businessSimulationData) {
			setBusinessSimulationData({
				...businessSimulationData,
				value: event.target.value,
			});
		}
	};

	const [isErrorUpdate, setIsErrorUpdate] = useState(false);
	const handleSave = () => {
		if (businessSimulationData) {
			const apiPath = "/ftth_coverage/settings";
			const apiUrl = new URL(
				process.env.NODE_ENV === "production"
					? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
					: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
				process.env.NODE_ENV === "production"
					? window.location.origin
					: process.env.REACT_APP_DEV_API_BASE_URL
			);
			let dataToSend = businessSimulationData;
			httpService.putAPI(
				apiUrl,
				dataToSend,
				setIsErrorUpdate,
				setDettingsDataFetchRequired
			);

			handleClose();
		}
	};

	return (
		<Box
			sx={{
				p: 2, // Padding
				m: 2, // Margin
				boxShadow: 1, // Elevation
			}}
		>
			{/* <div>
				<TextField
					disabled
					id='outlined-disabled'
					label='Coverage Database Location'
					value={
						coverageLocationData
							? coverageLocationData.value
							: 'No Data Available!'
					}
					defaultValue='No Data Available!'
				/>
			</div>
			<Divider sx={{ my: 2 }} /> */}
			<div>
				{/* <Typography variant="subtitle1">Business Simulation</Typography>
                <Select
                    value={
                        businessSimulationData
                            ? businessSimulationData.value
                            : ""
                    }
                    onChange={handleBusinessSimulationChange}
                    sx={{ width: "100%" }}
                >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                    <MenuItem value="">Empty</MenuItem>
                </Select> */}
				<FormControl sx={{ width: "100%" }}>
					<InputLabel shrink={true} id="demo-select-small-label">
						Business Simulation
					</InputLabel>
					<Select
						labelId="demo-select-small-label"
						id="demo-select-small"
						value={businessSimulationData ? businessSimulationData.value : ""}
						label="Business Simulation"
						displayEmpty
						notched={true}
						onChange={handleBusinessSimulationChange}
					>
						<MenuItem value="true">Check Whitelist</MenuItem>
						<MenuItem value="false">Check Blacklist</MenuItem>
						<MenuItem value="">Passthrough</MenuItem>
					</Select>
				</FormControl>
			</div>
			<Button
				variant="contained"
				color="secondary"
				onClick={handleSave}
				sx={{ mt: 2 }}
			>
				Save
			</Button>
		</Box>
	);
};

export default Topbar;
