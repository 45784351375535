import {
	darken,
	IconButton,
	useTheme,
	Button,
	Tooltip,
	Snackbar,
	Alert,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Box,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import { MaterialReactTable } from "material-react-table";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { tokens } from "../../theme";
import useHttpService from "../Common/useHttpService";
import { Refresh } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../contexts/userContext";

function MassiveClear() {
	const [massiveClearData, setmassiveClearData] = useState([]);
	const [sendButtonIsDisabled, setsendButtonIsDisabled] = useState(false);
	const [clearingInstance, setclearingInstance] = useState("");
	const [snack, setsnack] = useState({
		open: false,
		status: "success",
		message: "",
	});

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);

	const [dialogIsOpen, setdialogIsOpen] = useState(false);
	const [dialogData, setdialogData] = useState(null);
	const [locationFilterApplied, setLocationFilterApplied] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const [isErrorUpdate, setIsErrorUpdate] = useState(false);
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [detailsRow, setDetailsRow] = useState({});
	const handleCloseDetails = () => {
		setDetailsOpen(false);
	};

	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const tableInstanceRef = useRef(null);
	const location = useLocation();

	const apiGetPathMassiveClear = "/workflow/massive_clear";
	const apiUrlGetMassiveClear = new URL(
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiGetPathMassiveClear
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiGetPathMassiveClear,
		process.env.NODE_ENV === "production"
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const apiPathSettings = "/ftth_coverage/settings";
	const apiUrlSettings = new URL(
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPathSettings
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPathSettings,
		process.env.NODE_ENV === "production"
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const apiPathSettingsByName = "/ftth_coverage/settings_by_name";
	const apiUrlSettingsByName = new URL(
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPathSettingsByName
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPathSettingsByName,
		process.env.NODE_ENV === "production"
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const columns = useMemo(() => [
		{
			accessorKey: "dag_id",
			header: "Dag ID",
			size: 100,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
		{
			accessorKey: "task_id",
			header: "Task ID",
			size: 200,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
		{
			accessorKey: "airflow",
			header: "Airflow Link",
			size: 200,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
		{
			accessorKey: "cnt",
			header: "Number of Instances",
			size: 200,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: true,
		},
		{
			accessorKey: "_",
			header: "Send Clear",
			size: 20,
			isInput: false,
			enableEditing: false,
			enableClickToCopy: false,
			enableColumnFilter: false,
			enableColumnActions: false,

			Cell: ({ row }) => {
				return (
					<Button
						key={row.original.id}
						onClick={(e) => {
							setdialogIsOpen(true);
							setdialogData(row);
						}}
						variant="contained"
						color="secondary"
						disabled={sendButtonIsDisabled}
					>
						Send
					</Button>
				);
			},
		},
	]);

	useEffect(() => {
		fetchTaskIdToClear();
	}, [massiveClearData]);

	const fetchTaskIdToClear = () => {
		const opName = "TASK_ID_TO_CLEAR";
		httpService.getAPI(`${apiUrlSettingsByName.href}?name=${opName}`, (res) => {
			// console.log(res[0].value);
			setsendButtonIsDisabled(res[0].value && res[0].value !== "");
			if (!res[0].value || res[0].value === "") setclearingInstance("");
			else {
				const dagTaskAirflowTriple = res[0].value.split("|");
				const dag = dagTaskAirflowTriple[1];
				const task = dagTaskAirflowTriple[2];
				const airflow = dagTaskAirflowTriple[0];

				const foundRow = massiveClearData.map((row, index) => {
					if (
						dag === row.dag_id &&
						task === row.task_id &&
						airflow === row.airflow
					)
						return index + 1;
				});
				// console.log(foundRow);
				setclearingInstance(foundRow);
			}
		});
	};

	useEffect(() => {
		if (location.state && location.state.runId && !locationFilterApplied) {
			setColumnFilters((prev) => [
				...prev,
				{ id: "id", value: location.state.runId },
			]);
		}
		setLocationFilterApplied(true);
	}, [location]);

	useEffect(() => {
		if (locationFilterApplied) {
			fetchMassiveClearData();
		}
		if (fetchRequired) {
			setFetchRequired(false);
		}
	}, [fetchRequired, columnFilters, pagination.pageIndex, pagination.pageSize]);

	const fetchMassiveClearData = () => {
		setIsLoading(true);
		apiUrlGetMassiveClear.searchParams.set(
			"start",
			`${pagination.pageIndex * pagination.pageSize}`
		);
		apiUrlGetMassiveClear.searchParams.set("size", `${pagination.pageSize}`);

		columnFilters.forEach((columnFilter) => {
			if (columnFilter.id === "dag_id") {
				apiUrlGetMassiveClear.searchParams.set("dag_id", columnFilter.value);
			} else if (columnFilter.id === "task_id") {
				apiUrlGetMassiveClear.searchParams.set("task_id", columnFilter.value);
			} else if (columnFilter.id === "airflow") {
				apiUrlGetMassiveClear.searchParams.set("airflow", columnFilter.value);
			} else if (columnFilter.id === "cnt") {
				apiUrlGetMassiveClear.searchParams.set("cnt", columnFilter.value);
			}
		});

		httpService.getAPI(apiUrlGetMassiveClear.href, (data) => {
			setmassiveClearData(data || []);
			setRowCount(data[0]?.totalDBRowCount || 0);
			setIsLoading(false);
			setIsRefetching(false);
			setIsError(false);
			setIsRefetching(false);
		});
	};

	const handleSendClear = () => {
		const opName = "TASK_ID_TO_CLEAR";

		httpService.getAPI(`${apiUrlSettingsByName.href}?name=${opName}`, (res) => {
			res.forEach((el) => {
				if (el.name === opName) {
					if (!el.value || el.value === "") {
						el.value = `${dialogData.original.airflow ?? ""}|${
							dialogData.original.dag_id ?? ""
						}|${dialogData.original.task_id ?? ""}`;
						httpService.putAPI(apiUrlSettings.href, el, (res) => {
							if (!res.data.result) {
								setsnack({
									status: "error",
									message:
										"Ops! Something went wrong while saving the data! Try again or blame the who created this!",
									open: true,
								});
							} else {
								const rowNumber = dialogData.index + 1;

								setsendButtonIsDisabled(true);
								setclearingInstance(rowNumber.toString());
								setsnack({
									status: "success",
									message: "The clear operation was successfully sent!",
									open: true,
								});
							}
							httpService.getAPI(apiUrlGetMassiveClear.href, (data) => {
								setmassiveClearData(data || []);
								setRowCount(data[0]?.totalDBRowCount || 0);
								setIsLoading(false);
								setIsRefetching(false);
								setIsError(false);
								setIsRefetching(false);
							});
						});
					} else {
						setsnack({
							status: "warning",
							message:
								"A clear operation is still being processed. Try again in a while.",
							open: true,
						});
					}
				}
			});
		});
	};

	const getAlert = () => {
		if (isError) {
			return {
				color: "error",
				children: "Error loading data",
			};
		}

		return undefined;
	};

	const minifyRunID = (ID) => {
		return ID.split("_")[0] + " " + "\u00B7".repeat(3);
	};

	return (
		<>
			<Snackbar
				open={snack.open}
				autoHideDuration={6000}
				onClose={() => setsnack((prev) => ({ ...prev, open: false }))}
			>
				<Alert severity={snack.status} variant="filled" sx={{ width: "100%" }}>
					{snack.message}
				</Alert>
			</Snackbar>
			{dialogData && (
				<Dialog
					open={dialogIsOpen}
					onClose={() => setdialogIsOpen(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					fullWidth
				>
					<DialogTitle
						id="alert-dialog-title"
						fontWeight="bold"
						fontSize="16pt"
					>
						Confirmation Alert
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Do you really want to clear {dialogData.original.cnt}{" "}
							{dialogData.original.cnt === 1 ? "instance" : "instances"} of{" "}
							{dialogData.original.dag_id}?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setdialogIsOpen(false)}
							variant="contained"
							color="secondary"
						>
							No
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								setdialogIsOpen(false);
								handleSendClear();
							}}
							autoFocus
						>
							Yes
						</Button>
					</DialogActions>
				</Dialog>
			)}
			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				enableRowNumbers
				muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
				columns={columns}
				data={massiveClearData}
				//getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: false,
					density: "comfortable",
				}}
				muiTableHeadCellProps={{
					align: "left",
					sx: {
						fontWeight: "bold",
						color: colors.grey[100],
						typography: "h5",
					},
				}}
				muiTableBodyCellProps={{
					align: "left",
					sx: {
						color: colors.grey[100],
						typography: "h6",
					},
				}}
				muiTableBodyProps={{
					sx: (theme) => ({
						"& tr:nth-of-type(odd)": {
							backgroundColor: darken(theme.palette.background.default, 0.05),
						},
					}),
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				//rowCount={rowCount}
				rowCount={
					massiveClearData && massiveClearData.length > 0
						? massiveClearData[0].totalDBRowCount
						: 0
				}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableSorting={false}
				enableDensityToggle={false}
				enableColumnOrdering={false}
				enableHiding={false}
				enableGlobalFilter={false}
				manualFiltering={true}
				manualPagination={true}
				manualSorting={false}
				//renderDetailPanel={RenderDetail}
				muiTableBodyRowProps={({ row }) => ({
					onDoubleClick: (event) => {
						setDetailsRow(row.original);
						setDetailsOpen(true);
					},
					sx: {
						cursor: "pointer",
					},
				})}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title="Refresh">
								<IconButton
									onClick={() => {
										setFetchRequired(true);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
				renderTopToolbarCustomActions={({ table }) => {
					return (
						<Box>
							{clearingInstance ? (
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-start",
										alignItems: "center",
									}}
								>
									<WarningIcon sx={{ color: "#ffa500" }}></WarningIcon>
									<span
										style={{
											marginLeft: "5px",
											fontWeight: "bold",
											fontSize: "12pt",
											color: "orange",
										}}
									>
										Warning!
									</span>{" "}
									<span
										style={{
											marginLeft: "5px",
										}}
									>
										#{clearingInstance} clearing is in progress! No further
										actions can be started until the process is complete.
									</span>
								</Box>
							) : (
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-start",
										alignItems: "center",
									}}
								>
									<CheckIcon color="green"></CheckIcon>
									<span style={{ marginLeft: "5px" }}>
										There is no clearing dag at the moment.
									</span>
								</Box>
							)}
						</Box>
					);
				}}
			/>
		</>
	);
}

export default MassiveClear;
