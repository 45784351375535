import React, { useState, useEffect } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import useHttpService from "../../customHooks/useHttpService.js";

function AddDagPage({ open, onClose, onAdd, dagTemplates }) {
	const httpService = useHttpService();

	const [dag, setDag] = useState({
		dag_name: "",
		description: "",
		order_type_id: "",
	});

	const [cloneAnotherDag, setCloneAnotherDag] = useState(false);
	const handleChangeCreationMode = (event) => {
		setCloneAnotherDag(event.target.value);
	};

	const [dagNameError, setDagNameError] = useState(false);
	const [orderError, setOrderError] = useState(false);
	const [dagToCloneError, setDagToCloneError] = useState(false);

	const [dagTemplateToClone, setDagTemplateToClone] = useState("");
	const handleChangeDagTemplateToClone = (event) => {
		let isValidDagToClone = true;
		isValidDagToClone = event.target.value !== "" && event.target.value !== null;
		if (!isValidDagToClone) {
			setDagToCloneError(true);
		} else {
			setDagToCloneError(false);
		}
		setDagTemplateToClone(event.target.value);
	};

	const handleChange = (prop) => (event) => {
		let isValidName = true;
		let isValidOrder = true;
		if (prop === "dag_name") {
			isValidName =
				event.target.value !== "" &&
				/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(event.target.value);
			if (!isValidName) {
				setDagNameError(true);
			} else {
				setDagNameError(false);
			}
		}
		else if (prop === "order_type_id") {
			isValidOrder =
				event.target.value !== "" && event.target.value !== null 
			if (!isValidOrder) {
				setOrderError(true);
			} else {
				setOrderError(false);
			}
		}
		setDag({ ...dag, [prop]: event.target.value });
	};

	const handleAddDag = () => {
		let isValidName =
			dag.dag_name !== "" &&
			/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(dag.dag_name);
		if (!isValidName) {
			setDagNameError(true);
			return;
		} else {
			setDagNameError(false);
		}

		let isValidOrder = true;
		isValidOrder = dag.order_type_id !== "" && dag.order_type_id !== null;
		if (!isValidOrder) {
			setOrderError(true);
			return;
		} else {
			setOrderError(false);
		}

		if (cloneAnotherDag) {
			let isValidDagToClone = true;
			isValidDagToClone =
				dagTemplateToClone !== "" && dagTemplateToClone !== null;
			if (!isValidDagToClone) {
				setDagToCloneError(true);
				return;
			} else {
				setDagToCloneError(false);
			}
		}

		const newDag = { ...dag, cloneAnotherDag: cloneAnotherDag };
		if (cloneAnotherDag) {
			const selectedDagTemplateIndex = dagTemplates
				.map((item) => item.dag_name)
				.indexOf(dagTemplateToClone);
			newDag.selectedDagToClone = dagTemplates[selectedDagTemplateIndex];
		}

		onAdd(newDag);
		setDag({
			dag_name: "",
			description: "",
			order_type_id: "",
		});
		onClose();
	};

	const [ordertypes, setOrdertypes] = useState([]);
	const [ordertypesIsError, setOrdertypesIsError] = useState(false);
	const [ordertypesIsLoading, setOrdertypesIsLoading] = useState(false);
	const [ordertypesIsRefetching, setOrdertypesIsRefetching] = useState(false);
	const [ordertypesRowCount, setOrdertypesRowCount] = useState(0);

	const fetchOrdertypes = () => {
		const apiPath = "/workflow/order_type";
		const apiUrl = new URL(
			process.env.NODE_ENV === "production"
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === "production"
				? window.location.origin
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.getAPI(
			apiUrl,
			ordertypes,
			setOrdertypes,
			setOrdertypesRowCount,
			setOrdertypesIsLoading,
			setOrdertypesIsRefetching,
			setOrdertypesIsError
		);
	};

	useEffect(() => {
		fetchOrdertypes();
	}, []);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Create New DAG Template</DialogTitle>
			<DialogContent>
				<FormControl
					fullWidth
					style={{ marginBottom: "20px", marginTop: "20px" }}
				>
					<InputLabel id="order-type-select-label">Order</InputLabel>
					<Select
						labelId="order-type-select-label"
						id="order-type-select"
						label="Order"
						value={dag.order_type_id}
						onChange={handleChange("order_type_id")}
						required
						autoFocus
						margin="dense"
						error={orderError}
						helperText={orderError && "No order selected"}
					>
						<MenuItem disabled value="">
							<em>Select the Order</em>
						</MenuItem>
						{ordertypes.map((ordertype, index) => (
							<MenuItem key={index} value={ordertype.id}>
								{ordertype.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					style={{ marginBottom: "20px" }}
					label="Name"
					value={dag.dag_name}
					onChange={handleChange("dag_name")}
					fullWidth
					required
					autoFocus
					margin="dense"
					error={dagNameError}
					helperText={dagNameError && "Invalid Name"}
				/>
				<TextField
					style={{ marginBottom: "20px" }}
					label="Description"
					value={dag.description}
					onChange={handleChange("description")}
					fullWidth
					autoFocus
					margin="dense"
				/>
				<FormControl fullWidth style={{ marginBottom: "20px" }}>
					<InputLabel id="creation-mode-select-label">Creation Mode</InputLabel>
					<Select
						labelId="creation-mode-select-label"
						id="creation-mode-select"
						label="Creation Mode"
						value={cloneAnotherDag}
						onChange={handleChangeCreationMode}
						autoFocus
						margin="dense"
					>
						<MenuItem value={false}>Create a blank DAG Template</MenuItem>
						<MenuItem value={true}>Clone from another DAG Template</MenuItem>
					</Select>
				</FormControl>
				{cloneAnotherDag && (
					<FormControl fullWidth style={{ marginBottom: "20px" }}>
						<InputLabel id="dag-template-select-label">Dag Template</InputLabel>
						<Select
							labelId="dag-template-select-label"
							id="dag-template-select"
							label="Dag Template"
							value={dagTemplateToClone}
							onChange={handleChangeDagTemplateToClone}
							required
							autoFocus
							margin="dense"
							error={dagToCloneError}
							helperText={dagToCloneError && "No DAG selected"}
						>
							<MenuItem disabled value="">
								<em>Select the DAG Template to be Cloned</em>
							</MenuItem>
							{dagTemplates.map((dagTemplate, index) => (
								<MenuItem key={index} value={dagTemplate.dag_name}>
									{dagTemplate.dag_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button disabled={dagNameError} onClick={handleAddDag}>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AddDagPage;
