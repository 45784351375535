import {
	Box,
	Typography,
	useTheme,
	Divider,
	Link,
	Button,
	Tooltip,
} from "@mui/material";
import { tokens } from "../../theme";
import { PieChart } from "@mui/x-charts/PieChart";
import { ResponsivePie } from "@nivo/pie";

const StatBox = ({
	title,
	icon,
	data,
	total,
	linkTo,
	titleToolTip,
	enableArcLabels = false,
	enableLegends = false,
	enableTooltip = false,
	isInteractive = true,
	handleClickTitleAndCenter = () => {},
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const styles = {
		root: {
			fontFamily: "consolas, sans-serif",
			textAlign: "center",
			position: "relative",
			width: 600,
			height: 600,
		},
		overlay: {
			position: "absolute",
			top: 0,
			right: 50,
			bottom: 0,
			left: 50,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			fontSize: 28,
			color: colors.grey[100],
			// background: "#FFFFFF33",
			textAlign: "center",
			// This is important to preserve the chart interactivity
			pointerEvents: "none",
		},
	};

	const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
		return (
			<text
				x={centerX}
				y={centerY}
				textAnchor="middle"
				dominantBaseline="central"
				fill={colors.grey[100]}
				style={{
					fontSize: 28,
					cursor: isInteractive ? "pointer" : "unset",
				}}
				onClick={handleClickTitleAndCenter}
			>
				{total}
			</text>
		);
	};

	const onClickChart = (event) => {
		if (event.data.onClick) {
			event.data.onClick();
		} else {
			// console.log(event);
		}
	};

	const chartColors = ["#588c7e", "#f2e394", "#f2ae72", "#d96459"];

	return (
		<Box width="100%" m="0 10px">
			<Box display="flex" justifyContent="space-between">
				<Box sx={{ display: "flex", gap: "1rem" }}>
					<Button
						color="secondary"
						onClick={handleClickTitleAndCenter}
						startIcon={icon}
						sx={{ cursor: isInteractive ? "pointer" : "unset" }}
					>
						<Tooltip title={titleToolTip} placement="top">
							<Typography variant="h6" sx={{ color: colors.grey[100] }}>
								{title}
							</Typography>
						</Tooltip>
					</Button>
				</Box>
			</Box>
			<Divider sx={{ mb: 2 }} />

			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignContent: "center",
					position: "relative",
					width: "100%",
					height: 140,
				}}
			>
				<ResponsivePie
					{...(!enableTooltip ? { tooltip: () => <></> } : {})}
					data={data}
					isInteractive={isInteractive}
					theme={{
						axis: {
							domain: {
								line: {
									stroke: colors.grey[100],
								},
							},
							legend: {
								text: {
									fill: colors.grey[100],
								},
							},
							ticks: {
								line: {
									stroke: colors.grey[100],
									strokeWidth: 1,
								},
								text: {
									fill: colors.grey[100],
								},
							},
						},
						legends: {
							text: {
								fill: colors.grey[100],

								fontSize: 18,
							},
						},
						tooltip: {
							container: {
								color: colors.grey[300],
							},
						},
					}}
					onClick={onClickChart}
					onMouseEnter={(_datum, event) => {
						event.currentTarget.style.cursor = isInteractive
							? "pointer"
							: "none";
					}}
					layers={[
						"arcs",
						"arcLabels",
						"arcLinkLabels",
						"legends",
						CenteredMetric,
					]}
					colors={{ datum: "data.color" }}
					margin={{ top: 5, right: 10, bottom: 5, left: 10 }}
					startAngle={-90}
					endAngle={90}
					innerRadius={0.75}
					padAngle={1}
					cornerRadius={2}
					activeOuterRadiusOffset={3}
					fit={false}
					borderColor={{
						from: "color",
						modifiers: [["darker", 0.2]],
					}}
					enableArcLinkLabels={false}
					arcLinkLabelsSkipAngle={10}
					arcLinkLabelsTextColor={colors.grey[100]}
					arcLinkLabelsThickness={2}
					arcLinkLabelsColor={{ from: "color" }}
					enableArcLabels={enableArcLabels}
					arcLabel="value"
					arcLabelsRadiusOffset={0.4}
					arcLabelsSkipAngle={7}
					arcLabelsTextColor={{
						from: "color",
						modifiers: [["darker", 2]],
					}}
					legends={
						enableLegends && [
							{
								dataFrom: "keys",
								data: data.map((item, index) => ({
									color: item.color,
									id: item.id,
									label: item.label + ": " + item.value,
								})),
								anchor: "bottom",
								direction: "row",
								justify: false,
								translateX: 0,
								translateY: 0,
								itemsSpacing: 40,
								itemWidth: 150,
								itemHeight: 20,
								itemDirection: "left-to-right",
								itemOpacity: 1,
								symbolSize: 17,
								symbolShape: "square",
								effects: [
									{
										on: "hover",
										style: {
											itemTextColor: "#A9A9A9",
										},
									},
								],
							},
						]
					}
				/>
				{/* <Box style={styles.overlay}>
					<span>{total}</span>
				</Box> */}
			</Box>
		</Box>
	);
};

export default StatBox;
